import React, { useEffect, useState } from 'react';
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper';
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm';
import { CAREGIVER_PROFILE_DOCUMENTS_FORM_INPUTS } from '../../../CAREGIVER_FORM_CONSTANTS';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CareGiverProfileTabs from '../ProfileHeader';
import ClearIcon from '@mui/icons-material/Clear';
import PdfViewer from '../../../../../../_metronic/layout/components/header/chat_system/components/PdfViewer';

export default function AddCaregiverProfileDocuments() {
    const [uiElementsList, setUiElementsList] = useState([]);
    const [uploadedFile, setUploadedFile] = useState(null); 
    const [filePreview, setFilePreview] = useState(null);
    const [presignedUrl, setPresignedUrl] = useState(null); 
    const params : { id: string }= useParams()
    const history = useHistory();

    useEffect(() => {
        const tempElementsList = CAREGIVER_PROFILE_DOCUMENTS_FORM_INPUTS();
        setUiElementsList(tempElementsList);
    }, []);

    const handleSubmitForm = async (formData) => {
      
        const newFormData = new FormData();
        newFormData.append('file', uploadedFile);
        newFormData.append('name', formData['name']);
        newFormData.append('expiryDate', formData['expiryDate'] || new Date());

        try {
            const response = await axios.patch(
                `/caregivers/addDocument/${params.id}`,
                newFormData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            if (response.status === 201) {
                const { document } = response.data;

                const presignedResponse = await axios.get(`/batchfile/fetch-file-url`, {
                    params: { fileKey: document.fileKey },
                });

                if (presignedResponse.status === 200) {
                    const presignedUrl = presignedResponse.data.presignedUrl;

                    setUploadedFile(document.fileKey); 
                    setPresignedUrl(presignedUrl); 
                    toast.success('Document uploaded successfully');
                }
            }
        } catch (err) {
            toast.error('Failed to upload document');
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (uploadedFile) {
            toast.error('You cannot upload more than one file.');
            return;
        }

        if (file) {
            const allowedMimeTypes = ['application/pdf', 'image/jpeg', 'image/png'];
            if (!allowedMimeTypes.includes(file.type)) {
                toast.error('Invalid file type. Please select a valid file.');
                return;
            }
            setUploadedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => setFilePreview(e.target.result);
            reader.readAsDataURL(file);

            setUploadedFile(file); 
        }
    };

    const handleDeleteClick = () => {
      setUploadedFile(null);
        setFilePreview(null);
        setPresignedUrl(null);
    };

    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];

    return (
        <>
            <CareGiverProfileTabs />
            <FormWrapper
                title="New Certificate"
                content={
                    <>
                        <DynamicForm
                            fields={uiElementsList}
                            data={{}}
                            onSubmit={handleSubmitForm}
                            buttons={buttons}
                        />
                        <div style={{marginTop:"-50px",marginLeft:"20px"}}>
                            <input
                                type="file"
                                accept=".pdf, .jpg, .jpeg, .png"
                                onChange={(e) => handleFileChange(e)}
                            />
                            {uploadedFile || presignedUrl ? (
                                <div style={{ position: 'relative',width:"300px",marginTop:"5px"}}>
                                    <PdfViewer
                                        name={uploadedFile?.name || 'Uploaded File'}
                                        filePath={filePreview || presignedUrl}
                                        mimeType={uploadedFile?.type || 'application/pdf'}
                                    />
                                    <ClearIcon
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleDeleteClick}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </>
                }
            />
        </>
    );
}
