import React, {useEffect, useState} from 'react'
import CareGiverScheduleTabs from '../Tabs/SchedulesTabs'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import DynamicTable from '../../../../reusable-components/tables/DynamicTable'
import {columnDataMapping, columnSorting, columns} from '../../CAREGIVER_TIME_OFF_TABLE_CONSTANTS'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import { QueryData } from '../../../../reusable-components/tables/DynamicTableTypes'
import { RootState } from '../../../../../setup'
import { hasPermission } from '../../../../../_helper/hasPermission'
import { permissions } from '../../../../../_constants/permissions'
const selectAuth = (state: RootState) => state.auth
export default function TimeOffs() {
  const [filteredData, setFilteredData] = useState([])
  const [historyFilteredData, setHistoryFilteredData] = useState([])
  const [isFetchingData, setIsFetchingData] = useState(true);
  const authUser = useSelector((state: {auth: any}) => state.auth)
  const [loadingStates, setLoadingStates] = useState({})
  const [loadAgain, setLoadAgain] = useState(false)
  const [queryDataForHistory, setQueryDataForHistory] = useState<QueryData>({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
    sortBy: '_id',
    sortDir: 'desc',
  })
  const [queryDataForRequests, setQueryDataForRequests] = useState<QueryData>({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
    sortBy: '_id',
    sortDir: 'desc',
  })
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId
  const getTimeFromTimeStamp = (timestamp) => {
    const date = new Date(timestamp)
    let hour = date.getHours()
    const minute = date.getMinutes()
    const period = hour >= 12 ? 'PM' : 'AM'
    if (hour > 12) {
      hour -= 12
    } else if (hour === 0) {
      hour = 12
    }
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`
    const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`
    const formattedTime = `${formattedHour}:${formattedMinute} ${period}`
    return formattedTime
  }
  const getTimeFormat = (startDate, endDate) => {
    return `${startDate} - ${endDate}`
  }
  useEffect(() => {
    const fetchData = async (
      status,
      page,
      pageSize,
      setDataFunction,
      setQueryDataFunction,
      sortBy,
      sortDir
    ) => {
      setIsFetchingData(true)
      try {
        const response = await axios.get(
          `offTimeRequests/getAll?page=${page}&pageSize=${pageSize}&status=${status}&sortDir=${sortDir}&sortBy=${sortBy}`
        )
        const {data, currentPage, totalCounts} = response.data
        let newData = []
        data?.map((elem) => {
          elem['timeFormat'] = getTimeFormat(elem['startTime'], elem['endTime'])
          newData.push(elem)
        })
        setDataFunction(data)
        setQueryDataFunction((prevState) => ({
          ...prevState,
          currentPage,
          totalCounts,
        }))
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message)
        } else {
          console.log('Error: ', error.message)
        }
      } finally{
        setIsFetchingData(false)
      }
    }
    fetchData(
      'UNRESONDED',
      queryDataForRequests.pageNumber,
      queryDataForRequests.pageSize,
      setFilteredData,
      setQueryDataForRequests,
      queryDataForRequests.sortBy,
      queryDataForRequests.sortDir
    )
    fetchData(
      'APPROVED',
      queryDataForHistory.pageNumber,
      queryDataForHistory.pageSize,
      setHistoryFilteredData,
      setQueryDataForHistory,
      queryDataForHistory.sortBy,
      queryDataForHistory.sortDir
    )
  }, [
    loadAgain,
    queryDataForRequests.pageNumber,
    queryDataForRequests.pageSize,
    queryDataForHistory.pageNumber,
    queryDataForHistory.pageSize,
    queryDataForRequests.sortBy,
    queryDataForRequests.sortDir,
    queryDataForHistory.sortBy,
    queryDataForHistory.sortDir,
  ])
  const setLoading = (id, action, isLoading) => {
    setLoadingStates((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [action]: isLoading,
      },
    }))
  }
  const onApprove = async (row) => {
    setLoading(row._id, 'approve', true)
    await axios
      .post('/offTimeRequests/approve?id=' + row['_id'])
      .then((res) => {
        if (res.status == 201) {
          toast.success('Time off request approved successfully')
          let newLoadAgain = !loadAgain
          setLoadAgain(newLoadAgain)
          setLoading(row._id, 'approve', false)
        }
      })
      .catch((error) => {
        toast.error('Could not approve time off request')
        setLoading(row._id, 'approve', false)
      })
  }
  const onReject = async (row) => {
    setLoading(row._id, 'reject', true)
    await axios
      .post('/offTimeRequests/reject?id=' + row['_id'])
      .then((res) => {
        if (res.status == 201) {
          toast.success('Time off request declined successfully')
          let newLoadAgain = !loadAgain
          setLoadAgain(newLoadAgain)
          setLoading(row._id, 'reject', false)
        }
      })
      .catch((error) => {
        toast.error('Could not approve time off request')
        setLoading(row._id, 'reject', false)
      })
  }
  return (
    <>
      <CareGiverScheduleTabs></CareGiverScheduleTabs>
      <div className='card'>
        <div className='card-body'>
          <TableTitle title='Requests'></TableTitle>
          <DynamicTable
            htmlColumns={[]}
            columns={columns}
            data={filteredData}
            canView={false}
            canDelete={false}
            onView={() => {}}
            onDelete={() => {}}
            columnDataMapping={columnDataMapping}
            sortingColumns={columnSorting}
            canUpdate={false}
            title='Are you sure you want to delete this Schedule?'
            canApprove={hasPermission(userPermissions, permissions.care_giver_schedules_timeOff_approve)}
            canReject={hasPermission(userPermissions, permissions.care_giver_schedules_timeOff_reject)}
            onApprove={(id) => onApprove(id)}
            onReject={(id) => onReject(id)}
            setQueryData={setQueryDataForRequests}
            queryData={queryDataForRequests}
            loadingStates={loadingStates}
          />
        </div>
        <div className='card-body'>
          <TableTitle title='Hours Approved'></TableTitle>
          <DynamicTable
            htmlColumns={[]}
            columns={columns}
            data={historyFilteredData}
            canView={false}
            canDelete={false}
            onView={() => {}}
            onDelete={() => {}}
            columnDataMapping={columnDataMapping}
            sortingColumns={columnSorting}
            canUpdate={false}
            title='Are you sure you want to delete this Schedule?'
            setQueryData={setQueryDataForHistory}
            queryData={queryDataForHistory}
            tableLoader={isFetchingData}
          />
        </div>
      </div>
    </>
  )
}