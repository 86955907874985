import React, { useState } from 'react';
import { Tab, Tabs, Row, Col } from 'react-bootstrap';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';

const AccountSettings = () => {
  const [key, setKey] = useState('editProfile');

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      padding: '60px 20px',
      backgroundColor: '#f1f3f5',
    } as React.CSSProperties,
    card: {
      width: '100%',
      maxWidth: '900px',
      backgroundColor: '#ffffff',
      boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
      borderRadius: '12px',
      overflow: 'hidden',
    } as React.CSSProperties,
    title: {
      fontSize: '26px',
      fontWeight: '600',
      color: '#333',
      textAlign: 'center',
      padding: '20px',
      borderBottom: '1px solid #e0e0e0',
    } as React.CSSProperties,
    sidebar: {
      padding: '20px',
      borderRight: '1px solid #e0e0e0',
      minHeight: '100%',
    } as React.CSSProperties,
    tabs: {
      border: 'none',
      width: '100%',
    } as React.CSSProperties,
    tabLink: {
      fontSize: '16px',
      color: '#555',
      padding: '12px 20px',
      borderRadius: '5px',
      textAlign: 'left',
      cursor: 'pointer',
      width: '100%',
      display: 'block',
      transition: 'background-color 0.3s ease, color 0.3s ease',
      marginBottom: '8px',
      fontWeight: '500',
    } as React.CSSProperties,
    activeTabLink: {
      backgroundColor: 'transparent',
      color: '#007bff',
      fontWeight: '600',
      borderBottom: '2px solid #007bff',
      padding: '8px 12px',
      textDecoration: 'none',
      cursor: 'pointer',
    } as React.CSSProperties,
    
    content: {
      padding: '40px',
      backgroundColor: '#ffffff',
    } as React.CSSProperties,
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.title}>Account Settings</h2>
        <Row>
          <Col sm={3} style={styles.sidebar}>
            <Tabs
              id="account-settings-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k || 'editProfile')}
              style={styles.tabs}
              className="flex-column"
            >
              <Tab
                eventKey="editProfile"
                title={
                  <span
                    style={{
                      ...styles.tabLink,
                      ...(key === 'editProfile' ? styles.activeTabLink : {}),
                    }}
                  >
                    Edit Profile
                  </span>
                }
              />
              <Tab
                eventKey="changePassword"
                title={
                  <span
                    style={{
                      ...styles.tabLink,
                      ...(key === 'changePassword' ? styles.activeTabLink : {}),
                    }}
                  >
                    Change Password
                  </span>
                }
              />
            </Tabs>
          </Col>
          <Col sm={9} style={styles.content}>
            {key === 'editProfile' && <EditProfile />}
            {key === 'changePassword' && <ChangePassword />}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccountSettings;
