import React, { useEffect, useState } from 'react';
import FormWrapper from '../../../../reusable-components/Wrapper/Wrapper';
import DynamicForm from '../../../../reusable-components/forms/DynamicForm';
import { MARS_FORM_CONSTANTS } from '../../MARS_TABLE_CONSTANTS';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';



const MedicationForm = () => {
    const history = useHistory()
    const [uiElementsList, setUiElementsList] = useState([])
    const [formData, setFormData] = useState({})
    const [i, setI] = useState(0)
    const [clickCount, setClickCount] = useState(6)
    const [inputArr, setInputArr] = useState([
        {
            time: '',
            dosage: '',
            comment: '',
            hours: 0,
            minutes: 0
        },
    ])

    const getHoursFromTimeStamp = (timeStamp) => {
        const date = new Date(timeStamp);
        return date.getHours()
    }

    const getMinutesFromTimeStamp = (timeStamp) => {
        const date = new Date(timeStamp);
        return date.getMinutes()
    }

    const onChangeTiming = (name, value, checked, type, index) => {
        let prevInputArr = inputArr
        prevInputArr[index]['hours'] = getHoursFromTimeStamp(value)
        prevInputArr[index]['minutes'] = getMinutesFromTimeStamp(value)
        prevInputArr[index]['time'] = value
        setInputArr(prevInputArr)
    }

    const onChangeDosage = (name, value, checked, type, index) => {

        let prevInputArr = inputArr
        prevInputArr[index]['dosage'] = value
        setInputArr(prevInputArr)
    }

    const onChangeComment = (name, value, checked, type, index) => {

        let prevInputArr = inputArr
        prevInputArr[index]['comment'] = value
        setInputArr(prevInputArr)
    }

    useEffect(async () => {
        const response = await axios.get('/clients/getClientNames')
        const {data} = response?.data
        const updatedclients = [
            ...data.map((client) => ({label: client.fullName, value: client._id})),
          ]
        const medicationList = await axios.get('/medicationListings').then(({ data }) => {
            if (data?.length) {
                return data.map(item => ({ value: item._id, label: item.name }))
            }
        })
        const tempElementsList = MARS_FORM_CONSTANTS(updatedclients, medicationList)
        setUiElementsList(tempElementsList)
        setUiElementsList((prevState) => [
            ...prevState,
            {
                label: 'Time',
                index: 0,
                name: `${i}time`,
                value: inputArr[0]['time'],
                onChange: onChangeTiming,
                type: 'timezone-time',
                required: true,
                rowNumber: 6,
            },
            {
                label: 'Dosage',
                name: `${i}dosage`,
                index: 0,
                value: inputArr[0]['dosage'],
                onChange: onChangeDosage,
                type: 'text',
                required: true,
                rowNumber: 6,
            },
            {
                label: 'Comment',
                index: 0,
                name: `${i}comment`,
                value: inputArr[0]['comment'],
                onChange: onChangeComment,
                type: 'text',
                required: true,
                rowNumber: 6,
            },
        ])
    }, [])

    useEffect(() => {
        if (i !== 0) {
            const timeToAdd = {
                label: 'Time',
                index: i,
                name: `${i}time`,
                value: inputArr[i]['time'],
                onChange: onChangeTiming,
                type: 'timezone-time',
                required: true,
                rowNumber: clickCount,

            }
            const dosageFieldToAdd = {
                label: 'Dosage',
                name: `${i}dosage`,
                index: i,
                value: inputArr[i]['dosage'],
                onChange: onChangeDosage,
                type: 'text',
                required: true,
                rowNumber: clickCount,

            }
            const commentFieldToAdd = {
                label: 'Comment',
                index: i,
                name: `${i}comment`,
                value: inputArr[i]['comment'],
                onChange: onChangeComment,
                type: 'text',
                required: true,
                rowNumber: clickCount,

            }
            const deleteButton = {
                index: i,
                label: 'Delete',
                variant: 'outlined',
                type: 'button',
                onClick: () => onDeleteClick(i),
                rowNumber: clickCount
            }
            setUiElementsList((prevElements) => [
                ...prevElements,
                timeToAdd,
                dosageFieldToAdd,
                commentFieldToAdd,
                deleteButton
            ])
        }
    }, [i])
    const onAddAnotherTimingClick = async () => {
        setClickCount(clickCount + 1)
        let prevInputArr = inputArr
        prevInputArr.push({
            time: '',
            dosage: '',
            comment: '',
            hours: 0,
            minutes: 0
        })
        setInputArr(prevInputArr)
        setI(i + 1)
    }
    const onDeleteClick = (index) => {
        let prevState = inputArr
        prevState[index].roleId = ''
        setInputArr(prevState)
        setUiElementsList((prevState) => {
          return prevState.filter((item) => {
            return !item.index || (item.index && item.index != index)
          })
        })
    };



    const formatDate = (time) => {
        return new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        }).format(time);
    }

    const handleSubmitForm = async (formData) => {
        let { clientId, medicationListingId, prescribedOn, comment, dosageInformation, sideEffects } = formData
        const formattedDosageTimings = inputArr.map(entry => ({
            time: entry.time,
            dosage: entry.dosage,
            comment: entry.comment,
            hours: entry.hours,
            minutes: entry.minutes
        }));

      const response = await axios.post('/individualMedications', {
                clientId,
                medicationListingId,
                prescribedOn: prescribedOn || new Date(),
                dosageInformation,
                sideEffects,
                comment,
                dosageTimings: formattedDosageTimings
            })
        const { message, success } = response?.data
        if(!success) {
            return toast.error(message)
        }
        history.push('/mars/individual-medication')
        toast.success(message)
    }

    const buttons = [
        {
            label: 'Add Another Timing',
            variant: 'outlined',
            type: 'button',
            onClick: onAddAnotherTimingClick,
        },
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ]
    return (
            <FormWrapper
                title='Add Individual Medication'
                content={
                    <DynamicForm
                        fields={uiElementsList}
                        data={formData}
                        onSubmit={handleSubmitForm}
                        buttons={buttons}
                    />
                }
            ></FormWrapper>
    );
};

export default MedicationForm;
