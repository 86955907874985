import React from 'react'
import {Route, Switch} from 'react-router-dom'

import {useSelector} from 'react-redux'
import { hasPermission } from '../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../_helper/routesWithPermission'
import { Error404 } from '../../../modules/errors/components/Error404'
import { ListNotes } from '../../../pages/Notes/Nurses_Notes/List'
import { NewNotes } from '../../../pages/Notes/Nurses_Notes/new/New'
import { EditNotes } from '../../../pages/Notes/Nurses_Notes/edit/edit'
import { permissions } from '../../../../_constants/permissions'

export const NursesNotesIndexRoutes: React.FC = () => {
  const auth = useSelector((state: {auth: any}) => state.auth)
  const userPermissions = auth.user.roleId
  const canUpdate = hasPermission(userPermissions, permissions.notes_nurses_note_update)
  const canCreate = hasPermission(userPermissions, permissions.notes_nurses_note_update)
  const canRead = hasPermission(userPermissions, permissions.notes_nurses_note_view)

  return (
    <Switch>
      <RouteWithPermission
        path='/notes/nurses-notes/new'
        component={NewNotes}
        hasPerm={canCreate}
      />
      <RouteWithPermission
        path='/notes/nurses-notes/edit/:id'
        component={EditNotes}
        hasPerm={canUpdate}
      />
      <RouteWithPermission
        path='/notes/nurses-notes/list'
        component={ListNotes}
        hasPerm={canRead}
      />
      <Route path='/error/404' component={Error404} />
    </Switch>
  )
}
