import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup'
import { toast } from 'react-toastify'
import TableTitle from '../../../../../reusable-components/tables/TableTitle';
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar';
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable';
import { LIST_OF_PROGRAM_COLUMNS, LIST_OF_PROGRAM_COLUMN_DATA_MAPPING, LIST_OF_PROGRAM_SORTING_COLUMNS } from '../../GENERAL_TABLE_CONSTANTS';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteProgram, getProgram } from '../../../care/enrollment/store/actions';
import { hasPermission } from '../../../../../../_helper/hasPermission';
import { permissions } from '../../../../../../_constants/permissions';
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper';
import AddButton from '../../../../../reusable-components/Buttons/AddButton';
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes';
const selectAuth = (state: RootState) => state.auth;
const ListProgram = () => {
    const [programs, setPrograms] = useState<any[]>([])
    const [filteredData, setFilteredData] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState('')
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [deletedID, setDeletedID] = useState('')
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm : ''
    });
    const dispatch = useDispatch()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    const history = useHistory()
    useEffect(() => {
        const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            setIsFetchingData(true)
            try {
                const result: any = await dispatch(getProgram({ page: page, pageSize: pageSize, sortBy: sortBy, sortDir: sortDir, searchTerm : queryData.searchTerm }));
                const { data, currentPage, totalCounts } = result.payload.data;
                setPrograms(data);
                setFilteredData(data);
                setQueryData((prevState) => {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    }
                });
            } catch (err) {
                console.log(err);
            } finally{
                setIsFetchingData(false)
            }
        };
    
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    }, [deletedID, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm]);
    const DeleteProgram = async (row: { _id: any; }) => {
        setIsFetchingData(true)
        try {
            const response: any = await dispatch(deleteProgram(row._id))
            if (response.payload?.success) {
                toast.success(response.payload.message || 'Success', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                setDeletedID(row._id)
                setQueryData((prevState)=> {
                    return {
                      ...prevState,
                      searchTerm : '',
                      pageNumber: 1
                    }
                  })
            } else {
                toast.error(response.payload?.response?.data?.message || response.payload?.message || 'Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        } catch (error) {
            toast.error('Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        } finally{
            setIsFetchingData(false)
        }
    }
    
    const onUpdate = ({ _id }: { _id: string }) => {
        history.push({
            pathname: `/admin/general/programs/list/${_id}`
        })
    }
    const onClickAddButton = () => {
        history.push('/admin/general/programs/new')
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <div>
                    <TableTitle title='Programs List'></TableTitle>
                    <TableWrapper
                        searchField={
                            <TableSearchBar
                                label='Search'
                                variant='outlined'
                                size='small'
                                value={searchTerm}
                                setQueryData={setQueryData}
                                setSearchTerm={setSearchTerm}
                                margin='normal'
                                inputAdornmentPosition='start'
                            ></TableSearchBar>
                        }
                        tableButtons={
                            !!hasPermission(userPermissions, permissions.admin_billing_program_create) &&
                            <AddButton
                                onClick={onClickAddButton}
                            />
                        }
                        dynamicTable={
                            <DynamicTable
                                columns={LIST_OF_PROGRAM_COLUMNS}
                                data={filteredData}
                                canUpdate={hasPermission(userPermissions, permissions.admin_billing_program_update)}
                                canDelete={hasPermission(userPermissions, permissions.admin_billing_program_delete)}
                                columnDataMapping={LIST_OF_PROGRAM_COLUMN_DATA_MAPPING}
                                sortingColumns={LIST_OF_PROGRAM_SORTING_COLUMNS}
                                clickableRow={false}
                                onUpdate={onUpdate}
                                onDelete={DeleteProgram}
                                title='Are you sure you want to delete the Program'
                                setQueryData={setQueryData}
                                queryData={queryData}
                                tableLoader={isFetchingData}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    )
}
export default ListProgram