import {Redirect, Route, Switch} from 'react-router-dom'
import ContactList from '../../../../../pages/admin/care/contact/list/List'
import ContactNewTable from '../../../../../pages/admin/care/contact/new/Table'
import ContactNewForm from '../../../../../pages/admin/care/contact/new/New'
import ListContacts from '../../../../../pages/admin/care/contact/editContact/List'
import {useSelector} from 'react-redux'
import {hasPermission} from '../../../../../../_helper/hasPermission'
import {RouteWithPermission} from '../../../../../../_helper/routesWithPermission'
import {Error404} from '../../../../../modules/errors/components/Error404'

const ContactIndexRoutes: React.FC = () => {
  const {auth, client} = useSelector((state: {auth: any, client: any}) => state)
  const userPermissions = auth.user.roleId
  const canUpdate = hasPermission(userPermissions, 'ADMIN.CARE.CONTACT.UPDATE')
  const canCreate = hasPermission(userPermissions, 'ADMIN.CARE.CONTACT.CREATE')
  const canRead = hasPermission(userPermissions, 'ADMIN.CARE.CONTACT.VIEW')

  const getDefaultPath = () => {
    // if (canRead) return '/admin/care/contact/list'
    if (canUpdate) return `/admin/care/contact/list/${client.clientID}`
    if (canCreate) return `/admin/care/contact/new/${client.clientID}`
    if (canCreate) return '/admin/care/individual-intake/new'
    return '/error/404'
  }
  return (
    <Switch>
      <Route exact path='/admin/care/contact'>
        <Redirect to={getDefaultPath()} />
      </Route>
      <RouteWithPermission
        path='/admin/care/contact/list/:id'
        component={ListContacts}
        hasPerm={canUpdate}
      />
      <RouteWithPermission
        path='/admin/care/contact/new/:id'
        component={ContactNewForm}
        hasPerm={canCreate}
      />
      <RouteWithPermission
        path='/admin/care/contact/new'
        component={ContactNewTable}
        hasPerm={canCreate}
      />
      <Route path='/error/404' component={Error404} />
    </Switch>
  )
}

export default ContactIndexRoutes
