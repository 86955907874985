import {useLocation} from 'react-router-dom'
import IndividualIntakeHeader from './IndividualIntakeHeader'

function IndividualIntakeIndex() {
  const location = useLocation()
  return (
    <div>
      {location.pathname !== '/admin/care/individual-intake/new' && <IndividualIntakeHeader />}
    </div>
  )
}

export default IndividualIntakeIndex
