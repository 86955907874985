import { useDispatch } from 'react-redux'
import { getOneSites, updateSite } from '../store/action'
import { toast } from 'react-toastify'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { SITE_CONSTANT_INPUTS } from '../../GENERAL_CONSTANTS'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'


const EditSite = () => {
    const [site, setSite] = useState({})
    const dispatch = useDispatch()
    const [isFormLoading, setIsFormLoading] = useState(true)
    const parameters = useParams();
    const history = useHistory()
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsFormLoading(true);
            if (parameters.id) {
                const response = await dispatch(getOneSites(parameters.id))
                const tempSite = response.payload?.data
                const data = {
                    _id: tempSite?._id,
                    siteName: tempSite?.siteName,
                    siteCode: tempSite?.siteCode,
                    siteID: tempSite?.siteID,
                    programCostCenterNumber: tempSite?.programCostCenterNumber,
                    addrInfo: {
                        st1: tempSite?.st1,
                        st2: tempSite?.st2,
                        city: tempSite?.city,
                        state: tempSite?.state,
                        country: tempSite?.country,
                        primaryPhone: tempSite?.primaryPhone,
                        zipCode: tempSite?.zipCode,
                    },
                    secondaryPhone: tempSite?.secondaryPhone,
                    accuracy: tempSite?.accuracy,
                    lat: tempSite?.lat,
                    lng: tempSite?.lng
                }
                setSite(data)
            }} catch (err) {
                console.log(err);
            }finally{
                setIsFormLoading(false)
            }
        }
        fetchData()
    }, [])

    const handleSubmitForm = async (formData) => {
        let temp = {
            _id: site._id,
            siteID: Number(formData.siteID),
            siteName: formData.siteName,
            siteCode: formData.siteCode,
            st1: formData?.addrInfo?.st1,
            st2: formData?.addrInfo?.st2,
            city: formData?.addrInfo?.city,
            state: formData?.addrInfo?.state,
            country: formData?.addrInfo?.country,
            programCostCenterNumber: Number(formData.programCostCenterNumber),
            primaryPhone: Number(formData?.addrInfo?.primaryPhone),
            secondaryPhone: Number(formData?.secondaryPhone),
            zipCode: Number(formData?.addrInfo?.zipCode),
            accuracy: formData?.accuracy,
            lat: site?.lat,
            lng: site?.lng
        }
        setIsFormLoading(true)
        try{
        const response = await dispatch(updateSite(temp))
        if(response.payload.status === 400 && response.payload.success === false) {
            toast.error(response.payload.message)
        } else if (response?.payload.success) {
            toast.success(response?.payload.message || 'success', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.push({
                pathname: `/admin/general/site/list`
            })
        } else {
            toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }} catch (err) {
            console.log(err);
        }finally{
            setIsFormLoading(false)
        }
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];

    return (
        <div className='card my-10'>
        <div className='card-body'>
          {isFormLoading ? (
            <div className='overlay d-flex align-middle justify-content-center align-middle' style={{marginTop:"100px"}}>
              <MoonLoader color='#9db2fc' size={60} loading={isFormLoading} />
            </div>
          ) : (
        <FormWrapper
            title='Edit Site'
            content={
                <DynamicForm
                    fields={SITE_CONSTANT_INPUTS}
                    data={site}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )}
    </div>
  </div>
    )
}

export default EditSite