import { useState, useEffect } from 'react'
import SearchClientLogForm from './SearchClientLogForm'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import { useHistory } from 'react-router-dom'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import { LIST_OF_CLIENT_LOG_COLUMNS_DATA_MAPPING, LIST_OF_CLIENT_LOG_COLUMNS, LIST_OF_CLIENT_LOG_SORTING_COLUMNS } from '../../care_table_constants'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import TableButton from '../../../../../reusable-components/tables/TableButton'
import { getIncidentReport } from '../store/action'
const SearchClientLogTable = ({ formData, setShowForm }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [clientLogListing, setClientLogListing] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedLog, setSelectedLog] = useState({})
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [currentPage, setCurrentPage] = useState(1)
    const [exportDataDetail, setExportDataDetail] = useState([])
    const [show, setShow] = useState(false)
    const [queryData, setQueryData] = useState({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1
    })
    useEffect(() => {
        const fetchData = async (page, pageSize) => {
            setIsFetchingData(true)
            try {
                const [clients_Response, client_Logs_Response] = await Promise.all([
                    axios.get(`/clients/get`),
                    dispatch(getIncidentReport({page:page, pageSize:pageSize}))
                ]);
                const { data, currentPage, totalCounts } = clients_Response.data;
                const clientLog_Data = client_Logs_Response.payload.data.data;
                // Associate client names with logs
                const logsWithClientNames = clientLog_Data.map(log => {
                    const associatedClient = data.find(client => client._id === log.clientID);
                    const updatedLog = { ...log };
                    if (associatedClient) {
                        // Construct clientName with trimmed first and last names
                        updatedLog.clientName = `${associatedClient.firstName.trim()} ${associatedClient.lastName.trim()}`;
                    } else {
                        updatedLog.clientName = ''; // or handle the case when associatedClient is not found
                    }
                    return updatedLog;
                });
                let formUpdatedData = {}
                let objectKeysArray = Object.keys(formData)
                for (const element of objectKeysArray) {
                    if (formData[element] !== '') {
                        formUpdatedData[element] = formData[element]
                    }
                }
                let formDataKeys = Object.keys(formUpdatedData)
                let selectedData = {}
                let filteredLogs = logsWithClientNames;
                // Apply date range filtering
                if (formData.reportDateFrom && formData.reportDateTo) {
                    filteredLogs = logsWithClientNames.filter(log => log.reportedOn >= formData.reportDateFrom && log.reportedOn <= formData.reportDateTo);
                } else if (formData.reportDateFrom) {
                    filteredLogs = logsWithClientNames.filter(log => log.reportedOn >= formData.reportDateFrom);
                } else if (formData.reportDateTo) {
                    filteredLogs = logsWithClientNames.filter(log => log.reportedOn <= formData.reportDateTo);
                }
                // Apply type filtering
                if (formData.type && formData.type.length > 0) {
                    filteredLogs = filteredLogs.filter(log => formData.type.every(type => log.type.includes(type)));
                }
                // Apply intensity level filtering
                if (formData.intensityLevel && formData.intensityLevel.length > 0) {
                    filteredLogs = filteredLogs.filter(log => formData.intensityLevel.includes(log.intensityLevel));
                }
                formDataKeys = formDataKeys.filter(key =>
                    key !== "reportDateTo" && key !== "reportDateFrom" && key !== "type" && key !== "intensityLevel"
                );
                let tempFinalArray = [];
                if (formDataKeys.length > 0) {
                    for (const element of formDataKeys) {
                        // Check if the key is one of "clientName", "description", "summary", or "intensityLevel"
                        if (["clientName", "description", "summary", "intensityLevel"].includes(element)) {
                            // Check if the corresponding value is a string
                            if (typeof formUpdatedData[element] === "string") {
                                // Convert the value to lowercase
                                formUpdatedData[element] = formUpdatedData[element].toLowerCase();
                            }
                        }
                    }
                    for (let i = 0; i < filteredLogs.length; i++) {
                        if (selectedData[filteredLogs[i]._id] === "deleted") {
                            continue;
                        }
                        for (const element of formDataKeys) {
                            if (["clientName", "summary", "description"].includes(element)) {
                                let lowerCaseLetter = filteredLogs[i][element].toLowerCase();
                                if (lowerCaseLetter.includes(formUpdatedData[element])) {
                                    selectedData[filteredLogs[i]._id] = filteredLogs[i];
                                }
                            } else {
                                if (filteredLogs[i][element] === formUpdatedData[element]) {
                                    selectedData[filteredLogs[i]._id] = filteredLogs[i];
                                } else {
                                    selectedData[filteredLogs[i]._id] = "deleted";
                                    break;
                                }
                            }
                        }
                    }
                    let selectedDataKeys = Object.keys(selectedData);
                    for (const element of selectedDataKeys) {
                        if (selectedData[element] !== 'deleted') {
                            tempFinalArray.push(selectedData[element]);
                        }
                    }
                } else {
                    tempFinalArray = filteredLogs;
                }
                // Set the final filtered data to the state
                setClientLogListing(tempFinalArray);
                setFilteredData(tempFinalArray);
                setQueryData((prevState)=> {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    }
                })
            } catch (error) {
                console.error('Error fetching data:', error);
            }finally{
                setIsFetchingData(false)
            }
        };
        fetchData(queryData.pageNumber, queryData.pageSize);
    }, [ formData.reportDateFrom, formData.reportDateTo, formData.type, formData.intensityLevel, queryData.pageNumber, queryData.pageSize]);
    const onDelete = (row) => {
        let tempObj = {
            deleteID: row._id
        }
        axios.delete('/clientLogs/delete', {
            data: tempObj
        })
            .then(res => {
                setClientLogListing(prevState => prevState.filter(tlog => tlog._id !== row._id))
                setFilteredData(prevState => prevState.filter(tlog => tlog._id !== row._id))
            })
            .catch(err => {
                console.log(err)
            })
    }
    const handleSearchChange = (event) => {
        event.persist()
        setSearchTerm(() => event.target.value)
        if (event.target?.value) {
            const filteredTlogs = clientLogListing.filter(
                (tlog) =>
                    tlog.clientName.toLowerCase().includes(event.target?.value.toLowerCase()) ||
                    tlog.intensityLevel.toLowerCase().includes(event.target?.value.toLowerCase()) ||
                    tlog.type.toString().toLowerCase().includes(event.target?.value.toLowerCase()) ||
                    tlog.summary.toLowerCase().includes(event.target?.value.toLowerCase()) ||
                    tlog.description.toLowerCase().includes(event.target?.value.toLowerCase()) ||
                    tlog.reportedOn.toLowerCase().includes(event.target?.value.toLowerCase())
            )
            setFilteredData(filteredTlogs)
        } else setFilteredData(clientLogListing)
    }
    return (
        <div>
            <div>
                {
                    show ? <SearchClientLogForm selectedLog={selectedLog} setShowForm={setShowForm} />
                        :
                        <TableWrapper
                            searchField={
                                <TableSearchBar
                                    label='Search'
                                    variant='outlined'
                                    size='small'
                                    value={searchTerm}
                                    handleSearchChange={handleSearchChange}
                                    margin='normal'
                                    inputAdornmentPosition='start'
                                ></TableSearchBar>
                            }
                            tableButtons={
                                <TableButton
                                    style={{ marginRight: '4px' }}
                                    variant={'outlined'}
                                    color={'primary'}
                                    title='Cancel'
                                    onClick={() => history.push('/admin/care/incident-report/list')}
                                ></TableButton>
                            }
                            dynamicTable={
                                <DynamicTable
                                    columns={LIST_OF_CLIENT_LOG_COLUMNS}
                                    data={filteredData}
                                    canDelete={true}
                                    columnDataMapping={LIST_OF_CLIENT_LOG_COLUMNS_DATA_MAPPING}
                                    onDelete={onDelete}
                                    sortingColumns={LIST_OF_CLIENT_LOG_SORTING_COLUMNS}
                                    title='Are you sure you want to delete this Incident report?'
                                    setQueryData={setQueryData}
                                    queryData={queryData}
                                    tableLoader={isFetchingData}
                                />
                            }
                        />
                }
            </div>
        </div>
    )
}
export default SearchClientLogTable