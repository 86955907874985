
import { useEffect, useState } from 'react'
import { RootState } from '../../../../../../setup';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProfessionalClaim, getProfessionalClaim } from '../store/action';
import TableTitle from '../../../../../reusable-components/tables/TableTitle';
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar';
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable';
import { useHistory } from 'react-router-dom';
import { ListOfBillingDataColumns, ListOfBillingDataSortingColumns, ListOfBillingDateColumnMapping } from '../../../BILLING_TABLE_CONSTANTS';
import { hasPermission } from '../../../../../../_helper/hasPermission';
import { permissions } from '../../../../../../_constants/permissions';
import TableButton from '../../../../../reusable-components/tables/TableButton';
const selectAuth = (state: RootState) => state.auth;
const ListProfessionClaim = () => {
    const [professionalClaim, setProfessionalClaim] = useState<any[]>([])
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [filteredData, setFilteredData] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState('')
    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    useEffect(() => {
        let isMounted = true;
        const fetchProfessionalClaim = async () => {
            setIsFetchingData(true)
            try {
                const professionResult: any = await dispatch(getProfessionalClaim())
                let tempObj: any = professionResult.payload.data;
                for (let i = 0; i < tempObj.length; i++) {
                    tempObj[i].serviceDateFrom = tempObj[i].claimGenSearch.serviceDateFrom
                    tempObj[i].serviceDateTo = tempObj[i].claimGenSearch.serviceDateTo
                }
                setProfessionalClaim(tempObj)
                setFilteredData(tempObj)
            }
            catch (err) {
                console.log(err)
            } finally{
                setIsFetchingData(false)
            }
        }
        fetchProfessionalClaim()
        return () => {
            isMounted = false;
        };
    }, [])
    const OnDeleteProClaim = (row: { _id: String; }) => {
        if (row._id) {
            dispatch(deleteProfessionalClaim(row._id))
        }
    }
    const handleSearchChange = (event: any): void => {
        event.persist()
        console.log(event.target?.value)
        setSearchTerm(() => event.target.value)
        if (event.target?.value) {
            const filteredClients: any = professionalClaim.filter(
                (client) =>
                    client.billingProvider.toLowerCase().includes(event.target?.value.toLowerCase()) ||
                    client.TCNNumber.toLowerCase().includes(event.target?.value.toLowerCase()) ||
                    client.payer.toLowerCase().includes(event.target?.value.toLowerCase()) ||
                    client.claimFrequencyTypeCode.toLowerCase().includes(event.target?.value.toLowerCase()) ||
                    client.claimGenSearch.serviceDateTo.toLowerCase().includes(event.target?.value.toLowerCase()) ||
                    client.claimGenSearch.serviceDateFrom.toLowerCase().includes(event.target?.value.toLowerCase()) ||
                    client.relationToIndividual
                        .toString()
                        .toLowerCase()
                        .includes(event.target?.value.toLowerCase())
            )
            setFilteredData(filteredClients)
        } else setFilteredData(professionalClaim)
    }
    const onUpdate = ({ _id }: { _id: string }) => {
        history.push({
            pathname: `/professionalclaim/professional-claim/list/${_id}`
        })
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Professional Claim List'></TableTitle>
                <TableSearchBar
                    label='Search'
                    variant='outlined'
                    size='small'
                    value={searchTerm}
                    margin='normal'
                    inputAdornmentPosition='start'
                ></TableSearchBar>
                {!!hasPermission(userPermissions, permissions.billing_professionalClaim_create) && <TableButton
                    variant="contained"
                    color="primary"
                    style={{ float: 'right', marginTop: '1.7rem' }}
                    onClick={() => history.push(`/professionalclaim/professional-claim/new`)}
                    title="Add Professional Claim"
                />}
                <DynamicTable
                    columns={ListOfBillingDataColumns}
                    data={filteredData}
                    canUpdate={hasPermission(userPermissions, permissions.billing_professionalClaim_update)}
                    canDelete={hasPermission(userPermissions, permissions.billing_professionalClaim_delete)}
                    columnDataMapping={ListOfBillingDateColumnMapping}
                    sortingColumns={ListOfBillingDataSortingColumns}
                    clickableRow={false}
                    onUpdate={onUpdate}
                    onDelete={OnDeleteProClaim}
                    tableLoader={isFetchingData}
                    title='Are you sure you want to delete the Professional Claim'
                />
            </div>
        </div>
    )
}
export default ListProfessionClaim