import React, { useState, useEffect } from 'react'
import '../../../../../../_helper/tableHeaderSticky/tableHeaderSticky.css'
import axios from 'axios'
import { useSelector } from 'react-redux'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import { LIST_OF_SC_COLUMNS, LIST_OF_SC_COLUMN_DATA_MAPPING, LIST_OF_SC_SORTING_COLUMNS } from '../../GENERAL_TABLE_CONSTANTS'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import { useHistory } from 'react-router-dom'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { permissions } from '../../../../../../_constants/permissions'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import AddButton from '../../../../../reusable-components/Buttons/AddButton'
import BackButton from '../../../../../reusable-components/Buttons/BackButton'
import { toast } from 'react-toastify'
import RouteButton from '../../../../../reusable-components/Buttons/RouteButton'
const selectAuth = (state) => state.auth;
const NewType = () => {
    const [types, setTypes] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [searchTerm, setSearchTerm] = useState('')
    const [queryData, setQueryData] = useState({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm: ''
    })
    const history = useHistory()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    const [deletedId, setDeletedId] = useState('')
    useEffect(() => {
        const fetchData = async (page, pageSize, sortBy, sortDir) => {
            setIsFetchingData(true)
            try{
            const response = await axios.get(`/sharedtype/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`)
            const { data, currentPage, totalCounts } = response.data;
            setTypes(data)
            setFilteredData(data)
            setQueryData((prevState)=> {
                return {
                    ...prevState,
                    currentPage,
                    totalCounts,
                }
            })
            }catch(err){
                console.log(err)
            } finally{
                setIsFetchingData(false)
            }
        }
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, deletedId,queryData.searchTerm])
    const onDelete = (row) => {
        if (row._id) {
            axios.delete("/sharedtype/delete/" + row._id)
                .then(response => {
                    if (response.data && response.data.success) {
                        toast.success(response.data.message || 'Success', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                        setDeletedId(row._id)
                        setQueryData((prevState)=> {
                            return {
                              ...prevState,
                              searchTerm : '',
                              pageNumber: 1
                            }
                          })
                    } else {
                        toast.error(response?.data?.message || 'Error', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                    }
                })
        }
    }
    const onUpdate= ({ _id }) => {
        history.push({
            pathname: `/admin/general/shared-contact/type/list/${_id}`
        })
    }
    const onClickAddButton = () => {
        history.push('/admin/general/shared-contact/type/new')
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Shared Contact Type List'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            setSearchTerm={setSearchTerm}
                            setQueryData={setQueryData}
                            margin='normal'
                            inputAdornmentPosition='start'
                        />
                    }
                    tableButtons={
                        !!hasPermission(userPermissions, permissions.admin_billing_sharedContactType_create) &&
                        <>
                            <AddButton
                            onClick={onClickAddButton}
                        />
                            <RouteButton route='/admin/general/shared-contact/list' />
                        </>
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={LIST_OF_SC_COLUMNS}
                            data={filteredData}
                            canUpdate={hasPermission(userPermissions,permissions.admin_billing_sharedContactType_update)}
                            canDelete={hasPermission(userPermissions,permissions.admin_billingsharedContactType_delete)}
                            columnDataMapping={LIST_OF_SC_COLUMN_DATA_MAPPING}
                            onDelete={onDelete}
                            onUpdate={onUpdate}
                            sortingColumns={LIST_OF_SC_SORTING_COLUMNS}
                            title='Are you sure you want to delete this Shared Contact Type?'
                            setQueryData={setQueryData}
                            queryData={queryData}
                            tableLoader={isFetchingData}
                        />
                    }
                />
            </div>
        </div>
    )
}
export default NewType