import {Fragment, useEffect, useState} from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@mui/material'
import MedicationIcon from '@mui/icons-material/Medication'
import {useLocation, useParams,useHistory} from 'react-router-dom'
import axios from 'axios'
import {toast} from 'react-toastify'
import moment from 'moment'
import {useSelector} from 'react-redux'
import momentTZ from 'moment-timezone'
import DescriptionModal from '../../../../reusable-components/description-modal/descriptionmodal'
import NavigateButton from '../../../../reusable-components/description-modal/navigatebutton'
const selectAuth = (state) => state.auth
const selectTimeZone = (state) => state.timeZone.timeZone

export default function IndividualMedicationDosage() {
  const [data, setData] = useState([])
  const [clientData, setClientData] = useState({})
  const {id} = useParams()
  const history = useHistory();
  const {
    state: {date},
  } = useLocation()
  useEffect(() => {
    ;(async () => {
      const myMoment = moment(date || new Date())
      const startDate = myMoment.startOf('day').toISOString()
      const endDate = myMoment.endOf('day').toISOString()
      try {
        const response = await axios.get(`/individualMedications/getByClient/${id}?status=ACTIVE`)
        const {data: medicationEntries} = await axios.get(
          `/medicationEntries/${id}?startDate=${startDate}&endDate=${endDate}`
        )
        setData(
          response.data.map((currentData, index) => {
            if (index === 0) {
              setClientData({
                name: `${currentData.client.firstName} ${currentData.client.lastName}`,
                gender: currentData.client.gender,
                medicationId: currentData.client.medicaidNumber,
                prescribedOn:currentData.prescribedOn                
              })
            }

            return {
              name: currentData?.medicationListing?.name || '',
              id: currentData?.medicationListing?._id || '',
              dosage: currentData.dosageTimings.map((dosage) => {
                const foundIndex = medicationEntries.findIndex(
                  (b) => b.prescribedDosageTimingId === dosage._id
                )
                let obj = {...dosage, currentDosage: {}}
                if (foundIndex !== -1) {
                  const currentDosage = medicationEntries[foundIndex]
                  obj.update = true
                  obj.currentDosage = {
                    medicationId: currentDosage._id,
                    medicationGiven: currentDosage.medicationGiven,
                    time: currentDosage.dosageTiming,
                    dosage: currentDosage.dosage,
                    comment: currentDosage.comment,
                  }
                }
                return obj
              }),
            }
          })
        )
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  return (
    <Box
    sx={{
      backgroundColor: '#f7f9fc', // Light background for the main container
      borderRadius: '8px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      p: 4,
      maxWidth: '800px',
      margin: 'auto',
    }}
  >
     {!data?.length ? (
        <Typography variant='h4' sx={{fontSize: '20px', fontWeight: '700', textAlign: 'center'}}>
          No Medication Found For This Client
        </Typography>
      ) : (
    <>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 3,
      }}
    >
      
      <Typography variant='h5' sx={{ fontWeight: 700 }}>
        Client Details
      </Typography>
      <span style={{ display: "flex", alignItems: "end",justifyContent:"end",marginLeft:"380px" }}>
      <NavigateButton 
          label="View Edit History" 
          clientId={id} section="medication"
        />
        </span>
      <Button
      type='button'
      sx={{ padding: '4px', margin: '4px' }}
      variant='contained'
      style={{
      color: '#000',
      backgroundColor: '#fff',
       '&:hover': {
      backgroundColor: '#f2f2f2',
      }
     }}
      onClick={() => history.push("/mars/individual-medication/list")}>
      Cancel                                      
    </Button>
    </Box>
  
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        mb: 3,
        p: 3,
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <ListItem title='Name' value={clientData.name || ''} />
      <ListItem title='Medication ID Number' value={clientData.medicationId || ''} />
      <ListItem title='Gender' value={clientData.gender || ''} />
      <ListItem title='Prescribed On' value={moment(clientData.prescribedOn).format('MMMM Do YYYY') || ''} />
      <ListItem title='Date' value={moment(date).format('MMMM Do YYYY') || ''} />
    </Box>
  
    {data?.map((medication, index) => (
      <Box
        key={medication.id}
        sx={{
          mb: 3,
          p: 3,
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography
          variant='h6'
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#009EF7',
            fontWeight: 700,
            mb: 2,
          }}
        >
          <MedicationIcon sx={{ color: '#009EF7', fontSize: '24px', mr: 1 }} />
          {medication.name}
        </Typography>
        
        {medication.dosage.map((_, i) => (
          <Box key={`${medication.id}-${i}`} sx={{ mb: i !== medication.dosage.length - 1 ? 2 : 0 }}>
            <Medican data={{ ..._, medicanId: medication.id, medicineName: medication.name }} date={date} />
            {i !== medication.dosage.length - 1 ? (
              <Divider variant='middle' sx={{ my: 2 }}  key={`divider-${i}`}/>
            ):null}
          </Box>
        ))}
        
        {index !== data.length - 1 ? (
          <Divider sx={{ border: '1px solid #e0e0e0', my: 3 }} key={`final-divider-${index}`}/>
        ):null}
      </Box>
    ))}
    </>
      )}
  </Box>
  )
}

const ListItem = ({title, value}) => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant='h6'>{title}</Typography>
      <Typography variant='p' style={{color: '#000000'}}>
        {value}
      </Typography>
    </Box>
  )
}

const Medican = ({data, date}) => {
  const {id: clientId} = useParams()
  const [formData, setFormData] = useState({
    dosage: data?.currentDosage?.dosage || '',
    time: data?.currentDosage?.time || '',
    comment: data?.currentDosage?.comment || '',
  })
  const [medicanGiven, setMedicanGiven] = useState(data?.currentDosage?.medicationGiven || false)
  const [error, setError] = useState({})
  const auth = useSelector(selectAuth)
  const userId = auth.user._id
  const firstName = auth.user.firstName
  const lastName = auth.user.lastName
  const userEmail = auth.user.email
  const timeZone = useSelector(selectTimeZone)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [pendingSubmit, setPendingSubmit] = useState(false);
  const handleChange = ({target: {value, name}}) => {
    if (Object.keys(error).length) {
      setError({})
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  
  const handleSave = async () => {
    if (!description.trim() && !isModalOpen) {
      setIsModalOpen(true); 
      setPendingSubmit(true); 
      return;
    }
    if (medicanGiven) {
      if (!formData.time) {
        setError((prev) => ({
          time: 'Dosage Time Is Required',
        }))
        return
      }

      if (!formData.dosage) {
        setError((prev) => ({
          dosage: 'Dosage Is Required',
        }))
        return
      }
    } else if (!medicanGiven) {
      if (!formData.comment) {
        setError((prev) => ({
          comment: 'Comment Is Required',
        }))
        return
      }
    }

    try {
      if (data.update) {
        await axios.put(`/medicationEntries/${data.currentDosage.medicationId}`, {
          dosageTiming: medicanGiven ? formData.time :  data?.time || '',
          dosage: medicanGiven ? formData.dosage  : data?.dosage || '',
          comment: formData.comment,
          medicationGiven: medicanGiven,
          hours: parseInt(formData.time.substring(0,2)),
          minutes: parseInt(formData.time.substring(3,5)),
          prescribedTime: data.time,
          medicineName: data?.medicineName
        })
        toast.success('Medication Dosage successfully Updated')
        return
      }

      await axios.post('/medicationEntries/', {
        medicationId: data.medicanId,
        clientId: clientId,
        prescribedDosageTimingId: data._id,
        dosageTiming: medicanGiven ? formData.time :  data?.time || '',
        hours: parseInt(formData.time.substring(0,2)),
        minutes: parseInt(formData.time.substring(3,5)),
        administeredAt: date,
        dosage: medicanGiven ? formData.dosage  : data?.dosage || '',
        comment: formData.comment,
        medicationGiven: medicanGiven,
        attendantId: {firstName: firstName, lastName: lastName, _id: userId, email: userEmail},
        medicineName: data?.medicineName
      })
      toast.success('Medication Dosage successfully Added')
    } catch (error) {
      toast.error('Something went wrong')
    }
  }
  const handleSaveDescription = (desc) => {
    setDescription(desc);
    setIsModalOpen(false);
    setPendingSubmit(false);
    handleSave(); 
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPendingSubmit(false); 
  };
  return (
    <Fragment>
      <Box
        sx={{
          gap: 1,
          ml: 5,
          boxShadow: ' 3px 4px 18px #00000047',
          padding: '20px',
          borderRadius: '15px',
          paddingLeft: '15px',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={medicanGiven}
              onClick={({target: {checked}}) => {
                setFormData({})
                setMedicanGiven(checked)
              }}
            />
          }
          label='Medican Given?'
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            marginBottom: '10px',
          }}
        >
          <div style={{width: '100%'}}>
            <ListItem title='Prescribed Dose' value={data.dosage||""} />
          </div>
          <div style={{width: '100%'}}>
            <ListItem title='Prescribed Time' value={momentTZ.tz(data.time, 'UTC').tz(timeZone).format('hh:mm A')||""} />
          </div>
        </Box>
        <Box
          className='row g-3'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}
        >
          <Box className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
            <TextField
              fullWidth
              label='Time'
              variant='outlined'
              type='time'
              size='small'
              name='time'
              onChange={handleChange}
              value={formData.time||""}
              disabled={!medicanGiven}
              required
              error={Boolean(error.time)}
              helperText={error.time}
              InputLabelProps={{shrink: true}}
            />
          </Box>
          <Box className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
            <TextField
              fullWidth
              label='Dosage'
              sx={{boxSizing: 'border-box'}}
              variant='outlined'
              type='text'
              size='small'
              name='dosage'
              onChange={handleChange}
              required
              value={formData.dosage||""}
              disabled={!medicanGiven}
              error={Boolean(error.dosage)}
              helperText={error.dosage}
              InputLabelProps={{shrink: true}}
            />
          </Box>
        </Box>
        <div className='row g-3'>
          <Box className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3'>
            <TextField
              fullWidth
              label='Comment'
              variant='outlined'
              type='text'
              size='small'
              name='comment'
              required={!medicanGiven}
              error={Boolean(error.comment)}
              helperText={error.comment}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  comment: e.target.value,
                }))

                
              }}
              value={formData.comment||""}
              InputLabelProps={{shrink: true}}
            />
          </Box>
          <Box className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 ' sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant='contained'
              sx={{
                backgroundColor: 'primary',
                color: 'white',
                marginLeft: '4px',
              }}
              onClick={handleSave}
            >
              SAVE
            </Button>
          </Box>
          <DescriptionModal
              open={isModalOpen}
              onClose={handleCloseModal}
              clientID={clientId}
              onSave={handleSaveDescription}
              section="medication"
              />
        </div>
      </Box>
    </Fragment>
  )
}
