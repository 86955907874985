import TestingPage from '../pages/testingpage/Testing'
import BehaviorIndex from '../pages/admin/care/behavior/BehaviorIndex'
import DescriptionTable from '../reusable-components/description-modal/DescriptionTable'
/*Billing Module Routes*/
import ProfessionalClaim from '../pages/billing/professionalclaim/ProfessionalClaim'
import AgencyBasedReportsIndex from '../pages/billing/professionalclaim/agency-based-reports/AgencyBasedReportsIndex'
import BillingConversionIndex from '../pages/billing/professionalclaim/billing-conversion/BillingConversionIndex'
import ClaimTemplateIndex from '../pages/billing/professionalclaim/claim-template/ClaimTemplateIndex'
import ExcelUploadIndex from '../pages/billing/professionalclaim/excel-upload/ExcelUploadIndex'
import BillingDataIndex from '../pages/billing/professionalclaim/billing-data/BillingDataIndex'
import Chat from '../../_metronic/layout/components/header/chat_system/chat'
import CareAdmin from '../pages/admin/care/AdminCare'
import ContactHeader from '../pages/admin/care/contact/ContactIndex'
import IndividualIntakeHeader from '../pages/admin/care/individual-intake/IndividualIntakeIndex'
import SubMenuIndex from '../pages/admin/care/subMenuSection/SubMenuIndex'
import GeneralAdmin from '../pages/admin/general/General'
/*Admin Module Routes*/
import BillingAdmin from '../pages/admin/billing/AdminBilling'
import Clients from '../pages/clients/clients'
import ClientDetails from '../pages/clients/ClientDetails'
import ScoringMethods from '../pages/caregiver/ScoringMethods/ScoringMethods'
import UploadFiles from '../pages/importClients'
import NotificationManager from '../pages/notification-manager/NotificationManager'
import { ListMethod } from '../pages/admin/general/scoring-method/List'

export const allRoutes = [
  {path: '/import/data', component: UploadFiles, permission: 'USER_MANAGEMENT.ROLES.VIEW'},
  // {path: '/reusable-component', component: ReusableTable, permission: true},
  {path: '/testingpage', component: TestingPage, permission: true},
  {path: '/admin/billing', component: BillingAdmin, permission: true},
  {path: '/chat', component: Chat, permission: true},
  {path: '/notification-manager', component: NotificationManager, permission: 'USER_MANAGEMENT.NOTIFICATION_MANAGER.VIEW'},
  {
    path: '/admin/care/individual-intake',
    component: IndividualIntakeHeader,
    permission: 'ADMIN.CARE.CLIENTS.VIEW',
  },
  {path: '/admin/care/contact', component: ContactHeader, permission: 'ADMIN.CARE.CONTACT.VIEW'},
  {
    path: '/admin/general/scoring-method',
    component: ListMethod,
    permission: true,
  },
  {path: '/admin/care', component: CareAdmin, permission: 'ADMIN.CARE.VIEW'},
  {path: '/admin/care/behavior', component: BehaviorIndex, permission: true},

  // {
  //     path: '/admin/care/isp-program-scoring-method',
  //     component: ISPProgramScoringMethodIndex,
  //     permission: true,
  // },
  {path: '/admin/care/sub', component: SubMenuIndex, permission: true},
  {
    path: '/admin/care',
    exact: true,
    to: '/care',
    permission: 'ADMIN.CARE.VIEW',
  }, 

  {path: '/admin/general', exact: true, component: GeneralAdmin, permission: true},
  
  {path: '/billing/professionalclaim', component: ProfessionalClaim, permission: true},
  {
    path: '/professionalclaim/agency-based-reports',
    component: AgencyBasedReportsIndex,
    permission: true,
  },
  {path: '/professionalclaim/billing-data', component: BillingDataIndex, permission: true},
  {
    path: '/professionalclaim/billing-conversion',
    component: BillingConversionIndex,
    permission: true,
  },
  {path: '/professionalclaim/claim-template', component: ClaimTemplateIndex, permission: true},
  {path: '/professionalclaim/excel-upload', component: ExcelUploadIndex, permission: true},

  {path: '/clientdetails', component: ClientDetails, permission: true},
  {
    path: '/caregivers/scoringMethods',
    component: ScoringMethods,
    permission: true,
  },
  {
    path: '/edit-histories',
    component: DescriptionTable,
  },
]
export const allroutesHandler = () => {
}
