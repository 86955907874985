import React from 'react'
import GeneralHeader from './GeneralHeader'

function NursesNotesHeader() {
    return (
        <div>
            <GeneralHeader />
        </div>
    )
}

export default NursesNotesHeader