import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {permissions} from '../../../../../_constants/permissions'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {setClientID} from './store'
import {toast} from 'react-toastify'
import {MoonLoader} from 'react-spinners'
const selectAuth = (state: RootState) => state.auth

const ClientDailog = ({open, onClose}) => {
  const [clients, setClients] = useState([])
  const [selectedClient, setSelectedClient] = useState<{label: ''; value: ''}>({
    label: '',
    value: '',
  })
  const [deleteID, setDeleteID] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId

  const fetchClients = async () => {
    try {
      setLoading(true)
      const response = await axios.get('/clients/getClientNames')
      const {data} = response?.data
      const updatedclients = [
        ...data.map((client) => ({label: client.fullName, value: client._id})),
      ]
      setClients(updatedclients)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchClients()
  }, [deleteID])

  const handleClose = () => {
    if (!open) {
      onClose()
    }
  }
  const handleSave = () => {
    try {
      if (selectedClient) {
        dispatch(setClientID(selectedClient?.value))
        localStorage.setItem('clientID', JSON.stringify(selectedClient?.value))
        if (hasPermission(userPermissions, permissions.admin_care_update)) {
          history.push({
            pathname: `/admin/care/individual-intake/${selectedClient?.value}`,
          })
        } else if (hasPermission(userPermissions, permissions.admin_care_contact_view)) {
          history.push({
            pathname: `/admin/care/contact/list/${selectedClient?.value}`,
          })
        } else if (
          hasPermission(userPermissions, permissions.admin_care_enrollment_byIndividual_update)
        ) {
          history.push({
            pathname: `/admin/care/enrollment/by-client/${selectedClient?.value}`,
          })
        } else if (hasPermission(userPermissions, permissions.admin_care_insurance_update)) {
          history.push({
            pathname: `/admin/care/insurance/${selectedClient?.value}`,
          })
        } else if (hasPermission(userPermissions, permissions.admin_care_healthData_view)) {
          history.push({
            pathname: `/admin/care/health/health-data/${selectedClient?.value}`,
          })
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  const handleClientChange = (_, client) => {
    setSelectedClient(client)
  }
  const onClickAddButton = () => {
    history.push('/admin/care/individual-intake/new')
  }

  const handleDelete = async () => {
    try {
      setIsSubmitting(true) 
      setLoading(true)
      const response = await axios.delete(`/clients/softDelete/${selectedClient?.value}`)
      if (response.status === 200) {
        setDeleteID(selectedClient?.value)
        toast.success('client deleted successfully')
        setSelectedClient({label: '', value: ''})
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{display: 'flex', justifyContent: 'space-between'}}>
        Select Client
          <Button
            variant='contained'
            style={{marginLeft: '8px'}}
            color='error'
            onClick={handleDelete}
            disabled={isSubmitting || !selectedClient?.value}
          >
            Delete
          </Button>
      </DialogTitle>
      <DialogContent>
        <div className='gap-3'>
          <Autocomplete
            size='small'
            id={selectedClient?.value}
            options={clients}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            forcePopupIcon={true}
            value={selectedClient?.label || ''}
            fullWidth
            onChange={handleClientChange}
            disabled={isSubmitting || loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Search for client'
                placeholder={`Search for client`}
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className='pt-5 float-end'>
          <Button variant='contained' color='primary' onClick={() => history.push('/dashboard')}>
            Back
          </Button>
          <Button
            variant='contained'
            style={{marginLeft: '8px'}}
            color='primary'
            onClick={handleSave}
            disabled={isSubmitting || loading}
          >
            Search
          </Button>
          <Button
            variant='contained'
            style={{marginLeft: '8px'}}
            color='primary'
            onClick={onClickAddButton}
            disabled={isSubmitting}
          >
            Add New
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ClientDailog
