import {useState} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import {styled} from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
const UploadComponent = () => {
  const [isUploading, setIsUploading] = useState(false)
  const handleUpload = async (type: 'client' | 'contact', event: any) => {
    setIsUploading(true)
    const file = event.target.files[0]
    if (file) {
      if (file.type !== 'text/csv') {
        toast.error("Invalid File Type (only '.csv' type accepted)")
        return
      }

      try {
        const formData = new FormData()
        formData.append('file', file)
        await axios.post('clients/upload-csv', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        toast.success('Client(s) uploaded successfully')
      } catch (err) {
        toast.error(err?.message || 'Something went wrong')
      } finally {
        setIsUploading(false)
      }

      event.target.value = ''
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InputFileUpload
          disabled={isUploading}
          message={isUploading ? 'Uploading Client(s)' : 'Upload Clients'}
          handleUpload={(event) => handleUpload('client', event)}
        />
        <div style={{textAlign: 'center'}}>
          <a href='/csv/client-import-template.csv' download='client-import-template.csv'>
            <Button variant='contained' color='primary' component='label' disabled={isUploading}>
              Download Template
            </Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default UploadComponent

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

function InputFileUpload({message, disabled, handleUpload}) {
  return (
    <Button
      style={{width: '100px !important'}}
      component='label'
      role={undefined}
      variant='contained'
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      disabled={disabled}
    >
      {message}
      <VisuallyHiddenInput type='file' onChange={handleUpload} />
    </Button>
  )
}
