import DynamicTable from '../../../reusable-components/tables/DynamicTable'
import TableButton from '../../../reusable-components/tables/TableButton'
import TableSearchBar from '../../../reusable-components/tables/TableSearchBar'
import TableTitle from '../../../reusable-components/tables/TableTitle'
import TableWrapper from '../../../reusable-components/tables/TableWrapper'
import {Fragment, useEffect, useState} from 'react'
import AddClientBehavior from '../component/AddClientBehaviors'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import AddBehaviorEntry from '../component/AddBehaviorEntry'
import { toast } from 'react-toastify'
import { hasPermission } from '../../../../_helper/hasPermission'
import { permissions } from '../../../../_constants/permissions'
import { useSelector } from 'react-redux'
const selectAuth = (state) => state.auth;
const CLIENT_BEHAVIOR_TABLE_HEADER = ['Name', 'Target Behaviors', 'Replacement Behaviors']
export const CLIENT_BEHAVIOR_DATA_MAPPING = {
  'Name': 'name',
  'Target Behaviors': 'targetBehavior',
  'Replacement Behaviors': 'replacementBehavior',
}
export const CLIENT_BEHAVIOR_SORTING = {
  'Name': 'name',
  'Target Behaviors': 'targetBehavior',
  'Replacement Behaviors': 'replacementBehavior',
}
export const htmlColumns = ['Replacement Behaviors', 'Target Behaviors']
export default function ClientBehaviour() {
  const [searchTerm, setSearchTerm] = useState('')
  const [addBehaviour, setAddBehviour] = useState(false)
  const [behaviour, setBehaviour] = useState('')
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [filteredData, setFilteredData] = useState([])
  const history = useHistory()
  const [deleteID, setDeletedID] = useState('')
  const [addMedicationEntry, setAddMedicationEntry] = useState(false)
  const [queryData, setQueryData] = useState({
      pageNumber: 1,
      pageSize: 5,
      currentPage: 1,
      totalCounts: 1,
      sortBy: '_id',
      sortDir: 'desc',
      searchTerm: ''
  })
  const auth = useSelector(selectAuth);
  const userPermissions = auth.user.roleId;
  useEffect(() => {
    const fetchData = async (page, pageSize, sortBy, sortDir, searchTerm) => {
      setIsFetchingData(true)
      try {
        const response = await axios.get(`/behaviors?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`)
        const { data, currentPage, totalCounts } = response?.data;
        const newData = data?.map(item => ({
          ...item,
          replacementBehavior: (
            <Fragment>
              {
                item.replacementBehavior.slice(0, 5).map((behaviors, index) => {
                  return (
                  <Fragment key={index}>
                    <p className='mb-1 mt-1'>{behaviors}</p>
                  </Fragment>
                )})
              }
            </Fragment>
          )
        }))
        setFilteredData(newData)
        setQueryData((prevState) => {
            return {
                ...prevState,
                currentPage,
                totalCounts,
            };
        });
        } catch (e) {
          console.log(e)
        } finally{
          setIsFetchingData(false)
        }
    }
    fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm)
  }, [deleteID, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])
  const handleUpdate = (e) => {
    history.push(`/behaviour/edit/${e.clientId}`)
  }
  const onDelete = async (row) => {
    setIsFetchingData(true)
    try {
      if(row._id) {
        const response = await axios.delete(`/behaviors/` + row._id)
        if(response?.data?.success) {
          toast.success(response.data.message || 'Success', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
          setDeletedID(row._id)
          setQueryData((prevState)=> {
              return {
                ...prevState,
                searchTerm : '',
                pageNumber: 1
              }
            })
        } else {
          toast.error(response.data.message || 'Error', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
        }
      }
    } catch (err) {
      console.log('Error in OnDelete', err)
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    });
    } finally{
      setIsFetchingData(false)
    }
  }
  return (
    <>
      {addMedicationEntry ? (
        <AddBehaviorEntry handleClose={() => setAddMedicationEntry(false)} />
      ) : null}
      <div className='card'>
        {addBehaviour ? (
          <AddClientBehavior
            setBehaviour={setBehaviour}
            behaviour={behaviour}
            handleClose={() => {
              setBehaviour('')
              setAddBehviour(false)
            }}
            label='Add Target Behavior'
            title='Target Behavior'
          />
        ) : null}
        <div className='card-body'>
          <TableTitle title={'Client Behavior'} />
          <TableWrapper
            searchField={
              hasPermission(userPermissions, permissions.behavior_client_behavior_search) &&
                <TableSearchBar
                  label='Search'
                  variant='outlined'
                  size='small'
                  value={searchTerm}
                  setQueryData={setQueryData}
                  setSearchTerm={setSearchTerm}
                  margin='normal'
                  inputAdornmentPosition='start'
                />
            }
            tableButtons={
              <>
              {
                 hasPermission(userPermissions, permissions.behavior_client_behavior_create_entry) &&
                  <TableButton
                    variant='contained'
                    color='primary'
                    sx={{float: 'right', marginTop: '1.7rem'}}
                    onClick={(e) => {
                      setAddMedicationEntry(true)
                    }}
                    title='Add Behavior Entry'
                  />
              }
                {
                  hasPermission(userPermissions, permissions.behavior_client_behavior_create) &&
                    <TableButton
                      variant='contained'
                      color='primary'
                      sx={{float: 'right', marginTop: '1.7rem'}}
                      onClick={(e) => {
                        history.push(`/behaviour/add`)
                      }}
                      title='Add Client Behavior'
                    />
                }
              </>
            }
            dynamicTable={
              <DynamicTable
                columns={CLIENT_BEHAVIOR_TABLE_HEADER}
                data={filteredData}
                columnDataMapping={CLIENT_BEHAVIOR_DATA_MAPPING}
                sortingColumns={CLIENT_BEHAVIOR_SORTING}
                canUpdate={hasPermission(userPermissions, permissions.behavior_client_behavior_update)}
                canDelete={hasPermission(userPermissions, permissions.behavior_client_behavior_delete)}
                onDelete={onDelete}
                htmlColumns={htmlColumns}
                onUpdate={handleUpdate}
                title={'Are you sure you want to delete this client behavior?'}
                setQueryData={setQueryData}
                queryData={queryData}
                tableLoader={isFetchingData}
              />
            }
          />
        </div>
      </div>
    </>
  )
}