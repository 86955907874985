import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { NEW_SHARED_CONTACT_DATA, NEW_SHARED_CONTACT_INPUTS } from '../../GENERAL_CONSTANTS'


const SharedContactNew = () => {
    const [sharedTypes, setSharedTypes] = useState([])

    useEffect(() => {
        axios.get("/sharedtype/get")
            .then(res => {
                const tempObj = res.data.data
                const updatedTypeOptions = [
                    ...tempObj.map((type) => ({ label: type.name, value: type._id })),
                ];
                const constantsWithUpdatedSharedContacts = NEW_SHARED_CONTACT_INPUTS.map((field) => {
                    if (field.name === 'type') {
                        return {
                            ...field,
                            options: updatedTypeOptions,
                        };
                    }
                    return field;
                });
                setSharedTypes(constantsWithUpdatedSharedContacts)
            })
    }, [])

    const history = useHistory()

    const handleSubmitForm = async (formData) => {
        const tempObj = {
            title: formData.title,
            firstName: formData.firstName,
            lastName: formData.lastName,
            organizationName: formData.organizationName,
            npiNumber: Number(formData.npiNumber),
            type: formData.type,
            speciality: formData.speciality,
            email: formData.email,
            individualsAdded: [],
            addrInfo: {
                primaryPhone: Number(formData?.addrInfo?.primaryPhone),
                country: formData?.addrInfo?.country,
                st1: formData?.addrInfo?.st1,
                st2: formData?.addrInfo?.st2,
                city: formData?.addrInfo?.city,
                state: formData?.addrInfo?.state,
                zipCode: Number(formData?.addrInfo?.zipCode)
            },
            mailingInfo: {
                primaryPhone: Number(formData?.mailingInfo?.primaryPhone),
                country: formData?.mailingInfo?.country,
                st1: formData?.mailingInfo?.st1,
                st2: formData?.mailingInfo?.st2,
                city: formData?.mailingInfo?.city,
                state: formData?.mailingInfo?.state,
                zipCode: Number(formData?.mailingInfo?.zipCode)
            }
        };
        try {
            const response = await axios.post("/shared/create", tempObj);
            if(response?.data?.status === 400 && response?.data?.success === false) {
                toast.error(response?.data?.message)
            } else if (response.data && response.data.success) {
                toast.success(response?.data?.message || 'Success', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                history.push("/admin/general/shared-contact/list");
            } else {
                toast.error(response?.data?.message || 'Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        } catch (err) {
            console.log(err);
        }
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];
    return (
        <FormWrapper
            title='Shared Contact (New)'
            content={
                <DynamicForm
                    fields={sharedTypes}
                    data={NEW_SHARED_CONTACT_DATA}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )
}

export default SharedContactNew