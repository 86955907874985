import React, {useEffect, useState} from 'react'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import {ALLERGY_INPUTS} from '../CARE_CONSTANTS'
import {useHistory, useParams} from 'react-router-dom'
import axios from 'axios'
import {toast} from 'react-toastify'
import HealthProfileMenu from '../HealthProfile/HealthProfileMenu'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import AdminCareHeader from '../AdminCareHeader'
import { MoonLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import { setPatient } from '../individual-intake/store'
const selectAuth = (state) => state.client;

interface RouteParams {
  clientID: string
  id: string
}

const EditAllergy = () => {
  const [editAllergyData, setEditAllergyData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const parameters = useParams<RouteParams>()
  const {patient} = useSelector(selectAuth);
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        if (patient?.allergyInfo.length > 0) {
          const filteredAllergyInfo = patient?.allergyInfo.filter(
            (allergy) => allergy.uuid === parameters?.id
        );
        setEditAllergyData(filteredAllergyInfo[0]);
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleSubmitForm = async (formData) => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getDate().toString().padStart(2, '0')

    // Create the formatted date string
    const currentDateFormatted = `${year}-${month}-${day}`
    setIsLoading(true)
    try {
      if (formData.identificationDate <= currentDateFormatted) {
        // const response = await axios.put('health/updateAllergy', tempObj)
        const response = await axios.put(`/clients/update/allergyInfo/${parameters.clientID}`, formData)
        if (response?.status === 400 && response?.data?.success === false) {
          toast.error(response?.data?.message)
        } else if (response.status === 200 && response.data && response.data.success) {
          toast.success('Allergy Updated Successfully')
          dispatch(setPatient(response?.data?.data))
          history.push(`/admin/care/health/allergy/list/${parameters.clientID}`)
        } else {
          toast.error('Error Updating Allergy')
        }
      } else {
        toast.error('Identification Date should not be later than today')
      }
    } catch (err) {
      console.log(err)
      toast.error('Error Updating Allergy')
    } finally {
      setIsLoading(false)
    }
  }

  const buttons = [
    {label: 'Cancel', variant: 'contained', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit'},
  ]
  return (
    <div>
      <AdminCareHeader />
      <HealthProfileMenu />
      <div className='card my-10'>
        <div className='card-body'>
          {isLoading ? (
            <div className='overlay d-flex justify-content-center'>
              <MoonLoader color='#9db2fc' size={80} loading={isLoading} />
            </div>
          ) : (
            <div className='mt-5'>
              <TableTitle title='Edit Allergy Form'></TableTitle>
              <div className='mt-8'>
                <DynamicForm
                  fields={ALLERGY_INPUTS}
                  data={editAllergyData}
                  onSubmit={handleSubmitForm}
                  buttons={buttons}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditAllergy
