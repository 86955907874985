import React, {useEffect, useState} from 'react'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import TableWrapper from '../../../../reusable-components/tables/TableWrapper'
import TableSearchBar from '../../../../reusable-components/tables/TableSearchBar'
import {useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import axios from 'axios'
import TableButton from '../../../../reusable-components/tables/TableButton'
import DynamicTable from '../../../../reusable-components/tables/DynamicTable'
import { NOTES_ENTRIES_SORTING_COLUMNS,NOTES_ENTRIES_COLUMNS,NOTES_ENTRIES_COLUMNS_DATA_MAPPING } from '../../../caregiver/NOTES_TABLE_CONSTANTS'
import {toast} from 'react-toastify'
import { permissions } from '../../../../../_constants/permissions'
import { hasPermission } from '../../../../../_helper/hasPermission'
import { QueryData } from '../../../../reusable-components/tables/DynamicTableTypes'
import { RootState } from '../../../../../setup'
const selectAuth = (state: RootState) => state.auth;

export default function NoteEntries() {
  const history = useHistory()
  const [filteredData, setFilteredData] = useState([])
  const [notesEnteriesListing, setNotesEntriesListing] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [getApprove, setApprove] = useState(false)
  const [loadingStates, setLoadingStates] = useState({})
  const [queryData, setQueryData] = useState<QueryData>({
      pageNumber: 1,
      pageSize: 5,
      currentPage: 1,
      totalCounts: 1,
      sortBy: 'creationDateFormatted',
      sortDir: 'desc',
      searchTerm: ''
  })
  const params: { id: string } = useParams()
  const auth = useSelector(selectAuth);
  const userPermissions = auth.user.roleId;

  // use effect to fetch data and load UI
  useEffect(() => {
    getNoteAndNoteEntriesDetail(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm)
  }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])

  const getNoteAndNoteEntriesDetail = (page: number, pageSize: number, sortBy: string, sortDir: string, searchTerm: string) => {
    let axiosArray = []

    let newPromise = axios({
      method: 'get',
      url: '/notes/getNoteById/' + params.id,
    })
    axiosArray.push(newPromise)

    newPromise = axios({
      method: 'get',
      url: `/notesEntry/getAll?noteId=${params.id}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`,
    })
    axiosArray.push(newPromise)

    axios
      .all(axiosArray)
      .then(
        axios.spread(async (...responses) => {
          if (responses[0].data.isApproved) {
            setApprove(true)
          }
          const { data, currentPage, totalCounts } = responses[1].data
          setNotesEntriesListing(data)
          setFilteredData(data)
          setQueryData((prevState)=> {
              return {
                  ...prevState,
                  currentPage,
                  totalCounts,
              }
          })
        })
      )
      .catch((err) => toast.error('Something went wrong'))
  }

  const setLoading = (id, action, isLoading) => {
    setLoadingStates((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [action]: isLoading,
      },
    }))
  }

  const onClickAddButton = () => {
    history.push(`/notes/caregiver-notes/notesEntries/new/${params.id}`)
  }

  const onUpdate = (row) => {
    history.push(`/notes/caregiver-notes/notesEntries/edit/${row['_id']}`)
  }

  const onDownload = async (row) => {
    try{
      setLoading(row._id, 'download', true)
      const response = await axios.post('/notesEntry/generatePDF', row, {responseType: 'arraybuffer'})
      const blob = new Blob([response.data], {type: 'application/pdf'})
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'Notes_Report_For_' + row.client[0].firstName + row.client[0].lastName + '.pdf'
      )
      document.body.appendChild(link)
      link.click()
      setLoading(row._id, 'download', false)
    } catch(err){
      console.error('Error while download note', err)
      setLoading(row._id, 'download', false)
    }
  }
  return (
    <div className='card'>
      <div className='card-body'>
        <TableTitle title='Notes Entries'></TableTitle>
        <TableWrapper
          searchField={
              hasPermission(userPermissions, permissions.care_giver_notes_note_entries_search) &&
                <TableSearchBar
                  label='Search'
                  variant='outlined'
                  size='small'
                  value={searchTerm}
                  setQueryData={setQueryData}
                  setSearchTerm={setSearchTerm}
                  margin='normal'
                  inputAdornmentPosition='start'
                ></TableSearchBar>
          }
          tableButtons={
            getApprove && (
              hasPermission(userPermissions, permissions.care_giver_notes_note_entries_create) &&
              <TableButton
                variant={'contained'}
                color={'primary'}
                title='Create New Entry'
                onClick={onClickAddButton}
              />
            )
          }
          dynamicTable={
            <DynamicTable
              onDownload={onDownload}
              canDownload={hasPermission(userPermissions, permissions.care_giver_notes_note_entries_download)}
              queryData={queryData}
              setQueryData={setQueryData}
              htmlColumns={[]}
              columns={NOTES_ENTRIES_COLUMNS}
              sortingColumns={NOTES_ENTRIES_SORTING_COLUMNS}
              data={filteredData}
              canUpdate={hasPermission(userPermissions, permissions.care_giver_notes_note_entries_update)}
              onUpdate={onUpdate}
              columnDataMapping={NOTES_ENTRIES_COLUMNS_DATA_MAPPING}
              loadingStates={loadingStates}
            />
          }
        />
      </div>
    </div>
  )
}
