import { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { NEW_SHARED_CONTACT_INPUTS } from '../../GENERAL_CONSTANTS'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import { useHistory, useParams } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'


const SharedContactEditForm = () => {
    const [formData, setFormData] = useState({})
    const [sharedTypes, setSharedTypes] = useState([])
    const [isFormLoading, setIsFormLoading] = useState(true)
    const parameter = useParams()
    const history = useHistory()

   useEffect(() => {
  const fetchData = async () => {
    setIsFormLoading(true);
    try {
      if (parameter.id) {
        const [SharedContact, SharedContactType] = await Promise.all([
          axios.get(`/shared/getOne?id=${parameter.id}`),
          axios.get("/sharedtype/get"),
        ]);

        const { data } = SharedContact;
        const updatedTypeOptions = SharedContactType.data.data.map((type) => ({
          label: type.name,
          value: type._id,
        }));

        const constantsWithUpdatedSharedContacts = NEW_SHARED_CONTACT_INPUTS.map((field) => {
          if (field.name === "type") {
            return {
              ...field,
              options: updatedTypeOptions,
            };
          }
          return field;
        });

        const tempObj = {
          _id: data._id,
          title: data.title,
          firstName: data.firstName,
          lastName: data.lastName,
          organizationName: data.organizationName,
          npiNumber: data.npiNumber,
          type: data.type,
          speciality: data.speciality,
          email: data.email,
          addrInfo: {
            primaryPhone: data.addrInfo.primaryPhone,
            country: data.addrInfo.country,
            st1: data.addrInfo.st1,
            st2: data.addrInfo.st2,
            city: data.addrInfo.city,
            state: data.addrInfo.state,
            zipCode: data.addrInfo.zipCode,
          },
          mailingInfo: {
            primaryPhone: data.mailingInfo.primaryPhone,
            country: data.mailingInfo.country,
            st1: data.mailingInfo.st1,
            st2: data.mailingInfo.st2,
            city: data.mailingInfo.city,
            state: data.mailingInfo.state,
            zipCode: data.mailingInfo.zipCode,
          },
        };

        setFormData(tempObj);
        setSharedTypes(constantsWithUpdatedSharedContacts);
      } else {
        history.push("/admin/general/shared-contact/list");
      }
    } catch (error) {
      console.error("Error fetching shared contact data", error);
    } finally {
      setIsFormLoading(false);
    }
  };

  fetchData();
}, [parameter.id, history]);



    const handleSubmitForm = async (formData) => {
        const tempObj = {
            _id: formData._id,
            title: formData.title,
            firstName: formData.firstName,
            lastName: formData.lastName,
            organizationName: formData.organizationName,
            npiNumber: Number(formData.npiNumber),
            type: formData.type,
            speciality: formData.speciality,
            email: formData.email,
            addrInfo: {
                primaryPhone: Number(formData?.addrInfo?.primaryPhone),
                country: formData?.addrInfo?.country,
                st1: formData?.addrInfo?.st1,
                st2: formData?.addrInfo?.st2,
                city: formData?.addrInfo?.city,
                state: formData?.addrInfo?.state,
                zipCode: Number(formData?.addrInfo?.zipCode)
            },
            mailingInfo: {
                primaryPhone: Number(formData?.mailingInfo?.primaryPhone),
                country: formData?.mailingInfo?.country,
                st1: formData?.mailingInfo?.st1,
                st2: formData?.mailingInfo?.st2,
                city: formData?.mailingInfo?.city,
                state: formData?.mailingInfo?.state,
                zipCode: Number(formData?.mailingInfo?.zipCode)
            }
        };
        setIsFormLoading(true);

        try {
            const response = await axios.put("/shared/update", tempObj);
            if(response?.data?.status === 400 && response?.data?.success === false) {
                toast.error(response?.data?.message)
            } else if (response.data && response.data.success) {
                toast.success(response.data.message || 'Success', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                history.push({
                    pathname: `/admin/general/shared-contact/list`
                })
            } else {
                toast.error(response?.data?.message || 'Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        } catch (err) {
            console.log(err);
        } finally{
            setIsFormLoading(false)
        }
    }

    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];


    console.log(sharedTypes , NEW_SHARED_CONTACT_INPUTS,formData)

    return (
    <div className='card my-10'>
        <div className='card-body'>
          {isFormLoading ? (
            <div className='overlay d-flex align-middle justify-content-center align-middle' style={{marginTop:"100px"}}>
              <MoonLoader color='#9db2fc' size={60} loading={isFormLoading} />
            </div>
          ) : (
        <FormWrapper
            title='Edit Shared Contact'
            content={
                <DynamicForm
                    fields={sharedTypes}
                    data={formData}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )}
    </div>
  </div>
    )
}

export default SharedContactEditForm