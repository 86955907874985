import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import {CLIENT_LOG_FORM_INPUTS, NEW_CLIENT_LOG} from '../../CARE_CONSTANTS'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'
import {addIncidentReport} from '../store/action'
import {Typography} from '@mui/material'

const selectAuth = (state) => state.client

const AddClientLogForm = () => {
  const {clientID} = useSelector(selectAuth)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleSubmitForm = async (formData) => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getDate().toString().padStart(2, '0')
    const reportedOn = formData.reportedOn?.split("T")[0]
    // Create the formatted date string
    const currentDateFormatted = `${month}-${day}-${year}`
    try {
      const tempObj = {
        type: formData.type,
        summary: formData.summary,
        description: formData.description,
        reportedOn: reportedOn,
        intensityLevel: formData.intensityLevel,
        clientID: clientID,
      }
      if (new Date(reportedOn) <= new Date(currentDateFormatted)) {
        const response = await dispatch(addIncidentReport(tempObj))
        if (response?.payload?.status === 400 && response?.payload?.success === false) {
          toast.error(response?.payload?.message)
        } else if (response?.payload?.success) {
          toast.success(response?.payload?.message || 'Success', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          history.push(`/admin/care/incident-report/list/${clientID}`)
        } else {
          toast.error(
            response.payload?.response?.data?.message || response.payload?.message || 'Error',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )
        }
      } else {
        toast.error('Reported Date should not be later than today')
      }
    } catch (error) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const buttons = [
    {label: 'Cancel', variant: 'outlined', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit'},
  ]

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <Typography
            variant='h5'
            sx={{fontWeight: 'bold', marginBottom: '1.5rem'}}
          >{`Add Incident Report`}</Typography>
          <DynamicForm
            fields={CLIENT_LOG_FORM_INPUTS}
            data={NEW_CLIENT_LOG}
            onSubmit={handleSubmitForm}
            buttons={buttons}
          />
        </div>
      </div>
    </>
  )
}

export default AddClientLogForm
