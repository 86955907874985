const COGNITO = {
  REGION: process.env.REACT_APP_COGNITO_REGION,
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_COGNITO_DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
  REDIRECT_SIGNIN: process.env.NODE_ENV === 'development' ? 
    process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_LOCAL : 
    process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
  REDIRECT_SIGNOUT: process.env.NODE_ENV === 'development' ? 
    process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT_LOCAL : 
    process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
};
export default COGNITO;
