import { useEffect, useState } from 'react'
import axios from 'axios'
import '../../../_helper/tableHeaderSticky/tableHeaderSticky.css'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import TableTitle from '../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../reusable-components/tables/DynamicTable'
import { hasPermission } from '../../../_helper/hasPermission'
import { USERS_COLUMNS_DATA_MAPPING, USERS_COLUMNS, USERS_SORTING_COLUMNS } from './users_table_constants'
import TableWrapper from '../../reusable-components/tables/TableWrapper'
import AddButton from '../../reusable-components/Buttons/AddButton'
import { QueryData } from '../../reusable-components/tables/DynamicTableTypes'
import { RootState } from '../../../setup'
const Users = () => {
    const history = useHistory()
    const [filteredData, setFilteredData] = useState([])
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [searchTerm, setSearchTerm] = useState('')
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'fullName',
        sortDir: 'asc',
        searchTerm: ''
    })
    // const [editFormData, setEditFormData] = useState({
    //     _id: '',
    //     firstName: '',
    //     lastName: '',
    //     email: '',
    //     roleName: '',
    //     updatedAt: '',
    // })
    const [deleteUser, setDeleteUser] = useState({
        _id: ''
    })
    const authObj = useSelector((state: RootState) => state.auth)
    const userPermissions = authObj.user.roleId;
    useEffect(() => {
        const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string, searchTerm : string) => {
            setIsFetchingData(true)
            try{
            const response = await axios.get(`/user/users?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchUserTerm=${searchTerm}`)
            const { data, currentPage, totalCounts } = response.data;
                setFilteredData(data)
                setQueryData((prevState)=> {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    }
                })
            } catch(err){
                console.log(err)
            } finally{
                setIsFetchingData(false)
            }
        }
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm)
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm, deleteUser])
    // Function to handle deletion of a row by its ID
    const onDelete = async (user: any) => {
        setIsFetchingData(true)
        try {
            console.log(user)
            const response = await axios.delete(`user/deleteUser`, {
                data: {
                    deleteID: user._id,
                },
            })
            if (response.status === 200) {
                setFilteredData((prevData) => prevData.filter((item) => item._id !== user._id))
                toast.success('User deleted successfully!')
                setDeleteUser(user._id)
            }
        } catch (error) {
            console.error('Error deleting data:', error)
            toast.error('Error deleting User')
        } finally{
            setIsFetchingData(false)
        }
    }
    const onUpdate = (user) => {
        history.push(`/users/${user._id}`, { state: user })
    }
    const onClickAddButton = () => {
        history.push('/users/new')
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Users List'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            setQueryData={setQueryData}
                            setSearchTerm={setSearchTerm}
                            margin='normal'
                            inputAdornmentPosition='start'
                        />
                    }
                    tableButtons={
                        hasPermission(userPermissions, 'USER_MANAGEMENT.USER.CREATE') &&
                        <AddButton
                            onClick={onClickAddButton}
                        />
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={USERS_COLUMNS}
                            data={filteredData}
                            canUpdate={!!hasPermission(userPermissions, 'USER_MANAGEMENT.USER.UPDATE')}
                            canDelete={!!hasPermission(userPermissions, 'USER_MANAGEMENT.USER.DELETE')}
                            columnDataMapping={USERS_COLUMNS_DATA_MAPPING}
                            sortingColumns={USERS_SORTING_COLUMNS}
                            onDelete={onDelete}
                            onUpdate={onUpdate}
                            title='Are you sure you want to delete this User?'
                            setQueryData={setQueryData}
                            queryData={queryData}
                            tableLoader={isFetchingData}
                        />
                    }
                />
            </div>
        </div>
    )
}
export default Users