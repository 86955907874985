import {Redirect, Route, Switch} from 'react-router-dom'
import NewIndividual from '../../../../../pages/admin/care/individual-intake/new/New'
import IndividualList from '../../../../../pages/admin/care/individual-intake/listIndividual/List'
import EditIndividualForm from '../../../../../pages/admin/care/individual-intake/listIndividual/Edit'
import React from 'react'
import {useSelector} from 'react-redux'
import {hasPermission} from '../../../../../../_helper/hasPermission'
import {Error404} from '../../../../../modules/errors/components/Error404'
import SearchData from '../../../../../pages/admin/care/individual-intake/searchFields/SearchData'
import SearchFields from '../../../../../pages/admin/care/individual-intake/searchFields/SearchFields'
import {RouteWithPermission} from '../../../../../../_helper/routesWithPermission'

const IndividualIntakeIndexRoutes: React.FC = () => {
  const {auth, client} = useSelector((state: {auth: any; client: any}) => state)
  const userPermissions = auth.user.roleId
  const canUpdate = hasPermission(userPermissions, 'ADMIN.CARE.CLIENTS.UPDATE')
  const canCreate = hasPermission(userPermissions, 'ADMIN.CARE.CLIENTS.CREATE')
  const canRead = hasPermission(userPermissions, 'ADMIN.CARE.CLIENTS.VIEW')

  const getDefaultPath = () => {
    if (client.clientID) {
      if (canUpdate) return `/admin/care/individual-intake/${client.clientID}`
      if (canCreate) return '/admin/care/individual-intake/new'
      return '/error/404'
    }
  }

  return (
    <Switch>
      <Route exact path='/admin/care/individual-intake'>
        <Redirect to={getDefaultPath()} />
      </Route>
      <RouteWithPermission
        path='/admin/care/individual-intake/new'
        component={NewIndividual}
        hasPerm={canRead && canCreate}
      />
      <RouteWithPermission
        path='/admin/care/individual-intake/:id'
        component={EditIndividualForm}
        hasPerm={canRead && canUpdate}
      />
      <Route path='/error/404' component={Error404} />
    </Switch>
  )
}

export default IndividualIntakeIndexRoutes
