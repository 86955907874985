import axios from 'axios'
import React, {useEffect, useState} from 'react'
import SchedulesDynamicTable from '../../../../reusable-components/tables/SchedulesDynamicTable'
import {
  caregiverSchedulescolumns,
  caregiverSchedulescolumnsDataMapping,
} from '../../CAREGIVER_SCHEDULES_TABLE_CONSTANTS'
import DynamicAddModal from '../../../../reusable-components/Modals/DynamicModal'
import {CAREGIVER_ADD_SCHEDULE_FORM_INPUTS} from '../../CAREGIVER_ADD_SCHEDULE_FORM_CONSTANTS'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import CareGiverScheduleTabs from '../Tabs/SchedulesTabs'
import dayjs from 'dayjs'
import '../../Style.css'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {permissions} from '../../../../../_constants/permissions'
import Modal from '@mui/material/Modal'
import {Card, CardContent, Typography, Box, Autocomplete, TextField, Button} from '@mui/material'
import {makeStyles} from '@mui/styles'

const selectAuth = (state: RootState) => state.auth

const useStyles = makeStyles({
  card: {
    maxWidth: '90%',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  content: {
    padding: '0rem',
  },
  fieldContainer: {
    flex: 1,
    marginRight: '8px',
  },
  root: {
    marginTop: '24px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    padding: '10px 0',
    paddingRight: '4.5rem',
  },
  cancelButton: {
    color: '#000',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  },
  submitButton: {
    marginLeft: '6px',
    backgroundColor: '#28a745',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#218838',
    },
  },
})

export default function AllSchedules() {
  const [schedulesList, setSchedulesList] = useState([])
  const [selectedDay, setSelectedDay] = useState('')
  const [formData, setFormData] = useState({})
  const [employes, setEmployes] = useState([])
  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false)
  const [showEditScheduleModal, setShowEditScheduleModal] = useState(false)
  const [showCopyScheduleModal, setShowCopyScheduleModal] = useState(false)
  let curr = new Date()
  const [startDate, setStartDate] = useState(new Date(curr.setDate(curr.getDate() - curr.getDay())))
  const [currentWeekStartDate, setCurrentWeekStartDate] = useState(
    new Date(curr.setDate(curr.getDate() - curr.getDay()))
  )
  const [lastDate, setLastDate] = useState(
    new Date(curr.setDate(curr.getDate() - curr.getDay() + 6))
  )
  const [uiElements, setUIElements] = useState([])
  const [rolesToShow, setRolesToShow] = useState([])
  const [weeksToShow, setWeeksToShow] = useState([])
  const [rolesWithWages, setRolesWithWages] = useState([])
  const [rolesWithNames, setRolesWithNames] = useState([])
  const [dataSwitch, setDataSwitch] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [helperText, setHelperText] = useState('')
  const [nextWeekSchedules, setNextWeekSchedules] = useState([])
  const [newWeekStartDate, setNewWeekStartDate] = useState()
  const [newWeekEndDate, setNewWeekEndDate] = useState()
  const [weeklyStats, setWeeklyStats] = useState({})
  const [locationsData, setLocationsData] = useState([])
  let [onlyDisabledSchedules, setOnlyDisabledSchedules] = useState(true)


  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId

  useEffect(() => {
    let axiosArray = []
    let postData = {}
    let newPromise
    newPromise = axios({
      method: 'get',
      url: `caregivers/getByUserId/${auth.user._id}`,
    })
    axiosArray.push(newPromise)
    postData['startDate'] = startDate
    postData['endDate'] = lastDate
    newPromise = axios({
      method: 'post',
      url: `/schedules/getAllEmployesSchedules`,
      data: postData,
    })
    axiosArray.push(newPromise)

    newPromise = axios({
      method: 'post',
      url: `/openshifts/getAllOpenShifts`,
      data: postData,
    })
    axiosArray.push(newPromise)

    newPromise = axios({
      method: 'get',
      url: `/address/getAll`,
    })
    axiosArray.push(newPromise)

    axios
      .all(axiosArray)
      .then(
        axios.spread(async (...responses) => {
          if (responses[0].data?.careGiver?.roles) {
            let tempRoles = []
            responses[0].data.careGiver.roles.map((eachRole, index) => {
              tempRoles.push({
                label: responses[0].data.careGiver.role[index].name,
                value: index,
              })
            })
            setRolesToShow(tempRoles)
            setRolesWithWages(responses[0].data?.careGiver?.roles)
            setRolesWithNames(responses[0].data?.careGiver?.role)
          }
          setWeeklyStats(responses[1]?.data?.weeklyStats)
          setEmployes(responses[1]?.data?.employes)
          if (responses[3].data) {
            let tempLocations = []
            responses[3].data?.map((eachSite) => {
              tempLocations.push({
                label: `${eachSite?.st1} ${
                  eachSite?.st2
                }, ${eachSite?.city}, ${eachSite?.state}, ${eachSite?.country}`,
                value: {
                  address: `${eachSite?.st1} ${
                    eachSite?.st2
                  }, ${eachSite?.city}, ${eachSite?.state}, ${eachSite?.country}`,
                  lat: eachSite?.lat,
                  lng: eachSite?.lng,
                  typeOfLocation: 'site',
                  idOfDoc: eachSite?._id,
                },
              })
            })
            setLocationsData(tempLocations)
          }
          await prepareSchdeules(responses[2].data, responses[1].data.employes)
        })
      )
      .catch((error) => console.error(error))
  }, [startDate, dataSwitch])

  const prepareSchdeules = (openShifts, employes) => {
    setSchedulesList((prevState) => [openShifts])
    employes.map((eachEmploye, index) => {
      let firstName,
        lastName,
        userId,
        days,
        scheduleId,
        isPublished,
        mondayShifts = [],
        tuesdayShifts = [],
        wednesdayShifts = [],
        thursdayShifts = [],
        fridayShifts = [],
        saturdayShifts = [],
        sundayShifts = [],
        wages = [],
        roleNamesArray = [],
        roles = [],
        rolesWithNames = [],
        rolesWithWages = [],
        showInSchedule
      firstName = eachEmploye.firstName
      lastName = eachEmploye.lastName
      userId = eachEmploye.user
      days = eachEmploye.scheduleDetails[0]?.days
      isPublished = eachEmploye.scheduleDetails[0]?.isPublished
      scheduleId = eachEmploye.scheduleDetails[0]?._id
      rolesWithNames = eachEmploye.role
      rolesWithWages = eachEmploye.roles
      showInSchedule = eachEmploye?.locationSettings?.showInSchedule || false
      eachEmploye.role.map((eachRole, index) => {
        roles.push({
          label: eachRole.name,
          value: index,
        })
      })
      if (eachEmploye.scheduleDetails.length > 0) {
        if (eachEmploye.scheduleDetails[0].days.length > 0) {
          eachEmploye.scheduleDetails[0].days.map((eachDay) => {
            if (eachDay.name == 'monday') {
              mondayShifts = eachDay.shifts
              mondayShifts.map((eachShift) => {
                eachShift.dayId = eachDay._id
                if (!roleNamesArray.toString().includes(eachShift.roleName)) {
                  roleNamesArray.push(eachShift.roleName)
                  wages.push({
                    roleName: eachShift.roleName,
                    wage: eachShift.wage,
                    wageUnit: eachShift.wageUnit,
                  })
                }
              })
            }
            if (eachDay.name == 'tuesday') {
              tuesdayShifts = eachDay.shifts
              tuesdayShifts.map((eachShift) => {
                eachShift.dayId = eachDay._id
                if (!roleNamesArray.toString().includes(eachShift.roleName)) {
                  roleNamesArray.push(eachShift.roleName)
                  wages.push({
                    roleName: eachShift.roleName,
                    wage: eachShift.wage,
                    wageUnit: eachShift.wageUnit,
                  })
                }
              })
            }
            if (eachDay.name == 'wednesday') {
              wednesdayShifts = eachDay.shifts
              wednesdayShifts.map((eachShift) => {
                eachShift.dayId = eachDay._id
                if (!roleNamesArray.toString().includes(eachShift.roleName)) {
                  roleNamesArray.push(eachShift.roleName)
                  wages.push({
                    roleName: eachShift.roleName,
                    wage: eachShift.wage,
                    wageUnit: eachShift.wageUnit,
                  })
                }
              })
            }
            if (eachDay.name == 'thursday') {
              thursdayShifts = eachDay.shifts
              thursdayShifts.map((eachShift) => {
                eachShift.dayId = eachDay._id
                if (!roleNamesArray.toString().includes(eachShift.roleName)) {
                  roleNamesArray.push(eachShift.roleName)
                  wages.push({
                    roleName: eachShift.roleName,
                    wage: eachShift.wage,
                    wageUnit: eachShift.wageUnit,
                  })
                }
              })
            }
            if (eachDay.name == 'friday') {
              fridayShifts = eachDay.shifts
              fridayShifts.map((eachShift) => {
                eachShift.dayId = eachDay._id
                if (!roleNamesArray.toString().includes(eachShift.roleName)) {
                  roleNamesArray.push(eachShift.roleName)
                  wages.push({
                    roleName: eachShift.roleName,
                    wage: eachShift.wage,
                    wageUnit: eachShift.wageUnit,
                  })
                }
              })
            }
            if (eachDay.name == 'saturday') {
              saturdayShifts = eachDay.shifts
              saturdayShifts.map((eachShift) => {
                eachShift.dayId = eachDay._id
                if (!roleNamesArray.toString().includes(eachShift.roleName)) {
                  roleNamesArray.push(eachShift.roleName)
                  wages.push({
                    roleName: eachShift.roleName,
                    wage: eachShift.wage,
                    wageUnit: eachShift.wageUnit,
                  })
                }
              })
            }
            if (eachDay.name == 'sunday') {
              sundayShifts = eachDay.shifts
              sundayShifts.map((eachShift) => {
                eachShift.dayId = eachDay._id
                if (!roleNamesArray.toString().includes(eachShift.roleName)) {
                  roleNamesArray.push(eachShift.roleName)
                  wages.push({
                    roleName: eachShift.roleName,
                    wage: eachShift.wage,
                    wageUnit: eachShift.wageUnit,
                  })
                }
              })
            }
          })
        }
      }
      if (showInSchedule) setOnlyDisabledSchedules(false)
      if (
        showInSchedule ||
        (!showInSchedule &&
          (mondayShifts.length != 0 ||
            tuesdayShifts.length != 0 ||
            wednesdayShifts.length != 0 ||
            thursdayShifts.length != 0 ||
            fridayShifts.length != 0 ||
            saturdayShifts.length != 0 ||
            sundayShifts.length != 0))
      )
        setSchedulesList((prevState) => [
          ...prevState,
          {
            firstName: firstName,
            lastName: lastName,
            userId: userId,
            mondayShifts: mondayShifts,
            tuesdayShifts: tuesdayShifts,
            wednesdayShifts: wednesdayShifts,
            thursdayShifts: thursdayShifts,
            fridayShifts: fridayShifts,
            saturdayShifts: saturdayShifts,
            sundayShifts: sundayShifts,
            scheduleId: scheduleId,
            isPublished: isPublished,
            wages: wages,
            roles: roles,
            rolesWithNames: rolesWithNames,
            rolesWithWages: rolesWithWages,
            showInSchedule: showInSchedule,
          },
        ])
    })
  }

  const moveDatesForward = () => {
    var firstDay = new Date(startDate.setDate(startDate.getDate() + 7))
    var lastDay = new Date(lastDate.setDate(lastDate.getDate() + 7))
    setStartDate(firstDay)
    setLastDate(lastDay)
  }

  const moveDatesBackward = () => {
    var firstDay = new Date(startDate.setDate(startDate.getDate() - 7))
    var lastDay = new Date(lastDate.setDate(lastDate.getDate() - 7))
    setStartDate(firstDay)
    setLastDate(lastDay)
  }

  const onClickCell = async (index, rowIndex) => {
    let shiftAlreadyCreated = false
    let day = 'Sunday'
    shiftAlreadyCreated = schedulesList[rowIndex]['sundayShifts'].length > 0
    if (index % 7 == 0) {
      day = 'Saturday'
      shiftAlreadyCreated = schedulesList[rowIndex]['saturdayShifts'].length > 0
    } else if (index % 6 == 0) {
      day = 'Friday'
      shiftAlreadyCreated = schedulesList[rowIndex]['fridayShifts'].length > 0
    } else if (index % 5 == 0) {
      day = 'Thursday'
      shiftAlreadyCreated = schedulesList[rowIndex]['thursdayShifts'].length > 0
    } else if (index % 4 == 0) {
      day = 'Wednesday'
      shiftAlreadyCreated = schedulesList[rowIndex]['wednesdayShifts'].length > 0
    } else if (index % 3 == 0) {
      day = 'Tuesday'
      shiftAlreadyCreated = schedulesList[rowIndex]['tuesdayShifts'].length > 0
    } else if (index % 2 == 0) {
      day = 'Monday'
      shiftAlreadyCreated = schedulesList[rowIndex]['mondayShifts'].length > 0
    }
    let prevData = formData
    prevData = {}
    // subtracting 1 because 1st row is open for shifts
    prevData['userId'] = employes[rowIndex - 1]['user']
    let timeStart = new Date(startDate)
    let days = index - 1
    timeStart.setDate(timeStart.getDate() + days)
    let timeEnd = new Date(startDate)
    timeEnd.setDate(timeEnd.getDate() + days)
    timeStart.setHours(0)
    timeStart.setMinutes(0)
    timeStart.setSeconds(0)
    timeStart.setMilliseconds(0)
    timeEnd.setHours(2)
    timeEnd.setMinutes(0)
    timeEnd.setSeconds(0)
    timeEnd.setMilliseconds(0)
    prevData['timeStart'] = dayjs(timeStart).toDate()
    prevData['timeEnd'] = dayjs(timeEnd).toDate()
    setRolesToShow(schedulesList[rowIndex]['roles'])
    setRolesWithWages(schedulesList[rowIndex]['rolesWithWages'])
    setRolesWithNames(schedulesList[rowIndex]['rolesWithNames'])
    setFormData(prevData)
    setSelectedDay(day)
    const elements = CAREGIVER_ADD_SCHEDULE_FORM_INPUTS({
      day,
      rolesToShow: schedulesList[rowIndex]['roles'],
      locations: locationsData,
      shiftAlreadyCreated
    })
    setUIElements(elements)
    setShowAddScheduleModal(true)
  }

  const handleDiscardAdd = () => {
    setShowAddScheduleModal(false)
  }

  const handleDiscardEdit = () => {
    setShowEditScheduleModal(false)
  }

  const changeDataSwitch = () => {
    setDataSwitch(!dataSwitch)
  }

  const handleSubmitForm = async (formData) => {
    let timeStartTimeStamp = formData['timeStart']
    let timeEndTimeStamp = formData['timeEnd']
    let diff = formData['timeEnd'].getTime() - formData['timeStart'].getTime()
    let diffInSec = diff / 1000
    let diffInMins = diffInSec / 60
    let numberOfHours = diffInMins / 60
    let numberOfMinutes = diffInMins % 60
    if (numberOfHours < 0) numberOfHours = numberOfHours + 24
    if (numberOfMinutes < 0) numberOfMinutes = numberOfMinutes + 60
    numberOfHours = parseInt(numberOfHours.toString())
    numberOfMinutes = parseInt(numberOfMinutes.toString())

    let startingHours = formData['timeStart'].getHours()
    let startingMins = formData['timeStart'].getMinutes()
    let startingAmOrPm = startingHours >= 12 ? 'pm' : 'am'
    if (startingHours > 12) {
      startingHours = startingHours - 12
    } else if (startingHours == 0) {
      startingHours = 12
    }

    let endingHours = formData['timeEnd'].getHours()
    let endingMins = formData['timeEnd'].getMinutes()
    let endingAmOrPm = endingHours >= 12 ? 'pm' : 'am'
    if (endingHours > 12) {
      endingHours = endingHours - 12
    } else if (endingHours == 0) {
      endingHours = 12
    }

    let timeStart = `${startingHours < 10 ? '0' + startingHours : startingHours}:${
      startingMins < 10 ? '0' + startingMins : startingMins
    } ${startingAmOrPm}`
    let timeEnd = `${endingHours < 10 ? '0' + endingHours : endingHours}:${
      endingMins < 10 ? '0' + endingMins : endingMins
    } ${endingAmOrPm}`

    await axios
      .post('/schedules/create', {
        userId: formData['userId'],
        startDate: startDate,
        endDate: lastDate,
        numberOfHours: numberOfHours,
        day: selectedDay.toLowerCase(),
        timeStart: timeStart,
        timeEnd: timeEnd,
        timeStartTimeStamp: timeStartTimeStamp,
        timeEndTimeStamp: timeEndTimeStamp,
        isPreferred: formData['availability'] == 'preferred' ? true : false,
        isUnavailable: formData['availability'] == 'unavailable' ? true : false,
        isWholeDay: formData['wholeDay'] ? true : false,
        applyTo: formData['applyTo'],
        locations: formData['locations'] ? formData['locations'] : [],
        note: formData['note'],
        roleId: rolesWithNames[formData['roleName']]._id,
        roleName: rolesWithNames[formData['roleName']].name,
        wage: rolesWithWages[formData['roleName']].wage,
        wageUnit: rolesWithWages[formData['roleName']].wageUnit,
        numberOfMinutes: numberOfMinutes,
        sentByAdmin: true,
      })
      .then((res) => {
        setDataSwitch(!dataSwitch)
        handleDiscardAdd()
        if (res.status == 201) toast.success('Schedule added successfully')
      })
      .catch((e) => {
        setDataSwitch(!dataSwitch)
        toast.error(e.response.data.error ? e.response.data.error : `Couldn't add schedule`)
      })
  }

  const handleSubmitCopy = async () => {
    await axios
      .post('/schedules/copyAndPasteSchedule', {
        oldWeekStartDate: startDate,
        oldWeekEndDate: lastDate,
        newWeekStartDate: newWeekStartDate,
        newWeekEndDate: newWeekEndDate,
      })
      .then((res) => {
        setDataSwitch(!dataSwitch)
        setShowCopyScheduleModal(false)
        handleDiscardAdd()
        if (res.status == 201) toast.success('Schedule added successfully')
      })
      .catch((e) => {
        setDataSwitch(!dataSwitch)
        toast.error(e.response.data.error ? e.response.data.error : `Couldn't add schedule`)
      })
  }

  const handleSubmitEditForm = async (formData) => {
    let timeStartTimeStamp = formData['timeStart']
    let timeEndTimeStamp = formData['timeEnd']
    formData['timeEnd'] = formData['timeEnd'].$d ? formData['timeEnd'].$d : formData['timeEnd']
    formData['timeStart'] = formData['timeStart'].$d
      ? formData['timeStart'].$d
      : formData['timeStart']
    let diff = formData['timeEnd'].getTime() - formData['timeStart'].getTime()
    let diffInSec = diff / 1000
    let diffInMins = diffInSec / 60
    let numberOfHours = diffInMins / 60
    let numberOfMinutes = diffInMins % 60
    if (numberOfHours < 0) numberOfHours = numberOfHours + 24
    if (numberOfMinutes < 0) numberOfMinutes = numberOfMinutes + 60
    numberOfHours = parseInt(numberOfHours.toString())
    numberOfMinutes = parseInt(numberOfMinutes.toString())

    let startingHours = formData['timeStart'].getHours()
    let startingMins = formData['timeStart'].getMinutes()
    let startingAmOrPm = startingHours >= 12 ? 'pm' : 'am'
    if (startingHours > 12) {
      startingHours = startingHours - 12
    } else if (startingHours == 0) {
      startingHours = 12
    }

    let endingHours = formData['timeEnd'].getHours()
    let endingMins = formData['timeEnd'].getMinutes()
    let endingAmOrPm = endingHours >= 12 ? 'pm' : 'am'
    if (endingHours > 12) {
      endingHours = endingHours - 12
    } else if (endingHours == 0) {
      endingHours = 12
    }

    let timeStart = `${startingHours < 10 ? '0' + startingHours : startingHours}:${
      startingMins < 10 ? '0' + startingMins : startingMins
    } ${startingAmOrPm}`
    let timeEnd = `${endingHours < 10 ? '0' + endingHours : endingHours}:${
      endingMins < 10 ? '0' + endingMins : endingMins
    } ${endingAmOrPm}`

    await axios
      .patch('/schedules/editSchedule', {
        userId: formData['userId'],
        numberOfHours: numberOfHours,
        timeStart: timeStart,
        timeEnd: timeEnd,
        timeStartTimeStamp: timeStartTimeStamp,
        timeEndTimeStamp: timeEndTimeStamp,
        isPreferred: formData['availability'] == 'preferred' ? true : false,
        isUnavailable: formData['availability'] == 'unavailable' ? true : false,
        isWholeDay: formData['wholeDay'] ? true : false,
        applyTo: formData['applyTo'],
        locations: formData['locations'] ? formData['locations'] : [],
        note: formData['note'],
        roleId: rolesWithNames[formData['roleName']]._id,
        roleName: rolesWithNames[formData['roleName']].name,
        wage: rolesWithWages[formData['roleName']].wage,
        wageUnit: rolesWithWages[formData['roleName']].wageUnit,
        dayId: formData['dayId'],
        shiftId: formData['shiftId'],
        scheduleId: formData['scheduleId'],
        numberOfMinutes: numberOfMinutes,
        prevTimeStart: formData['prevTimeStart'],
        prevIsUnavailable: formData['prevIsUnavailable'],
        sentByAdmin: true,
      })
      .then((res) => {
        setDataSwitch(!dataSwitch)
        handleDiscardEdit()
        if (res.status == 201) toast.success('Schedule updated successfully')
      })
      .catch((e) => {
        setDataSwitch(!dataSwitch)
        toast.error(e.response.data.error ? e.response.data.error : `Couldn't update schedule`)
      })
  }

  const onDelete = async (shift, row) => {
    await axios
      .patch('/schedules/delete', {
        userId: row.userId,
        scheduleId: row.scheduleId,
        dayId: shift.dayId,
        shiftId: shift._id,
        isUnavailable: shift.isUnavailable,
        startTime: shift.timeStartTimeStamp,
      })
      .then((res) => {
        setDataSwitch(!dataSwitch)
        if (res.status == 201) toast.success('Schedule deleted Successfully')
      })
      .catch((e) => toast.error("Couldn't delete schdedule"))
  }

  const onEdit = async (shift, row, index) => {
    let shiftAlreadyCreated = false
    const prevData = formData
    let timeStart = shift.timeStartTimeStamp || new Date()
    let timeEnd = shift.timeEndTimeStamp || new Date()
    prevData['timeEnd'] = dayjs(timeEnd)
    prevData['timeStart'] = dayjs(timeStart)
    prevData['prevTimeStart'] = dayjs(timeStart)
    prevData['prevIsUnavailable'] = shift.isUnavailable
    prevData['wholeDay'] = shift.isWholeDay ? 'wholeDay' : undefined
    prevData['isPreferred'] = shift.isPreferred
    prevData['availability'] = shift.isPreferred ? 'preferred' : 'unavailable'
    prevData['applyTo'] = locationsData.filter(
      (eachLocation) => eachLocation.value.idOfDoc == shift.applyTo.idOfDoc
    )[0]?.value
    prevData['dayId'] = shift.dayId
    prevData['shiftId'] = shift._id
    prevData['scheduleId'] = row.scheduleId
    prevData['userId'] = row.userId
    prevData['roleName'] = row.roles.filter(
      (eachShift) => eachShift.label == shift.roleName
    )[0]?.value
    setRolesToShow(row.roles)
    setRolesWithWages(row.rolesWithWages)
    setRolesWithNames(row.rolesWithNames)
    setFormData(prevData)
    let day = 'Sunday'
    shiftAlreadyCreated = row['sundayShifts'].length > 1
    if (index % 7 == 0) {
      day = 'Saturday'
      shiftAlreadyCreated = row['saturdayShifts'].length > 1
    } else if (index % 6 == 0) {
      day = 'Friday'
      shiftAlreadyCreated = row['fridayShifts'].length > 1
    } else if (index % 5 == 0) {
      day = 'Thursday'
      shiftAlreadyCreated = row['thursdayShifts'].length > 1
    } else if (index % 4 == 0) {
      day = 'Wednesday'
      shiftAlreadyCreated = row['wednesdayShifts'].length > 1
    } else if (index % 3 == 0) {
      day = 'Tuesday'
      shiftAlreadyCreated = row['tuesdayShifts'].length > 1
    } else if (index % 2 == 0) {
      day = 'Monday'
      shiftAlreadyCreated = row['mondayShifts'].length > 1
    }
    setSelectedDay(day)

    const elements = CAREGIVER_ADD_SCHEDULE_FORM_INPUTS({
      day,
      rolesToShow: row.roles,
      locations: locationsData,
      shiftAlreadyCreated
    })
    setUIElements(elements)
    setShowEditScheduleModal(true)
  }

  const buttons = [
    {
      label: 'Cancel',
      variant: 'outlined',
      type: 'button',
      onClick: handleDiscardAdd,
    },
    {
      label: 'Save',
      variant: 'contained',
      type: 'submit',
    },
  ]

  const editButtons = [
    {
      label: 'Cancel',
      variant: 'outlined',
      type: 'button',
      onClick: handleDiscardEdit,
    },
    {
      label: 'Save',
      variant: 'contained',
      type: 'submit',
    },
  ]

  const onClickPublishSchedule = async () => {
    await axios
      .post('/schedules/publishSchedule', {
        startDate: startDate,
        endDate: lastDate,
      })
      .then((res) => {
        setDataSwitch(!dataSwitch)
        handleDiscardAdd()
        if (res.status == 201) toast.success('Schedule published successfully')
      })
      .catch((e) => {
        setDataSwitch(!dataSwitch)
        toast.error(e.response.data.error ? e.response.data.error : `Couldn't publish schedule`)
      })
  }

  const onChangeSelectValue = (selectedWeek) => {
    setNewWeekStartDate(selectedWeek.startDate)
    setNewWeekEndDate(selectedWeek.endDate)
    // let week1startDate = new Date()
    // week1startDate.setDate(lastDate.getDate() + 1)
    // let week2startDate = new Date()
    // week2startDate.setDate(week1startDate.getDate() + 7)
    // let week3startDate = new Date()
    // week3startDate.setDate(week1startDate.getDate() + 14)
    // let week1endDate = new Date()
    // week1endDate.setDate(lastDate.getDate() + 7)
    // let week2endDate = new Date()
    // week2endDate.setDate(lastDate.getDate() + 14)
    // let week3endDate = new Date()
    // week3endDate.setDate(lastDate.getDate() + 21)
    let scheduleExists = nextWeekSchedules[selectedWeek.index]
    if (scheduleExists.length > 0) {
      setErrorMsg(true)
      setHelperText(
        'This week already has schedules. By saving, you will be overriding the existing schedules'
      )
    } else {
      setErrorMsg(false)
      setHelperText('')
    }
  }

  const onClickCopySchedule = async () => {
    setErrorMsg(false)
    setHelperText('')
    let weekStartDate = new Date()
    if (currentWeekStartDate.toLocaleDateString() !== startDate.toLocaleDateString()) {
      weekStartDate.setDate(currentWeekStartDate.getDate() - 7)
    } else {
      weekStartDate.setDate(currentWeekStartDate.getDate())
    }

    await axios
      .get(
        `schedules/getThreeWeekScheduelsToCompany?weekStartDate=${weekStartDate}`
      )
      .then((res) => {
        if (res.status == 200) {
          setNextWeekSchedules(res.data)
        }
      })
      .catch((e) => console.error(e))
  }

  useEffect(() => {
    if (nextWeekSchedules.length == 3) {
      let week1StartDate = new Date(currentWeekStartDate)
      let week1EndDate = new Date(currentWeekStartDate)
      let week2StartDate = new Date(currentWeekStartDate)
      let week2EndDate = new Date(currentWeekStartDate)
      let week3StartDate = new Date(currentWeekStartDate)
      let week3EndDate = new Date(currentWeekStartDate)

      if (currentWeekStartDate.toLocaleDateString() !== startDate.toLocaleDateString()) {
        week1StartDate.setDate(currentWeekStartDate.getDate())
        week1EndDate.setDate(currentWeekStartDate.getDate() + 6)
        week2StartDate.setDate(currentWeekStartDate.getDate() + 7)
        week2EndDate.setDate(currentWeekStartDate.getDate() + 13)
        week3StartDate.setDate(currentWeekStartDate.getDate() + 14)
        week3EndDate.setDate(currentWeekStartDate.getDate() + 20)
      } else {
        week1StartDate.setDate(currentWeekStartDate.getDate() + 7)
        week1EndDate.setDate(currentWeekStartDate.getDate() + 13)
        week2StartDate.setDate(currentWeekStartDate.getDate() + 14)
        week2EndDate.setDate(currentWeekStartDate.getDate() + 20)
        week3StartDate.setDate(currentWeekStartDate.getDate() + 21)
        week3EndDate.setDate(currentWeekStartDate.getDate() + 27)
      }

      let weeks = [
        {
          label: `${
            week1StartDate.toLocaleString('default', {month: 'short'}) +
            ' ' +
            week1StartDate.getDate() +
            ' - ' +
            week1EndDate.toLocaleString('default', {month: 'short'}) +
            ' ' +
            week1EndDate.getDate() +
            ', ' +
            week1EndDate.getFullYear()
          }`,
          value: {
            startDate: week1StartDate,
            endDate: week1EndDate,
            index: 0,
            type: 'selectedWeek',
          },
        },
        {
          label: `${
            week2StartDate.toLocaleString('default', {month: 'short'}) +
            ' ' +
            week2StartDate.getDate() +
            ' - ' +
            week2EndDate.toLocaleString('default', {month: 'short'}) +
            ' ' +
            week2EndDate.getDate() +
            ', ' +
            week2EndDate.getFullYear()
          }`,
          value: {
            startDate: week2StartDate,
            endDate: week2EndDate,
            index: 1,
            type: 'selectedWeek',
          },
        },
        {
          label: `${
            week3StartDate.toLocaleString('default', {month: 'short'}) +
            ' ' +
            week3StartDate.getDate() +
            ' - ' +
            week3EndDate.toLocaleString('default', {month: 'short'}) +
            ' ' +
            week3EndDate.getDate() +
            ', ' +
            week3EndDate.getFullYear()
          }`,
          value: {
            startDate: week3StartDate,
            endDate: week3EndDate,
            index: 2,
            type: 'selectedWeek',
          },
        },
      ]

      weeks.sort((a, b) => {
        let fa = a.value.startDate,
          fb = b.value.startDate

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })

      setWeeksToShow(weeks)
      setShowCopyScheduleModal(true)
    }
  }, [nextWeekSchedules])

  const classes = useStyles()
  return (
    <>
      <CareGiverScheduleTabs></CareGiverScheduleTabs>
      <div className='card'>
        <div className='card-body'>
          <SchedulesDynamicTable
            columns={caregiverSchedulescolumns}
            data={schedulesList}
            columnDataMapping={caregiverSchedulescolumnsDataMapping}
            title={'Are you sure you want to delete this schedule?'}
            startDate={startDate}
            lastDate={lastDate}
            moveDatesForward={moveDatesForward}
            moveDatesBackward={moveDatesBackward}
            onClickCell={onClickCell}
            onDelete={onDelete}
            onEdit={onEdit}
            canAdd={hasPermission(
              userPermissions,
              permissions.care_giver_schedules_schedules_create
            )}
            canDelete={hasPermission(
              userPermissions,
              permissions.care_giver_schedules_schedules_delete
            )}
            canEdit={hasPermission(
              userPermissions,
              permissions.care_giver_schedules_schedules_update
            )}
            invisiblePreferredText={true}
            onClickPublishSchedule={onClickPublishSchedule}
            onClickCopySchedule={onClickCopySchedule}
            showWages={true}
            showFooter={true}
            stats={weeklyStats}
            disableCopyButton={startDate > currentWeekStartDate || onlyDisabledSchedules}
            typeOfSchedules={'allSchedules'}
            changeDataSwitch={changeDataSwitch}
          />
        </div>
      </div>
      <DynamicAddModal
        title={'Add New Schedule'}
        showAddScheduleModal={showAddScheduleModal}
        formInputs={uiElements}
        handleSubmitForm={handleSubmitForm}
        buttons={buttons}
        data={formData}
      ></DynamicAddModal>
      <DynamicAddModal
        title={'Edit Schedule'}
        showAddScheduleModal={showEditScheduleModal}
        formInputs={uiElements}
        handleSubmitForm={handleSubmitEditForm}
        buttons={editButtons}
        data={formData}
      ></DynamicAddModal>

      <Modal
        open={showCopyScheduleModal}
        aria-labelledby='add-modal-title'
        aria-describedby='add-modal-description'
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40vh',
          }}
        >
          <div
            style={{
              height: 180,
              textAlign: 'center',
              borderRadius: '6px',
              minWidth: '40rem',
            }}
          >
            <Card className={classes.card}>
              <CardContent className={classes.content}>
                <Typography
                  variant='h3'
                  sx={{paddingTop: '1rem', paddingBottom: '1rem'}}
                  fontSize={20}
                  fontWeight={'bold'}
                >
                  {'Select Week to Paste'}
                </Typography>

                <Box>
                  <div key={'select'} className={classes.fieldContainer}>
                    <Autocomplete
                      disablePortal
                      id={'select'}
                      size='small'
                      options={weeksToShow}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      sx={{maxWidth: '100%'}}
                      forcePopupIcon={true}
                      renderInput={(params) => (
                        <TextField
                          error={errorMsg ? errorMsg : undefined}
                          helperText={helperText ? helperText : undefined}
                          required={true}
                          {...params}
                          label={'Search Week'}
                        />
                      )}
                      onChange={(event, newValue) => onChangeSelectValue(newValue.value)}
                    />
                  </div>
                </Box>
                <Box className={classes.root}>
                  <Box className={classes.buttonContainer}>
                    {buttons.map((button: any, index: React.Key | null | undefined) =>
                      button.disabled === true ? (
                        <div></div>
                      ) : (
                        <Button
                          key={index}
                          type={button.type}
                          sx={{padding: '4px', margin: '4px'}}
                          variant={button.variant}
                          className={
                            button.type === 'submit' ? classes.submitButton : classes.cancelButton
                          }
                          onClick={
                            button.label === 'Cancel'
                              ? () => setShowCopyScheduleModal(false)
                              : () => handleSubmitCopy()
                          }
                          disabled={button.disabled}
                        >
                          {button.label}
                        </Button>
                      )
                    )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  )
}
