import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { useDispatch } from 'react-redux'
import { addContact } from '../store/action'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import { CONTACTS_FORM_INPUTS, NEW_CONTACT_DATA } from '../../CARE_CONSTANTS'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import * as validator from '../../../../../reusable-components/Validation/Validations';
import { AppDispatch } from '../../../../../../setup/redux/Store'

interface ContactNewProps {
    setShowNewForm?: (value: boolean) => void;
    setShowEditingForm?: (value: boolean) => void;
    clientInfo?: any; // Replace 'any' with the type of the clientInfo prop
}
const ContactNew: React.FC<ContactNewProps> = () => {
    const dispatch = useDispatch<AppDispatch>()
    const history = useHistory()
    const parameters: { id: string } = useParams()
    const handleSubmitForm = async (formData: any) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        // Create the formatted date string
        const currentDateFormatted = `${year}-${month}-${day}`;
        const tempObj: any = {
            'clientID': parameters.id,
            "firstName": formData.firstName,
            "lastName": formData.lastName,
            "relaiontoind": formData.relaiontoind,
            "agency": formData.agency,
            "comments": formData.comments,
            'isGuardian': formData.isGuardian,
            'email': formData.email,
            'addrInfo': {
                "st1": formData?.addrInfo?.st1,
                "st2": formData?.addrInfo?.st2,
                "city": formData?.addrInfo?.city,
                "state": formData?.addrInfo?.state,
                "country": formData?.addrInfo?.country,
                "zipCode": Number(formData?.addrInfo?.zipCode),
                "primaryPhone": Number(formData?.addrInfo?.primaryPhone),
            },
            'mailingInfo': {
                "st1": formData?.mailingInfo?.st1,
                "st2": formData?.mailingInfo?.st2,
                "city": formData?.mailingInfo?.city,
                "state": formData?.mailingInfo?.state,
                "country": formData?.mailingInfo?.country,
                "zipCode": Number(formData?.mailingInfo?.zipCode),
                "primaryPhone": Number(formData?.mailingInfo?.primaryPhone),
            },
        };
        if (formData.guardianEstablishDate && formData.guardianEstablishEndDate) {
            if (formData.guardianEstablishDate <= currentDateFormatted && 
                formData.guardianEstablishEndDate > formData.guardianEstablishDate) {
                tempObj.guardianInfo = {
                    "guardianType": formData.guardianType,
                    "guardianAuthority": formData.guardianAuthority,
                    "guardianEstablishDate": formData.guardianEstablishDate,
                    "guardianEstablishEndDate": formData.guardianEstablishEndDate,
                };
               const response = await dispatch(addContact(tempObj));
               if(response?.payload?.status === 400 && response?.payload?.success === false) {
                toast.error(response?.payload?.message)
            } else if (response?.payload?.status === 200 && response?.payload?.success) {
                toast.success('New Contact has been created')
                history.push(`/admin/care/contact/list/${parameters.id}`)
            } 
            } else {
                if (formData.guardianEstablishDate > currentDateFormatted) {
                    toast.error('Guardian Establish Start Date should not be later than today');
                } else if (formData.guardianEstablishEndDate < formData.guardianEstablishDate) {
                    toast.error('Guardian Establish End Date should not be earlier than Start Date');
                }
            }
        } else {
            const response = await dispatch(addContact(tempObj));
               if(response?.payload?.status === 400 && response?.payload?.success === false) {
                toast.error(response?.payload?.message)
            } else if (response?.payload?.status === 200 && response?.payload?.success) {
                toast.success('New Contact has been created')
                history.push(`/admin/care/contact/list/${parameters.id}`)
            } 
        }
    }
    

    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];

    return (
        <FormWrapper
            title="Add New Contact"
            content={
                <DynamicForm
                    fields={CONTACTS_FORM_INPUTS}
                    data={NEW_CONTACT_DATA}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )
}

export default ContactNew
