import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import {
    TextField, Button, Box,Typography
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import ClearIcon from '@mui/icons-material/Clear';
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import PdfViewer from '../../../../../../_metronic/layout/components/header/chat_system/components/PdfViewer'
import NumberField from '../../../../../reusable-components/forms/NumberField'
import { MoonLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import { setPatient } from '../../individual-intake/store'
const selectAuth = (state) => state.client;

const MainInsuranceForm = () => {
    const [insuranceData, setInsuranceData] = useState({})
    const [individual, setIndividual] = useState({})
    const [clientFileName, setClientFileName] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)
    const [filePreview, setFilePreview] = useState(null);
    const [file, setFile] = useState(null);
    const parameter = useParams()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        medicareNumber: "",
        medicareDate: "",
        medPlanDId: "",
        medPlanDName: "",
        medPlanDIssuer: "",
        medPlanDRxBin: "",
        medPlanDRxPcn: "",
        medPlanDRxGrp: "",
        otherBenefits: "",
        insuranceCompany: "",
        insuranceGroup: "",
        insurancePolicyNumber: "",
        insurancePolicyHolder: "",
        file: ""
    });
    const dispatch = useDispatch()
    const {patient} = useSelector(selectAuth);
    useEffect(() => {
        const fetchClientData = async () => {
            setIsLoading(true);
            try {
                setIndividual(patient);
                if (patient?.insuranceInfo) {
                    setInsuranceData(patient?.insuranceInfo);
                    setClientFileName(`${patient?.firstName} ${patient?.lastName}_Form`);
                }
            } catch (error) {
                console.error('Error fetching client data:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchClientData();
    }, [parameter.id]);

    const fetchPresignedUrl = async (fileKey) => {
        try {
          const response = await axios.get("presigned/fetch-file-url", {
            params: { fileKey },
          });
      
          if (response.status === 200) {
            setFile(response.data.presignedUrl); 
          }
        } catch (error) {
          console.error("Error fetching presigned URL:", error);
          toast.error("Failed to fetch file.");
        }
      };
      
      useEffect(() => {
        if (insuranceData?.file) {
          fetchPresignedUrl(insuranceData.file);
        }
      }, [insuranceData]);
      

    useEffect(() => {
        const tempObj = {
            medicareNumber: insuranceData?.medicareNumber,
            medicareDate: insuranceData?.medicareDate,
            medPlanDId: insuranceData?.medPlanDId,
            medPlanDName: insuranceData?.medPlanDName,
            medPlanDIssuer: insuranceData?.medPlanDIssuer,
            medPlanDRxBin: insuranceData?.medPlanDRxBin,
            medPlanDRxPcn: insuranceData?.medPlanDRxPcn,
            medPlanDRxGrp: insuranceData?.medPlanDRxGrp,
            otherBenefits: insuranceData?.otherBenefits,
            insuranceCompany: insuranceData?.insuranceCompany,
            insuranceGroup: insuranceData?.insuranceGroup,
            insurancePolicyNumber: insuranceData?.insurancePolicyNumber,
            insurancePolicyHolder: insuranceData?.insurancePolicyHolder,
            file: insuranceData?.file
        }
        setFormData(tempObj)
    }, [insuranceData, parameter.id])

    const uploadFileToS3 = async (file, id, providerCode = "Afgg2b") => {
        try {
          const formData = new FormData();
          formData.append("file", file);
      
          const response = await axios.patch(`clients/uploadfile/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: {
                providerCode, 
            },
          });
      
          if (response.status === 200) {
            toast.success("File uploaded successfully!");
            const fileKey = response.data.fileKey;

            setFormData((prevState) => ({
                ...prevState,
                file: fileKey, 
            }));

            return fileKey;
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          toast.error("Error uploading file. Please try again.");
        }
      };
      

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
      
        if (formData.file) {
          toast.error("You cannot upload more than one file.");
          event.target.value = null;
          return;
        }
      
        if (file) {
          const allowedMimeTypes = [
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/msword",
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/webp",
            "image/heic",
            "image/heif",
            "image/bmp",
          ];
      
          if (!allowedMimeTypes.includes(file.type)) {
            toast.error("Invalid file type. Please select a valid file.");
            event.target.value = null;
            return;
          }
      
          try {
            const fileKey = await uploadFileToS3(file, parameter.id,"Afgg2B"); 
            
            setSelectedFile(file);
            setFormData((prevState) => ({
              ...prevState,
              file: fileKey, 
            }));
      
            const reader = new FileReader();
            reader.onload = (e) => setFilePreview(e.target.result);
            reader.readAsDataURL(file);
          } catch (error) {
            console.error("Error handling file change:", error);
          }
        } else {
          setFilePreview(null);
        }
      };
      

    const handleFieldChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const deleteImage = async (fileKey, folderName, clientId, providerCode,type) => {
        try {
          const sanitizedFileKey = fileKey.startsWith(`${folderName}/`)
            ? fileKey.replace(`${folderName}/`, "")
            : fileKey;
      
          const response = await axios.delete(
            `/clients/deleteFileFromS3/${folderName}/${sanitizedFileKey}`,
            {
              params: { clientId, providerCode,type }, 
            }
          );
      
          if (response.status === 200) {
            toast.success("File deleted successfully.");
            setFile(null); 
            setFilePreview(null); 
            setFormData((prevState) => ({
              ...prevState,
              file: "", 
            }));
          }
        } catch (error) {
          console.error("Error deleting file:", error);
          toast.error("Failed to delete file.");
        }
      };
      
      const handleDeleteClick = () => {
        const fileKey = formData.file || selectedFile;
        const folderName = "insurance_documents";
        const clientId = parameter.id; 
        const providerCode = "Afgg2B";
      
        if (fileKey) {
          deleteImage(fileKey, folderName, clientId, providerCode,"insurance");
        } else {
          toast.error("No file found to delete.");
        }
      };

    const handleSubmit = async (formData) => {
        // Have disabled Medicare Expiry date check for now (Abdal)
        // const currentDate = new Date();
        // const year = currentDate.getFullYear();
        // const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        // const day = currentDate.getDate().toString().padStart(2, '0');
        // const currentDateFormatted = new Date(`${month}-${day}-${year}`);
    
        try {
            // if (new Date(formData.medicareDate) <= currentDateFormatted) {
                await axios.put(`/clients/update/insuranceInfo/${parameter.id}`, formData)
                    .then(res => {
                        if (res.status === 200) {
                            toast.success('Insurance Updated successfully');
                            dispatch(setPatient(res?.data?.data))
                            localStorage.setItem('patientData', JSON.stringify(res?.data?.data));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error('Failed to upload document');
                    });
            // } else {
            //     toast.error('Medicare Date should not be later than today');
            // }
        } catch (err) {
            console.log(err);
        }
    };
    

    return (
        <div>
            {/* <div className="mx-6">
                        <SubMenuSection individual={individual} />
                    </div> */}
            { isLoading ? (
                <div className="card">
                    <div className="card-body">
                        <div className="overlay d-flex justify-content-center">
                        <MoonLoader
                            color="#9db2fc"
                            size={80}
                            loading={isLoading}
                        />
                    </div>
                    </div>
                </div>
                    
                ) : (
                    <FormWrapper
                title={`Insurance Data Of: ${individual.firstName + ' ' + individual.lastName}`}
                content={
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(formData);
                    }}>
                        <Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medicareNumber"
                                        size='small'
                                        className='textField'
                                        value={formData?.medicareNumber || ''}
                                        onChange={(e)=> {
                                            const number = e?.target?.value?.replace(/[^0-9]/g, '').slice(0, 50)
                                            handleFieldChange(e.target.name, number)
                                        }}
                                        variant="outlined"
                                        label="Medicare Number"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={dayjs(formData?.medicareDate)}
                                            format="YYYY-MM-DD"
                                            onChange={(newValue) => {
                                                const year = newValue.$y.toString().padStart(2, '0');
                                                const month = (newValue.$M + 1).toString().padStart(2, '0');
                                                const day = newValue.$D.toString().padStart(2, '0');
                                                const newDate = `${year}-${month}-${day}`;
                                                setFormData({ ...formData, medicareDate: newDate });
                                            }}
                                            className='textField'
                                            slotProps={{ textField: { size: 'small', label: 'Insurance Expiry', error: false } }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medPlanDId"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDId || ''}
                                        onChange={(e)=> {
                                            const number = e?.target?.value?.replace(/[^0-9]/g, '').slice(0, 50)
                                            handleFieldChange(e.target.name, number)
                                        }}
                                        variant="outlined"
                                        label="Med Plan D ID"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medPlanDName"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDName || ''}
                                        onChange={(e) => handleFieldChange(e?.target?.name, e?.target?.value)}
                                        variant="outlined"
                                        label="Med Plan D Name"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medPlanDIssuer"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDIssuer || ''}
                                        onChange={(e) => handleFieldChange(e?.target?.name, e?.target?.value)}
                                        variant="outlined"
                                        label="Med Plan D Issuer"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medPlanDRxBin"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDRxBin || ''}
                                        onChange={(e) => handleFieldChange(e?.target?.name, e?.target?.value)}
                                        variant="outlined"
                                        label="Med Plan D RxBIN"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medPlanDRxPcn"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDRxPcn || ''}
                                        onChange={(e) => handleFieldChange(e?.target?.name, e?.target?.value)}
                                        variant="outlined"
                                        label="Med Plan D RxPCN"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="medPlanDRxGrp"
                                        size='small'
                                        className='textField'
                                        value={formData?.medPlanDRxGrp || ''}
                                        onChange={(e) => handleFieldChange(e?.target?.name, e?.target?.value)}
                                        variant="outlined"
                                        label="Med Plan D RxGrp"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                    fullWidth
                                    label='Other Benefits'
                                    name='otherBenefits'
                                    placeholder='Enter Other Benefits'
                                    variant='outlined'
                                    multiline
                                    rows={3}
                                    value={formData?.otherBenefits || ''}
                                    onChange={(e) => handleFieldChange(e?.target?.name, e?.target?.value)}
                                    />
                                    
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="insuranceCompany"
                                        size='small'
                                        className='textField'
                                        value={formData?.insuranceCompany || ''}
                                        onChange={(e) => handleFieldChange(e?.target?.name, e?.target?.value)}
                                        variant="outlined"
                                        label="Insurance Company"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="insuranceGroup"
                                        size='small'
                                        className='textField'
                                        value={formData?.insuranceGroup || ''}
                                        onChange={(e) => handleFieldChange(e?.target?.name, e?.target?.value)}
                                        variant="outlined"
                                        label="Insurance Group"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="insurancePolicyNumber"
                                        size='small'
                                        className='textField'
                                        value={formData?.insurancePolicyNumber || ''}
                                        onChange={(e)=> {
                                            const number = e?.target?.value?.replace(/[^0-9]/g, '').slice(0, 50)
                                            handleFieldChange(e.target.name, number)
                                        }}
                                        variant="outlined"
                                        label="Insurance Policy Number"
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="text"
                                        name="insurancePolicyHolder"
                                        size='small'
                                        className='textField'
                                        value={formData?.insurancePolicyHolder || ''}
                                        onChange={(e) => handleFieldChange(e?.target?.name, e?.target?.value)}
                                        variant="outlined"
                                        label="Insurance Policy Holder"
                                        autoComplete="off"
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                                <Box className='fieldContainer'>
                                    <TextField
                                        type="file"
                                        accept=".pdf, application/pdf, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv ,.doc, application/msword, .jpg, .jpeg, .png, .gif, .webp, .heic,.heif, .bmp"
                                        id="file-input"
                                        sx={{ maxWidth: 965 }}
                                        name='file'
                                        onChange={handleFileChange}
                                    />
                                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                    Allowed file types: PDF,Word (.docx), CSV,.jpg, .jpeg, .png, .gif.
                                  </Typography>
                                    {selectedFile || file ? (
                                        <div style={{
                                            position: 'relative',
                                            display: 'flex',
                                            margin: '10px',
                                        }}>
                                            {file && (
                                                <div style={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                }}>
                                                    <PdfViewer name={clientFileName} filePath={file}  mimeType={selectedFile?.type}/>
                                                    <ClearIcon
                                                        style={{
                                                            position: 'absolute',
                                                            top: '-3px',
                                                            right: '-2px',
                                                            color: 'black',
                                                            border: 'none',
                                                            borderRadius: '2px',
                                                            cursor: 'pointer',
                                                            padding: '2px',
                                                        }}
                                                        onClick={() => {
                                                            handleDeleteClick();
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            {selectedFile && (
                                                <div style={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    margin: '10px',
                                                }}>
                                                    <PdfViewer name={clientFileName} filePath={URL.createObjectURL(selectedFile)}  mimeType={selectedFile?.type}/>
                                                    <ClearIcon
                                                        style={{
                                                            position: 'absolute',
                                                            top: '-3px',
                                                            right: '-2px',
                                                            color: 'black',
                                                            border: 'none',
                                                            borderRadius: '2px',
                                                            cursor: 'pointer',
                                                            padding: '2px',
                                                        }}
                                                        onClick={() => {
                                                            handleDeleteClick();
                                                            setFilePreview(null);
                                                            setSelectedFile(null);
                                                            setFormData({
                                                                ...formData,
                                                                file: ''
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ) : null}


                                </Box>
                            </Box>
                            <Box style={{ marginTop: '24px', }}>
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                    borderBottomLeftRadius: '4px',
                                    borderBottomRightRadius: '4px',
                                    padding: '10px 0',
                                }}>

                                    <Button
                                        type='button'
                                        sx={{ padding: '4px', margin: '4px' }}
                                        variant='contained'
                                        style={{
                                            color: '#000',
                                            backgroundColor: '#fff',
                                            '&:hover': {
                                                backgroundColor: '#f2f2f2',
                                            }
                                        }}
                                        onClick={() => history.push('/admin/care/insurance/list')}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type='submit'
                                        sx={{ padding: '4px', margin: '4px' }}
                                        variant='contained'
                                        style={{
                                            marginLeft: '6px',
                                            backgroundColor: '#28a745',
                                            color: '#fff',
                                            '&:hover': {
                                                backgroundColor: '#218838',
                                            },
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>

                    </form>
                }
            />
                )}
                

        </div>
    )
}

export default MainInsuranceForm