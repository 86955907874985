import { CaregiverAddScheduleFormInputsProps } from "./CAREGIVER_TYPES"

export const CAREGIVER_ADD_SCHEDULE_FORM_INPUTS = ({ day, rolesToShow, locations, shiftAlreadyCreated }: CaregiverAddScheduleFormInputsProps) => {
  return [
    {
      sectionHeading: day,
      rowNumber: 1,
    },
    {
      label: 'Start',
      name: 'timeStart',
      type: 'timezone-time',
      required: true,
      rowNumber: 2,
      minutesStep: 5,
    },
    {
      label: 'End',
      name: 'timeEnd',
      type: 'timezone-time',
      required: true,
      rowNumber: 2,
      minutesStep: 5,
    },
    {
      label: 'Apply To',
      name: 'applyTo',
      type: 'select',
      options: locations,
      // options: [{label: 'All Locations', value: 'allLocations'}],
      required: true,
      rowNumber: 3,
    },
    // {
    //   name: 'wholeDay',
    //   type: 'checkboxBoolean',
    //   rowNumber: 4,
    //   required: true,
    //   options: [
    //     {
    //       label: 'Whole Day',
    //       value: 'wholeDay',
    //     },
    //   ],
    //   disabled: shiftAlreadyCreated
    // },
    {
      name: 'availability',
      type: 'radio',
      rowNumber: 4,
      required: true,
      options: [
        {
          label: 'Preferred',
          value: 'preferred',
        },
        {
          label: 'Unavailable',
          value: 'unavailable',
        },
      ],
    },
    {
      sectionHeading: 'Role',
      rowNumber: 5,
    },
    {
      label: 'Search Role',
      name: 'roleName',
      type: 'select',
      options: rolesToShow,
      required: true,
      rowNumber: 6,
    },
  ]
}
