import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
interface ClientLog {
    _id: String,
    type: String,
    summary: String,
    description: String,
    reportedOn: String,
    intensityLevel: String,
    clientID: String,
}
export const getIncidentReport = createAsyncThunk(
    '/incident-report/get',
    async ({ page, pageSize, sortBy, sortDir, searchTerm, clientID }: { page: number, pageSize: number, sortBy: string, sortDir: string, searchTerm: string, clientID: string }) => {
        try {
            const response = await axios.get(`/incident-report/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}&clientID=${clientID}`)
            return {
                data: response.data
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getSearchIncidentReport = createAsyncThunk(
    'incident-report/search',
    async (searchTerm) => {
        try {
            const response = await axios.get(`incident-report/search?searchTerm=${searchTerm}`)
            return {
                data: response.data  
            }
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getOneIncidentReport = createAsyncThunk(
    '/incident-report/getOne/',
    async (id: string | undefined) => {
        try {
            const response = await axios.get('/incident-report/getOne/' + id)
            return {
                data: response.data
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)
export const addIncidentReport = createAsyncThunk(
    'incident-report/create',
    async (data: ClientLog) => {
        try {
            const response = await axios.post('incident-report/create', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }

)
export const deleteIncidentReport = createAsyncThunk(
    'incident-report/delete/',
    async (data: string) => {
        try {
            const response = await axios.delete('incident-report/delete/' + data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)
export const updateIncidentReport = createAsyncThunk(
    'incident-report/update',
    async (data: ClientLog) => {
        try {
            const response = await axios.put('incident-report/update', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)
