import { Fragment, useEffect, useState } from 'react'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { CLIENT_LOG_FORM_INPUTS } from '../../CARE_CONSTANTS'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { getOneIncidentReport, updateIncidentReport } from '../store/action'
import { useHistory, useParams } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'
import DescriptionModal from '../../../../../reusable-components/description-modal/descriptionmodal'
import NavigateButton from '../../../../../reusable-components/description-modal/navigatebutton'
const ListOfClientLogsEdit = () => {
  const [clientLogData, setClientlogData] = useState({})
  const dispatch = useDispatch()
  const parameters = useParams()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setDescription] = useState('')
  const [pendingSubmit, setPendingSubmit] = useState(false);
  const [clientId, setClientId] = useState();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        if (parameters.id) {
          const resultTlog = await dispatch(getOneIncidentReport(parameters.id));
          const tempTlog = resultTlog.payload?.data?.data;
          setClientlogData(tempTlog);
          setClientId(tempTlog?.clientID);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, parameters.id]);

  const handleSubmitForm = async (formData) => {
    if (!description.trim() && !isModalOpen) {
      setIsModalOpen(true);
      setPendingSubmit(true);
      return;
    }
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getDate().toString().padStart(2, '0')
    // Create the formatted date string
    const currentDateFormatted = `${month}-${day}-${year}`
    const tempObj = {
      _id: formData._id,
      type: formData.type,
      summary: formData.summary,
      description: formData.description,
      reportedOn: formData.reportedOn,
      intensityLevel: formData.intensityLevel,
      clientID: formData.clientID,
    }
    try {
      if (new Date(formData.reportedOn) <= new Date(currentDateFormatted)) {
        const response = await dispatch(updateIncidentReport(tempObj))
        if (response.payload.status === 400 && response.payload.success === false) {
          toast.error(response.payload.message)
        } else if (response?.payload.success) {
          toast.success(response?.payload.message || 'Incident Report Updated Successfully')
          history.push(`/admin/care/incident-report/list/${clientId}`)
        } else {
          toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error')
        }
      } else {
        toast.error('Reported Date should not be later than today')
      }
    } catch (err) {
      console.log(err)
    }
  }
  const handleSaveDescription = (desc) => {
    setDescription(desc);
    setIsModalOpen(false);
    setPendingSubmit(false);
    handleSubmitForm(clientLogData); 
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPendingSubmit(false);
  };

  const buttons = [
    { label: 'Cancel', variant: 'outlined', type: 'button' },
    { label: 'Save', variant: 'contained', type: 'submit' },
  ];

  return (
    <div className="card">
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-30px' }}>
        <NavigateButton label="View Edit History" clientId={clientId} section="Incident Reports" />
      </div>
      {isLoading ? (
        <div className="card">
          <div className="card-body">
            <div className="overlay d-flex justify-content-center">
              <MoonLoader color="#9db2fc" size={80} loading={isLoading} />
            </div>
          </div>
        </div>
      ) : (
        <FormWrapper
          title="Edit Incident Report"
          content={
            <>
              <DynamicForm
                fields={CLIENT_LOG_FORM_INPUTS}
                data={clientLogData}
                onSubmit={handleSubmitForm}
                buttons={buttons}
              />
              <DescriptionModal
                open={isModalOpen}
                onClose={handleCloseModal}
                clientID={clientId}
                onSave={handleSaveDescription}
                section="Incident Reports"
              />
            </>
          }
        />
      )}
    </div>
  )
}

export default ListOfClientLogsEdit;
