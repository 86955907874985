import { useEffect, useState } from 'react'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import { EDIT_CAREGIVER_FORM_INPUTS } from '../../CAREGIVER_FORM_CONSTANTS'
import { Button, Box, Typography } from '@mui/material'
import { hasPermission } from '../../../../../_helper/hasPermission'
import { permissions } from '../../../../../_constants/permissions'
import { MoonLoader } from 'react-spinners'

export default function EditCareGiver() {
    const [formData, setFormData] = useState({})
    const [rolesToShow, setRolesToShow] = useState([])
    const [userRoles, setUserRoles] = useState([])
    const [uiElementsList, setUiElementsList] = useState([])
    const [isFormLoading, setIsFormLoading] = useState(true)
    const [updateRolesFields, setUpdateRolesFields] = useState(false)
    const [error, setError] = useState([false])
    const [i, setI] = useState(0)
    const [clickCount, setClickCount] = useState(5)
    const [addClick, setAddClick] = useState(0)
    const [inputArr, setInputArr] = useState([
        {
            roleId: '',
            wage: '',
            wageUnit: '',
            effectiveDate: null,
        },
    ])
    const history = useHistory()
    const authUser = useSelector((state: { auth: any }) => state.auth)
    const userPermissions = authUser.user.roleId;
    const params : { id: string }= useParams()

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsFormLoading(true); 
                
                
                const rolesResponse = await axios.get("roleManagement/get");
                const tempRoles = rolesResponse.data.map((obj) => ({
                    label: obj.name,
                    value: obj._id,
                }));
                setRolesToShow(tempRoles);

                
                const caregiverResponse = await axios.get(`caregivers/getById/${params.id}`);
                const careGiver = caregiverResponse.data.careGiver;
                const tempArrForInputArr = [];
                const prevFormData = { ...formData };

                setI(careGiver.role.length - 1);
                setUserRoles(careGiver.role);

                careGiver.roles.forEach((eachRole, index) => {
                    const wageKeyName = `${index}Wage`;
                    const wageKeyUnitName = `${index}wageUnit`;
                    const roleKeyName = `${index}Role`;

                    prevFormData[wageKeyName] = eachRole.wage;
                    prevFormData[wageKeyUnitName] = eachRole.wageUnit;
                    prevFormData[roleKeyName] = eachRole.roleId;

                    tempArrForInputArr.push({
                        roleId: careGiver.role[index]["_id"],
                        wage: eachRole.wage,
                        wageUnit: eachRole.wageUnit,
                        effectiveDate: eachRole?.effectiveDate,
                    });
                });

                prevFormData["firstName"] = careGiver.firstName;
                prevFormData["lastName"] = careGiver.lastName;
                prevFormData["phone"] = careGiver.phone;
                prevFormData["email"] = careGiver.email;
                prevFormData["accessLevel"] = careGiver.access?.accessLevel;
                prevFormData["locationSettings"] = careGiver.locationSettings
                    ? Object.keys(careGiver.locationSettings)
                    : undefined;

                setFormData(prevFormData);
                setInputArr(tempArrForInputArr);
                setUpdateRolesFields((prevState) => !prevState);

            } catch (err) {
                console.error(err);
            } finally {
                setIsFormLoading(false);
            }
        };

        fetchData();
    }, [params.id]);

    useEffect(() => {
        const tempElementsList = EDIT_CAREGIVER_FORM_INPUTS(rolesToShow)
        setUiElementsList(tempElementsList)
        setUpdateRolesFields((prevstate) => {
            return !prevstate
        })
    }, [rolesToShow])

    useEffect(() => {
        let tempClickCount = clickCount
        let tempUIArray = uiElementsList
        userRoles.map((eachRole, index) => {
            tempClickCount = tempClickCount + 1
            const roleFieldToAdd = {
                index: index,
                label: 'Search Role',
                name: `${index}Role`,
                type: 'select',
                options: rolesToShow,
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleName,
                error: error[index],
                helperText: error[index] ? 'Please select a different role' : '',
            }
            const wageFieldToAdd = {
                index: index,
                label: 'Wage',
                name: `${index}Wage`,
                type: 'number',
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleWage,
            }
            const wageUnitFieldToAdd = {
                index: index,
                label: `Unit`,
                name: `${index}wageUnit`,
                type: 'select',
                options: [
                    {
                        label: '/hour',
                        value: 'hour',
                    },
                   
                ],
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleWageUnit,
            }
            const deleteButton = {
                index: index,
                label: 'Delete',
                variant: 'outlined',
                type: 'button',
                onClick: () => onRemoveRoleButtonClick(index),
                rowNumber: tempClickCount,
            }
            if (index == 0) {
                tempUIArray.push(roleFieldToAdd)
                tempUIArray.push(wageFieldToAdd)
                tempUIArray.push(wageUnitFieldToAdd)
            } else {
                tempUIArray.push(roleFieldToAdd)
                tempUIArray.push(wageFieldToAdd)
                tempUIArray.push(wageUnitFieldToAdd)
                tempUIArray.push(deleteButton)
            }
        })
        setClickCount(tempClickCount)
        setUiElementsList(tempUIArray)
    }, [updateRolesFields])

    useEffect(() => {
        if (addClick != 0) {
            let tempI = i + 1
            let tempClickCount = clickCount + 1
            const roleFieldToAdd = {
                index: tempI,
                label: 'Search Role',
                name: `role${tempClickCount - 5}`,
                value: inputArr[tempI]['name'],
                type: 'select',
                options: rolesToShow,
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleName,
            }
            const wageFieldToAdd = {
                index: tempI,
                label: 'Wage',
                name: `${tempI}Wage`,
                value: inputArr[tempI]['wage'],
                type: 'number',
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleWage,
            }
            const wageUnitFieldToAdd = {
                index: tempI,
                label: `Unit`,
                value: inputArr[tempI]['wageUnit'],
                name: `${tempI}wageUnit`,
                type: 'select',
                options: [
                    {
                        label: '/hour',
                        value: 'hour',
                    },
                ],
                required: true,
                rowNumber: tempClickCount,
                onChange: onChangeRoleWageUnit,
            }
            const deleteButton = {
                index: tempI,
                label: 'Delete',
                variant: 'outlined',
                type: 'button',
                onClick:() => onRemoveRoleButtonClick(tempI),
                rowNumber: tempClickCount,
            }
            setUiElementsList((prevElements) => [
                ...prevElements,
                roleFieldToAdd,
                wageFieldToAdd,
                wageUnitFieldToAdd,
                deleteButton,
            ])
            setI(tempI)
            setClickCount(tempClickCount)
        }
    }, [addClick])

    const onChangeRoleName = (value, index) => {
        let prevState = inputArr
        prevState[index]['roleId'] = value
        setInputArr(prevState)
    }

    const onChangeRoleWage = (name, value, checked, type, index) => {
        let prevState = inputArr
        prevState[index]['wage'] = value
        setInputArr(prevState)
    }

    const onChangeRoleWageUnit = (value, index) => {
        let prevState = inputArr
        prevState[index]['wageUnit'] = value
        setInputArr(prevState)
    }

    const onRemoveRoleButtonClick = (index) => {
        setInputArr(prevInputArr => {
            const updatedArr = [...prevInputArr];
            updatedArr[index].roleId = '';
            return updatedArr;
        });
        setUiElementsList(prevState => {
            return prevState.filter(item => !item.index || (item.index && item.index !== index));
        });
    };

    const handleSubmitForm = async (formData) => {
        let { firstName, lastName, phone, accessLevel, locationSettings } = formData
        let showInSchedule, eligibleForOpenShifts

        if (locationSettings?.includes('showInSchedule')) showInSchedule = true
        if (locationSettings?.includes('eligibleForOpenShifts')) eligibleForOpenShifts = true
        inputArr.map((eachElem) => {
            if (eachElem.effectiveDate == null) delete eachElem.effectiveDate
        })

        if(phone.toString().length < 10) return

        axios
            .patch('caregivers/update', {
                id: params.id,
                firstName,
                lastName,
                phone,
                access: {
                    accessLevel,
                },
                roles: inputArr.filter((item) => item.roleId !== ''),
                locationSettings: {
                    showInSchedule,
                    eligibleForOpenShifts,
                },
            })
            .then((res) => {
                if (res.status == 201) {
                    toast.success('Caregiver updated successfully')
                }
            })
            .catch((e) => toast.error("Couldn't update caregiver"))
    }

    const onAddAnotherRoleClick = () => {
        let prevInputArr = inputArr
        prevInputArr.push({ roleId: '', wage: '', wageUnit: '', effectiveDate: null })
        setInputArr(prevInputArr)
        setAddClick(addClick + 1)
    }

    const buttons = [
        {
            label: 'Add Another Role',
            variant: 'outlined',
            type: 'button',
            onClick: onAddAnotherRoleClick,
        },
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ]

    return (
        <>
            <div className='card'>
                <div className='card-body'>
                {isFormLoading ? (
            <div className='overlay d-flex align-middle justify-content-center align-middle' style={{marginTop:"100px"}}>
              <MoonLoader color='#9db2fc' size={60} loading={isFormLoading} />
            </div>
          ) : (
            <>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '1.5rem',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                        }}
                    >
                        <Typography fontSize={20} fontWeight={'bold'} fontFamily='helvetica'>
                            Edit Caregivers
                        </Typography>
                       {
                        hasPermission(userPermissions, permissions.care_giver_profile_jobDetails_view) || 
                        hasPermission(userPermissions, permissions.care_giver_profile_personalInformation_view) || 
                        hasPermission(userPermissions, permissions.care_giver_profile_documents_view) ? 
                        <Button
                        variant='contained'
                        color='primary'
                        type='button'
                        style={{marginRight:'0.7rem'}}
                        onClick={() => {
                            if(hasPermission(userPermissions, permissions.care_giver_profile_jobDetails_view)){
                                history.push(`/caregivers/profile/jobDetails/${params.id}`)
                            } else if(hasPermission(userPermissions, permissions.care_giver_profile_personalInformation_view)){
                                history.push(`/caregivers/profile/personalInformation/${params.id}`)
                            } else if(hasPermission(userPermissions, permissions.care_giver_profile_documents_view)){
                                history.push(`/caregivers/profile/documents/${params.id}`)
                            }
                        }}

                    >
                        Caregiver Details
                    </Button> : null
                       }
                       
                    </Box>
                    
                    <DynamicForm
                        fields={uiElementsList}
                        data={formData}
                        onSubmit={handleSubmitForm}
                        buttons={buttons}
                    />
                    </>
          )}
                </div>
            </div>
        </>
    )
}
