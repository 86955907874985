import {useEffect, useState, ChangeEvent} from 'react'
import axios from 'axios'
import {useHistory, useParams} from 'react-router-dom'
import {CurrentDate} from '../../../../../../_helper/currentDate'
import ClientDetailsForm from './ClientDetailsForm'
import {
  TextField,
  IconButton,
  Button,
  Autocomplete,
  Box,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from '@mui/material'
import {Close} from '@mui/icons-material'
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {State, City} from 'country-state-city'
import {toast} from 'react-toastify'
import {title, gender, race, Country} from '../CONSTANTS'
import * as validator from '../../../../../reusable-components/Validation/Validations'
import {RootState} from '../../../../../../setup'
import {ClientData, FormDataType} from '../IndividualsTypes'
import {MoonLoader} from 'react-spinners'
import { setPatient } from '../store'
import { useDispatch } from 'react-redux'
const selectAuth = (state: RootState) => state.auth

const EditIndividualForm = () => {
  const history = useHistory()
  const [showResAddr, setShowResAddr] = useState<boolean>(false)
  const [showMailingAddr, setShowMailingAddr] = useState<boolean>(false)
  const [showEditingForm, setShowEditingForm] = useState<boolean>(true)
  const [showDetailsForm, setShowDetailsForm] = useState<boolean>(false)
  const [images, setImages] = useState<any[]>([])
  const [imageURLs, setImageURLs] = useState<any[]>([])
  const [showImage, setShowImage] = useState<boolean>(false)
  const [showImageUploaded, setShowImageUploaded] = useState<boolean>(false)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [selectedMailingState, setSelectedMailingState] = useState(null)
  const [selectedMailingCountry, setSelectedMailingCountry] = useState(null)
  const [states, setStates] = useState([])
  const [city, setCity] = useState([])
  const [mailingCity, setMailingCity] = useState([])
  const [mailingStates, setMailingStates] = useState([])
  const [imageSrc, setImageSrc] = useState('')
  const [individualData, setIndividualData] = useState<ClientData>()
  const [loadingPDF, setLoadingPDF] = useState(false)
  const [editIndividualData, setEditIndividualData] = useState({})
  const parameters: {id: string} = useParams()
  const dispatch = useDispatch()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [formData, setFormData] = useState<FormDataType>({
    title: {
      label: '',
      value: '',
    },
    image: '',
    gender: {
      label: '',
      value: '',
    },
    firstName: '',
    relationtoind: '',
    lastName: '',
    birthDate: '',
    socialSecurityNumber: '',
    medicaidNumber: '',
    email: '',
    phoneNumber: '',
    race: {
      label: '',
      value: '',
    },
    residentialAddr: false,
    mailingAddr: false,
    addrInfo: {
      primaryPhone: '',
      secondaryPhone: '',
      additionalPhone: '',
      country: '',
      st1: '',
      st2: '',
      city: '',
      state: '',
      zipCode: '',
      lat: '',
      lng: ''
    },
    mailingInfo: {
      primaryPhone: '',
      secondaryPhone: '',
      additionalPhone: '',
      country: '',
      st1: '',
      st2: '',
      city: '',
      state: '',
      zipCode: '',
      lat: '',
      lng: ''
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        if (parameters.id) {
          const res = await axios.get('/clients/getOne/' + parameters.id)
          const tempData = res.data.client
          if (tempData !== undefined) {
            setIndividualData(tempData)
            dispatch(setPatient(tempData));
            const gender = tempData.gender
            ? tempData.gender.charAt(0).toUpperCase() + tempData.gender.slice(1)
            : ''        
            const tempObj : any= {
              _id: tempData._id,
              title: tempData.title,
              gender: {label: gender, value: gender},
              firstName: tempData.firstName,
              lastName: tempData.lastName,
              birthDate: tempData.birthDate,
              socialSecurityNumber: tempData.socialSecurityNumber,
              medicaidNumber: tempData.medicaidNumber,
              status: tempData.status,
              email: tempData.email,
              phoneNumber: tempData.phoneNumber,
              race: tempData.race,
              image: tempData.image,
              enteredDate: tempData.enteredDate,
              enteredBy: tempData.enteredBy,
              admittedBy: tempData.admittedBy,
              lastUpdatedBy: tempData.lastUpdatedBy,
              admissionDate: tempData.admissionDate,
              addrInCareOf: tempData.addrInCareOf,
              addrInfo: {
                st1: tempData.addrInfo.st1,
                st2: tempData.addrInfo.st2,
                city: tempData.addrInfo.city,
                state: tempData.addrInfo.state,
                zipCode: tempData.addrInfo.zipCode,
                country: tempData.addrInfo.country,
                primaryPhone: tempData.addrInfo.primaryPhone,
                secondaryPhone: tempData.addrInfo.secondaryPhone,
                additionalPhone: tempData.addrInfo.additionalPhone,
                lat: tempData.addrInfo?.lat,
                lng: tempData.addrInfo?.lng
              },
              mailingInfo: {
                st1: tempData.mailingInfo.st1,
                st2: tempData.mailingInfo.st2,
                primaryPhone: tempData.mailingInfo.primaryPhone,
                secondaryPhone: tempData.mailingInfo.secondaryPhone,
                additionalPhone: tempData.mailingInfo.additionalPhone,
                zipCode: tempData.mailingInfo.zipCode,
                state: tempData.mailingInfo.state,
                city: tempData.mailingInfo.city,
                country: tempData.mailingInfo.country,
                lat: tempData.addrInfo?.lat,
                lng: tempData.addrInfo?.lng
              },
            }
            
            setSelectedCountry({label:tempObj?.addrInfo?.country, value:tempObj?.addrInfo?.country})
            setSelectedMailingCountry({label:tempObj?.mailingInfo?.country, value:tempObj?.mailingInfo?.country})
            setSelectedState({label: tempObj?.addrInfo?.state, value: tempObj?.addrInfo?.state})
            setSelectedMailingState({
              label: tempObj?.mailingInfo?.state,
              value: tempObj?.mailingInfo?.state,
            })
            setEditIndividualData(tempObj)
            setFormData(tempObj)
          } else {
            history.push('/admin/care')
          }

          if (
            tempData.addrInfo.st1 ||
            tempData.addrInfo.st2 ||
            tempData.addrInfo.city ||
            tempData.addrInfo.state ||
            tempData.addrInfo.zipCode ||
            tempData.addrInfo.country ||
            tempData.addrInfo.primaryPhone ||
            tempData.addrInfo.additionalPhone
          ) {
            setShowResAddr(true)
          }

          if (
            tempData.mailingInfo.st1 === tempData.addrInfo.st1 &&
            tempData.mailingInfo.st2 === tempData.addrInfo.st2 &&
            tempData.mailingInfo.city === tempData.addrInfo.city &&
            tempData.mailingInfo.country === tempData.addrInfo.country &&
            tempData.mailingInfo.zipCode === tempData.addrInfo.zipCode &&
            tempData.mailingInfo.state === tempData.addrInfo.state &&
            tempData.mailingInfo.primaryPhone === tempData.addrInfo.primaryPhone &&
            tempData.mailingInfo.secondaryPhone === tempData.addrInfo.secondaryPhone &&
            tempData.mailingInfo.additionalPhone === tempData.addrInfo.additionalPhone
          ) {
            setShowMailingAddr(false)
          } else {
            setShowMailingAddr(true)
          }
        }
      } catch (error) {
        console.error('Error fetching client data:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [parameters.id])

  useEffect(() => {
    const fetchImage = async () => {
      let imageloc = individualData?.image
      if (imageloc) {
        try {
          const response = await axios.post('/clients/getImage', {imageloc}, {responseType: 'blob'})
          const imageBlob = response.data
          setImageSrc(URL.createObjectURL(imageBlob))
          setShowImage(true)
        } catch (error) {
          console.error('Error fetching image:', error)
          setShowImage(false)
        }
      } else {
        setImageSrc('')
        setShowImage(false)
      }
    }

    fetchImage()
  }, [individualData, showEditingForm])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryCodes = {
          "United States": "US",
          "Pakistan": "PK"
        };
        const country = countryCodes[selectedCountry?.label] || '';
        
        const stateOptions = country ? State.getStatesOfCountry(country).map((state) => ({
          label: state.name,
          value: state.isoCode,
        })) : [];
  
        const state = stateOptions.find((option) => option.label === selectedState?.label);
        const cityOptions = selectedState && state
          ? City.getCitiesOfState(country, state.value).map((city) => ({
              label: city.name,
              value: city.name,
            }))
          : [];
  
        setStates(stateOptions);
        setCity(cityOptions);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [selectedCountry, selectedState]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryCodes = {
          "United States": "US",
          "Pakistan": "PK"
        };
        const country = countryCodes[selectedMailingCountry?.label] || '';
  
        const mailingStateOptions = country ? State.getStatesOfCountry(country).map((state) => ({
          label: state.name,
          value: state.isoCode,
        })) : [];
  
        const state = mailingStateOptions.find((option) => option.label === selectedMailingState?.label);
        const mailingCityOptions = selectedMailingState && state
          ? City.getCitiesOfState(country, state.value).map((city) => ({
              label: city.name,
              value: city.name,
            }))
          : [];
  
        setMailingStates(mailingStateOptions);
        setMailingCity(mailingCityOptions);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [selectedMailingCountry, selectedMailingState]);
  
  const deleteImage = async (fileKey, folderName, clientId, providerCode,type) => {
    try {
      const sanitizedFileKey = fileKey.startsWith(`${folderName}/`)
        ? fileKey.replace(`${folderName}/`, "")
        : fileKey;
  
      const response = await axios.delete(
        `/clients/deleteFileFromS3/${folderName}/${sanitizedFileKey}`,
        {
          params: { clientId, providerCode,type }, 
        }
      );
  
      if (response.status === 200) {
        toast.success("File deleted successfully.");
        setImageSrc(""); 
        setShowImage(false); 
        setFormData((prevState) => ({
          ...prevState,
          image: "",
        }));
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      toast.error("Failed to delete file.");
    }
  };
  
  const handleDeleteClick = () => {
    const fileKey = formData.image; 
    const folderName = "uploads"; 
    const clientId = parameters.id; 
    const providerCode = "Afgg2B";
  
    if (fileKey) {

      deleteImage(fileKey, folderName, clientId, providerCode,"profile");
    } else {
      toast.error("No file found to delete.");
    }
  };
  
  useEffect(() => {
    if (!showEditingForm) {
      setShowImage(false)
    }
  }, [showEditingForm])

  useEffect(() => {
    if (showImage) {
      setShowImageUploaded(false)
    }
  }, [showImage])

  useEffect(() => {
    if (images.length < 1) return
    const newImageUrls = images.map((image) => URL.createObjectURL(image))
    setImageURLs(newImageUrls)
  }, [images])
  useEffect(() => {
  const fetchImage = async () => {
    if (formData.image) {
      try {
        const response = await axios.get("/batchfile/fetch-file-url", {
          params: { fileKey: formData.image },
        });
        if (response.status === 200) {
          setImageSrc(response.data.presignedUrl);
          setShowImage(true);
        }
      } catch (error) {
        console.error("Error fetching image:", error);
        setShowImage(false);
      }
    }
  };
  fetchImage();
}, [formData.image]);
const handleImageUpload = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "clientprofile");

    const uploadResponse = await axios.post("/batchfile/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (uploadResponse.status === 200) {
      const fileKey = uploadResponse.data.fileUrl;

      const presignedResponse = await axios.get("/batchfile/fetch-file-url", {
        params: { fileKey },
      });

      if (presignedResponse.status === 200) {
        const presignedUrl = presignedResponse.data.presignedUrl;

        setFormData((prevData) => ({
          ...prevData,
          image: fileKey, 
        }));
        setImageSrc(presignedUrl); 
        setShowImage(true);
      }
    }
  } catch (error) {
    console.error("Error uploading image:", error);
  }
};

 const onImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
  if (e.target.files) {
    const files = Array.from(e.target.files);

    const previewUrl = URL.createObjectURL(files[0]);
    setImageSrc(previewUrl);
    setShowImage(true);

    await handleImageUpload(files[0]);
  }
};
  const generatePDF = () => {
    setLoadingPDF(true)
    const parameters = {
      fileName: 'Client-Form-' + CurrentDate(),
      clientData: individualData,
    }

    axios
      .post('/pdf/clientForm', parameters, {responseType: 'arraybuffer'})
      .then((res) => {
        const blob = new Blob([res.data], {type: 'application/pdf'})
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Client-Form-' + CurrentDate() + '.pdf')
        document.body.appendChild(link)
        link.click()
        setLoadingPDF(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingPDF(false)
      })
  }

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((value, key) => value && value[key], obj)
  }

  const handleSubmit = async (values) => {
    setIsLoading(true)
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getDate().toString().padStart(2, '0')

    // Create the formatted date string
    const currentDateFormatted = `${month}-${day}-${year}`
    values.race = values?.race?.value
    values.title = values?.title?.value
    values.gender = values?.gender?.value
    if (showMailingAddr === false && showResAddr === true) {
        const { addrInfo } = values;
        values.mailingInfo = {
            st1: addrInfo.st1,
            st2: addrInfo.st2,
            primaryPhone: addrInfo.primaryPhone,
            secondaryPhone: addrInfo.secondaryPhone,
            additionalPhone: addrInfo.additionalPhone,
            zipCode: addrInfo.zipCode,
            state: addrInfo.state,
            city: addrInfo.city,
            country: addrInfo.country,
            lat: formData?.addrInfo?.lat,
            lng: formData?.addrInfo?.lng
        }
        values.residentialAddr = true;
        values.mailingAddr = true;
    } else if (showMailingAddr === false && showResAddr === false) {
        const fields = [
            'st1', 'st2', 'city', 'state', 'zipCode', 'country',
            'primaryPhone', 'secondaryPhone', 'additionalPhone',
        ];
        const mailingFields = [
            'st1',
            'st2', 'primaryPhone', 'additionalPhone',
            'zipCode',
            'state',
            'city',
            'country',
        ]
        fields.forEach(field => {
            values.addrInfo[field] = '';
        });
        mailingFields.forEach(field => {
            values.mailingInfo[field] = '';
        });
        values.residentialAddr = false;
        values.mailingAddr = false;
    }
    const validations = [
      {field: 'firstName', validation: validator.NAME_VALIDATION},
      {field: 'lastName', validation: validator.NAME_VALIDATION},
      {field: 'socialSecurityNumber', validation: validator.SSN_VALIDATION},
      {field: 'email', validation: validator.EMAIL_VALIDATION},
      {field: 'phoneNumber', validation: validator.PHONE_NUMBER_VALIDATION},
      { field: 'addrInfo.st1', validation: validator.STREET_VALIDATION },
      { field: 'addrInfo.st2', validation: validator.STREET_VALIDATION },
      { field: 'addrInfo.zipCode', validation: validator.ZIP_CODE_VALIDATION },
      { field: 'addrInfo.primaryPhone', validation: validator.PHONE_NUMBER_VALIDATION },
      { field: 'mailingInfo.st1', validation: validator.STREET_VALIDATION },
      { field: 'mailingInfo.st2', validation: validator.STREET_VALIDATION },
      { field: 'mailingInfo.zipCode', validation: validator.ZIP_CODE_VALIDATION },
      { field: 'mailingInfo.primaryPhone', validation: validator.PHONE_NUMBER_VALIDATION },
      {field: 'addrInfo.accuracy'},
    ]
    for (const {field, validation} of validations) {
      const value = getNestedValue(values, field)
      if (value !== undefined && value !== null && value !== '') {
        if (validation && !validation.pattern.test(value)) {
          toast.error(`Form Validation Failed for ${field}`)
          return
        }
      }
    }

    try {
      if (new Date(values.birthDate) <= new Date(currentDateFormatted)) {
        if (images && images.length > 0) {
          try {
             if (images.length > 0 && formData.image) {
              values.image = formData.image;
            }
          } catch (error) {
            console.error(error)
          }
        }
        const response = await axios.put('clients/update', values)
        if(response.data.status === 400 && response.data.success === false) {
            toast.error(response.data.message)
        } else if (response.status === 200 && response.data && response.data.success) {
            toast.success('Client Updated Successfully')
        }
      } else {
        toast.error('Date should not be later than today')
      }
    } catch (err) {
      console.log(err)
      toast.error('Error Updating Client')
    } finally {
      setIsLoading(false)
    }
  }

  const handleFieldChange = (event) => {
    const {name, value} = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  return (
    <div>
      {isLoading ? (
        <div className='card'>
          <div className='card-body'>
            <div className='overlay d-flex justify-content-center'>
              <MoonLoader color='#9db2fc' size={80} loading={isLoading} />
            </div>
          </div>
        </div>
      ) : (
        <div className='fv-row fv-plugins-icon-container '>
          <div className='row'>
            <div className='fv-row fv-plugins-icon-container'>
              <div className='scroll-y '>
                <div>
                  {/* <div className="card">
                                    <div className='card-body'>
                                        <SubMenuSection individual={editIndividualData} />
                                    </div>
                                </div> */}
                <div>
                  <div>
                    {showEditingForm === false && showDetailsForm === true ? (
                      <ClientDetailsForm
                        setShowDetailsForm={setShowDetailsForm}
                        setShowEditingForm={setShowEditingForm}
                        ClientDetails={editIndividualData}
                      />
                    ) : (
                      <div className='card'>
                        <div className='card-body'>
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h2 className='mt-2'>Client Demographic Form (IDF) (Edit)</h2>
                            <Box
                              sx={{
                                marginBottom: '10px',
                                marginRight: '8px',
                                position: 'relative',
                                backgroundColor: !showImage ? 'grey' : 'transparent',
                                width: '90px',
                                height: '90px',
                              }}
                            >
                              {!showImage && (
                                <div
                                  style={{
                                    justifyContent: 'center',
                                    marginTop: '40%',
                                    display: 'flex',
                                  }}
                                >
                                  No Image
                                </div>
                              )}
                              <div style={{position: 'relative'}}>
                                {showImage && (
                                  <div style={{position: 'absolute', top: 0, right: 0}}>
                                    <IconButton
                                      onClick={() => {
                                        handleDeleteClick()
                                        setImages([])
                                        setShowImage(false)
                                      }}
                                    >
                                      <Close style={{color: 'grey'}} />
                                    </IconButton>
                                  </div>
                                )}
                                {showImage && imageSrc && (
                                  <img
                                    key={imageSrc}
                                    src={imageSrc}
                                    height='90px'
                                    width='90px'
                                    alt='Uploaded Preview'
                                  />
                                )}
                              </div>
                            </Box>
                          </div>

                          <form
                            onSubmit={(e) => {
                              e.preventDefault()
                              handleSubmit(formData)
                            }}
                          >
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                              <Box className='fieldContainer'>
                                <input
                                  type='file'
                                  accept='image/*'
                                  className='textField'
                                  onChange={onImageChange}
                                  style={{
                                    display: 'block',
                                    width: '100%',
                                    padding: '4px',
                                    fontSize: '14px',
                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                    borderRadius: '4px',
                                  }}
                                />
                              </Box>
                              <Box className='fieldContainer'>
                                <Autocomplete
                                  id='title'
                                  size='small'
                                  options={title}
                                  style={{maxWidth: 965}}
                                  isOptionEqualToValue={(option, value) =>
                                    option?.value === value?.value
                                  }
                                  value={formData?.title}
                                  onChange={(e, newValue) => {
                                    setFormData({...formData, title: newValue})
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant='outlined'
                                      label='Select Title'
                                    />
                                  )}
                                />
                              </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                              <Box className='fieldContainer'>
                                <Autocomplete
                                  id='gender'
                                  size='small'
                                  options={gender}
                                  isOptionEqualToValue={(option, value) =>
                                    option?.value === value?.value
                                  }
                                  value={formData?.gender}
                                  style={{maxWidth: 965}}
                                  onChange={(e, newValue) => {
                                    setFormData({...formData, gender: newValue})
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant='outlined'
                                      required
                                      label='Select Gender'
                                    />
                                  )}
                                />
                              </Box>
                              <Box className='fieldContainer'>
                                <TextField
                                  type='text'
                                  name='firstName'
                                  size='small'
                                  className='textField'
                                  value={formData.firstName || ''}
                                  onChange={handleFieldChange}
                                  variant='outlined'
                                  label='First Name'
                                  autoComplete='off'
                                  required
                                  error={Boolean(
                                    formData.firstName &&
                                      !validator.NAME_VALIDATION.pattern.test(formData.firstName)
                                  )}
                                  helperText={
                                    formData.firstName &&
                                    !validator.NAME_VALIDATION.pattern.test(formData.firstName)
                                      ? validator.NAME_VALIDATION.message
                                      : ''
                                  }
                                />
                              </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                              <Box className='fieldContainer'>
                                <TextField
                                  type='text'
                                  name='lastName'
                                  size='small'
                                  className='textField'
                                  value={formData.lastName || ''}
                                  onChange={handleFieldChange}
                                  variant='outlined'
                                  label='Last Name'
                                  autoComplete='off'
                                  required
                                  error={Boolean(
                                    formData.lastName &&
                                      !validator.NAME_VALIDATION.pattern.test(formData.lastName)
                                  )}
                                  helperText={
                                    formData.lastName &&
                                    !validator.NAME_VALIDATION.pattern.test(formData.lastName)
                                      ? validator.NAME_VALIDATION.message
                                      : ''
                                  }
                                />
                              </Box>
                              <Box className='fieldContainer'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    value={dayjs(formData.birthDate)}
                                    format='MM-DD-YYYY'
                                    onChange={(newValue) => {
                                      if (newValue) {
                                        const newDate = newValue.format('MM-DD-YYYY')
                                        setFormData((prevState) => ({
                                          ...prevState,
                                          birthDate: newDate,
                                        }))
                                      }
                                    }}
                                    className='textField'
                                    slotProps={{
                                      textField: {
                                        label: 'Birth Date',
                                        required: true,
                                        size: 'small',
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                              <Box className='fieldContainer'>
                                <TextField
                                  type='text'
                                  size='small'
                                  name='socialSecurityNumber'
                                  className='textField'
                                  value={formData.socialSecurityNumber}
                                  onChange={(e) => {
                                    const {name, value} = e.target
                                    let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 9)

                                    if (formattedValue.length > 3) {
                                      formattedValue =
                                        formattedValue.slice(0, 3) + '-' + formattedValue.slice(3)
                                    }
                                    if (formattedValue.length > 6) {
                                      formattedValue =
                                        formattedValue.slice(0, 6) + '-' + formattedValue.slice(6)
                                    }

                                    setFormData((prevData) => ({
                                      ...prevData,
                                      [name]: formattedValue,
                                    }))
                                  }}
                                  variant='outlined'
                                  placeholder='xxxxxxxxx'
                                  label='Social Security Number'
                                  autoComplete='off'
                                  error={Boolean(
                                    formData.socialSecurityNumber &&
                                      !validator.SSN_VALIDATION.pattern.test(
                                        formData.socialSecurityNumber
                                      )
                                  )}
                                  helperText={
                                    formData.socialSecurityNumber &&
                                    !validator.SSN_VALIDATION.pattern.test(
                                      formData.socialSecurityNumber
                                    )
                                      ? validator.SSN_VALIDATION.message
                                      : ''
                                  }
                                />
                              </Box>
                              <Box className='fieldContainer'>
                                <TextField
                                  type='text'
                                  name='medicaidNumber'
                                  value={formData.medicaidNumber}
                                  className='textField'
                                  onChange={(e) => {
                                    const {name, value} = e.target
                                    const formattedValue = value
                                      .replace(/[^a-zA-Z0-9]/g, '')
                                      .slice(0, 12)
                                    if (formattedValue.length > 0 && formattedValue.length < 8) {
                                      setError('Medicaid number must be at least 8 digits long.')
                                    } else {
                                      setError('')
                                    }
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      [name]: formattedValue,
                                    }))
                                  }}
                                  variant='outlined'
                                  size='small'
                                  label='Medicaid Number'
                                  autoComplete='off'
                                  error={!!error}
                                  helperText={error}
                                />
                              </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                              <Box className='fieldContainer'>
                                <TextField
                                  type='email'
                                  name='email'
                                  value={formData.email}
                                  size='small'
                                  className='textField'
                                  onChange={handleFieldChange}
                                  variant='outlined'
                                  label='Email'
                                  autoComplete='off'
                                  error={Boolean(
                                    formData.email &&
                                      !validator.EMAIL_VALIDATION.pattern.test(formData.email)
                                  )}
                                  helperText={
                                    formData.email &&
                                    !validator.EMAIL_VALIDATION.pattern.test(formData.email)
                                      ? validator.EMAIL_VALIDATION.message
                                      : ''
                                  }
                                />
                              </Box>
                              <Box className='fieldContainer'>
                                <TextField
                                  type='text'
                                  name='phoneNumber'
                                  size='small'
                                  className='textField'
                                  value={
                                    formData.phoneNumber && formData.phoneNumber !== 'N/A'
                                      ? formData.phoneNumber
                                      : ''
                                  }
                                  onChange={(e) => {
                                    const {name, value} = e.target
                                    let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10)
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      [name]: formattedValue,
                                    }))
                                  }}
                                  variant='outlined'
                                  label='Phone Number'
                                  autoComplete='off'
                                  error={Boolean(
                                    formData.phoneNumber &&
                                      !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                        formData.phoneNumber
                                      )
                                  )}
                                  helperText={
                                    formData.phoneNumber &&
                                    !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                      formData.phoneNumber
                                    )
                                      ? validator.PHONE_NUMBER_VALIDATION.message
                                      : ''
                                  }
                                />
                              </Box>
                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                              <Box className='fieldContainer'>
                                <Autocomplete
                                  options={race}
                                  value={formData.race}
                                  size='small'
                                  isOptionEqualToValue={(option, value) =>
                                    option?.value === value?.value
                                  }
                                  onChange={(e, newValue) => {
                                    setFormData({...formData, race: newValue})
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} variant='outlined' label='Select Race' />
                                  )}
                                />
                              </Box>

                            </Box>
                            <Box display='flex' flexDirection='row' className='fieldRow'>
                              <Box className='fieldContainer'>
                                <FormControlLabel
                                  label=''
                                  control={
                                    <Checkbox
                                      checked={showResAddr}
                                      onChange={() => {
                                        if (showResAddr) {
                                          // setShowMailingAddr(false)
                                          setShowResAddr(false)
                                        } else {
                                          setShowResAddr(true)
                                        }
                                      }}
                                    />
                                  }
                                />
                                <label
                                  style={{fontSize: '14px', fontWeight: '500', marginLeft: '-15px'}}
                                >
                                  Residential Address
                                </label>
                              </Box>
                            </Box>
                            {showResAddr === true ? (
                              <Box>
                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                  <Box className='fieldContainer'>
                                    <TextField
                                      type='text'
                                      name='st1'
                                      size='small'
                                      className='textField'
                                      value={formData.addrInfo.st1}
                                      onChange={(e) => {
                                        setFormData((prevState) => {
                                          return {
                                            ...prevState,
                                            addrInfo: {
                                              ...prevState.addrInfo,
                                              st1: e.target.value,
                                            },
                                          }
                                        })
                                      }}
                                      variant='outlined'
                                      label='Street 1'
                                      autoComplete='off'
                                      error={Boolean(
                                        formData.addrInfo.st1 &&
                                          !validator.STREET_VALIDATION.pattern.test(
                                            formData.addrInfo.st1
                                          )
                                      )}
                                      helperText={
                                        formData.addrInfo.st1 &&
                                        !validator.STREET_VALIDATION.pattern.test(
                                          formData.addrInfo.st1
                                        )
                                          ? validator.STREET_VALIDATION.message
                                          : ''
                                      }
                                    />
                                  </Box>
                                  <Box className='fieldContainer'>
                                    <TextField
                                      type='text'
                                      name='st2'
                                      size='small'
                                      className='textField'
                                      value={
                                        formData.addrInfo.st2 && formData.addrInfo.st2 !== 'N/A'
                                          ? formData.addrInfo.st2
                                          : ''
                                      }
                                      onChange={(e) => {
                                        setFormData((prevState) => {
                                          return {
                                            ...prevState,
                                            addrInfo: {
                                              ...prevState.addrInfo,
                                              st2: e.target.value,
                                            },
                                          }
                                        })
                                      }}
                                      variant='outlined'
                                      label='Street 2'
                                      autoComplete='off'
                                      error={Boolean(
                                        formData.addrInfo.st2 &&
                                          !validator.STREET_VALIDATION.pattern.test(
                                            formData.addrInfo.st2
                                          )
                                      )}
                                      helperText={
                                        formData.addrInfo.st2 &&
                                        !validator.STREET_VALIDATION.pattern.test(
                                          formData.addrInfo.st2
                                        )
                                          ? validator.STREET_VALIDATION.message
                                          : ''
                                      }
                                    />
                                  </Box>
                                </Box>
                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                  <Box className='fieldContainer'>
                                    <Autocomplete
                                      disabled={
                                        formData.addrInfo.st1 == '' && formData.addrInfo.st2 == ''
                                      }
                                      options={Country}
                                      size='small'
                                      className='textField'
                                      value={Country?.find(
                                        (option) => option.label === formData.addrInfo.country
                                      )}
                                      isOptionEqualToValue={(option, value) =>
                                        option?.value === value?.value
                                      }
                                      onChange={(_, newValue) => {
                                        const SelectedValue = newValue ? newValue : null
                                        setSelectedCountry(SelectedValue || '')
                                        setFormData((prevState) => {
                                          return {
                                            ...prevState,
                                            addrInfo: {
                                              ...prevState.addrInfo,
                                              country: SelectedValue ? SelectedValue.label : '',
                                            },
                                          }
                                        })
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant='outlined'
                                          label='- Select Country -'
                                          required={true}
                                        />
                                      )}
                                    />
                                  </Box>
                                  <Box className='fieldContainer'>
                                    <Autocomplete
                                      options={states}
                                      value={states?.find((option) => option.label === formData.addrInfo.state)}
                                      size='small'
                                      isOptionEqualToValue={(option, value) =>
                                        option?.value === value?.value
                                      }
                                      onChange={(_, newValue) => {
                                        const SelectedValue = newValue ? newValue : null
                                        setSelectedState(SelectedValue)
                                        setFormData((prevState) => {
                                          return {
                                            ...prevState,
                                            addrInfo: {
                                              ...prevState.addrInfo,
                                              state: SelectedValue && SelectedValue.label,
                                            },
                                          }
                                        })
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant='outlined'
                                          label='- Select State -'
                                        />
                                      )}
                                    />
                                  </Box>
                                </Box>
                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                  <Box className='fieldContainer'>
                                    <Autocomplete
                                      disabled={formData.addrInfo.state == ''}
                                      options={city}
                                      value={formData.addrInfo.city}
                                      size='small'
                                      isOptionEqualToValue={(option, value) =>
                                        option?.value === value?.value
                                      }
                                      onChange={(_, newValue) => {
                                        const SelectedValue = newValue ? newValue : null
                                        setFormData((prevState) => {
                                          return {
                                            ...prevState,
                                            addrInfo: {
                                              ...prevState.addrInfo,
                                              city: SelectedValue ? SelectedValue.value : '',
                                            },
                                          }
                                        })
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant='outlined'
                                          label='- Select City -'
                                          required={true}
                                        />
                                      )}
                                    />
                                  </Box>
                                  <Box className='fieldContainer'>
                                    <TextField
                                      type='text'
                                      name='zipCode'
                                      size='small'
                                      className='textField'
                                      value={formData.addrInfo.zipCode}
                                      onChange={(e) => {
                                        const {value} = e.target
                                        let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 5)
                                        setFormData((prevState) => {
                                          return {
                                            ...prevState,
                                            addrInfo: {
                                              ...prevState.addrInfo,
                                              zipCode: formattedValue,
                                            },
                                          }
                                        })
                                      }}
                                      variant='outlined'
                                      label='Zip Code'
                                      autoComplete='off'
                                      error={Boolean(
                                        formData.addrInfo.zipCode &&
                                          !validator.ZIP_CODE_VALIDATION.pattern.test(
                                            formData.addrInfo.zipCode
                                          )
                                      )}
                                      helperText={
                                        formData.addrInfo.zipCode &&
                                        !validator.ZIP_CODE_VALIDATION.pattern.test(
                                          formData.addrInfo.zipCode
                                        )
                                          ? validator.ZIP_CODE_VALIDATION.message
                                          : ''
                                      }
                                    />
                                  </Box>
                                </Box>
                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                  <Box className='fieldContainer'>
                                    <TextField
                                      type='text'
                                      name='primaryPhone'
                                      size='small'
                                      className='textField'
                                      value={
                                        formData.addrInfo.primaryPhone &&
                                        formData.addrInfo.primaryPhone !== 'N/A'
                                          ? formData.addrInfo.primaryPhone
                                          : ''
                                      }
                                      onChange={(e) => {
                                        const {value} = e.target
                                        let formattedValue = value
                                          .replace(/[^0-9]/g, '')
                                          .slice(0, 10)
                                        setFormData((prevState) => {
                                          return {
                                            ...prevState,
                                            addrInfo: {
                                              ...prevState.addrInfo,
                                              primaryPhone: formattedValue,
                                            },
                                          }
                                        })
                                      }}
                                      variant='outlined'
                                      label='Primary Phone'
                                      autoComplete='off'
                                      error={Boolean(
                                        formData.addrInfo.primaryPhone &&
                                          !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                            formData.addrInfo.primaryPhone
                                          )
                                      )}
                                      helperText={
                                        formData.addrInfo.primaryPhone &&
                                        !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                          formData.addrInfo.primaryPhone
                                        )
                                          ? validator.PHONE_NUMBER_VALIDATION.message
                                          : ''
                                      }
                                    />
                                  </Box>
                                  <Box className='fieldContainer'>
                                    <TextField
                                      type='text'
                                      name='secondaryPhone'
                                      size='small'
                                      className='textField'
                                      value={
                                        formData.addrInfo.secondaryPhone &&
                                        formData.addrInfo.secondaryPhone !== 'N/A'
                                          ? formData.addrInfo.secondaryPhone
                                          : ''
                                      }
                                      onChange={(e) => {
                                        const {value} = e.target
                                        let formattedValue = value
                                          .replace(/[^0-9]/g, '')
                                          .slice(0, 10)
                                        setFormData((prevState) => {
                                          return {
                                            ...prevState,
                                            addrInfo: {
                                              ...prevState.addrInfo,
                                              secondaryPhone: formattedValue,
                                            },
                                          }
                                        })
                                      }}
                                      variant='outlined'
                                      label='Secondary Phone'
                                      autoComplete='off'
                                      error={Boolean(
                                        formData.addrInfo.secondaryPhone &&
                                          !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                            formData.addrInfo.secondaryPhone
                                          )
                                      )}
                                      helperText={
                                        formData.addrInfo.secondaryPhone &&
                                        !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                          formData.addrInfo.secondaryPhone
                                        )
                                          ? validator.PHONE_NUMBER_VALIDATION.message
                                          : ''
                                      }
                                    />
                                  </Box>
                                </Box>
                                <Box display='flex' flexDirection='row' className='fieldRow'>
                                  <Box className='fieldContainer'>
                                    <TextField
                                      type='text'
                                      name='additionalPhone'
                                      size='small'
                                      className='textField'
                                      value={
                                        formData.addrInfo.additionalPhone &&
                                        formData.addrInfo.additionalPhone !== 'N/A'
                                          ? formData.addrInfo.additionalPhone
                                          : ''
                                      }
                                      onChange={(e) => {
                                        const {value} = e.target
                                        let formattedValue = value
                                          .replace(/[^0-9]/g, '')
                                          .slice(0, 10)
                                        setFormData((prevState) => {
                                          return {
                                            ...prevState,
                                            addrInfo: {
                                              ...prevState.addrInfo,
                                              additionalPhone: formattedValue,
                                            },
                                          }
                                        })
                                      }}
                                      variant='outlined'
                                      label='Additional Phone'
                                      autoComplete='off'
                                      error={Boolean(
                                        formData.addrInfo.additionalPhone &&
                                          !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                            formData.addrInfo.additionalPhone
                                          )
                                      )}
                                      helperText={
                                        formData.addrInfo.additionalPhone &&
                                        !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                          formData.addrInfo.additionalPhone
                                        )
                                          ? validator.PHONE_NUMBER_VALIDATION.message
                                          : ''
                                      }
                                    />
                                  </Box>
                                  </Box>
                                  <Box display='flex' flexDirection='row' className='fieldRow'>
                                    <Box className='fieldContainer'>
                                      <label className='fw-bold fs-6'>
                                        Is Mailing Address the same as Residential?
                                      </label>
                                      <RadioGroup
                                        value={showMailingAddr ? 'no' : 'yes'}
                                        onChange={() => {
                                          setShowMailingAddr(!showMailingAddr)
                                          if (!showMailingAddr) {
                                            setFormData((prevData) => {
                                              return {
                                                ...prevData,
                                                mailingInfo: {
                                                  st1: '',
                                                  st2: '',
                                                  primaryPhone: '',
                                                  secondaryPhone: '',
                                                  additionalPhone: '',
                                                  zipCode: '',
                                                  state: '',
                                                  city: '',
                                                  country: '',
                                                  lat: '',
                                                  lng: '',
                                                },
                                              }
                                            })
                                          }
                                        }}
                                        row
                                      >
                                        <FormControlLabel
                                          value='yes'
                                          control={<Radio />}
                                          label='Yes'
                                          labelPlacement='end'
                                        />
                                        <FormControlLabel
                                          value='no'
                                          control={<Radio />}
                                          label='No'
                                          labelPlacement='end'
                                        />
                                      </RadioGroup>
                                    </Box>
                                  </Box>
                                  {showMailingAddr === true ? (
                                    <Box>
                                      <Box display='flex' flexDirection='row' className='fieldRow'>
                                        <Box className='fieldContainer'>
                                          <TextField
                                            type='text'
                                            name='st1'
                                            size='small'
                                            className='textField'
                                            value={formData.mailingInfo.st1}
                                            onChange={(e) => {
                                              setFormData((prevState) => {
                                                return {
                                                  ...prevState,
                                                  mailingInfo: {
                                                    ...prevState.mailingInfo,
                                                    st1: e.target.value,
                                                  },
                                                }
                                              })
                                            }}
                                            variant='outlined'
                                            label='Street 1'
                                            autoComplete='off'
                                            error={Boolean(
                                              formData.mailingInfo.st1 &&
                                                !validator.STREET_VALIDATION.pattern.test(
                                                  formData.mailingInfo.st1
                                                )
                                            )}
                                            helperText={
                                              formData.mailingInfo.st1 &&
                                              !validator.STREET_VALIDATION.pattern.test(
                                                formData.mailingInfo.st1
                                              )
                                                ? validator.STREET_VALIDATION.message
                                                : ''
                                            }
                                          />
                                        </Box>
                                        <Box className='fieldContainer'>
                                          <TextField
                                            type='text'
                                            name='st2'
                                            size='small'
                                            className='textField'
                                            value={formData.mailingInfo.st2}
                                            onChange={(e) => {
                                              setFormData((prevState) => {
                                                return {
                                                  ...prevState,
                                                  mailingInfo: {
                                                    ...prevState.mailingInfo,
                                                    st2: e.target.value,
                                                  },
                                                }
                                              })
                                            }}
                                            variant='outlined'
                                            label='Street 2'
                                            autoComplete='off'
                                            error={Boolean(
                                              formData.mailingInfo.st2 &&
                                                !validator.STREET_VALIDATION.pattern.test(
                                                  formData.mailingInfo.st2
                                                )
                                            )}
                                            helperText={
                                              formData.mailingInfo.st2 &&
                                              !validator.STREET_VALIDATION.pattern.test(
                                                formData.mailingInfo.st2
                                              )
                                                ? validator.STREET_VALIDATION.message
                                                : ''
                                            }
                                          />
                                        </Box>
                                      </Box>
                                      <Box display='flex' flexDirection='row' className='fieldRow'>
                                        <Box className='fieldContainer'>
                                          <Autocomplete
                                            disabled={
                                              formData.mailingInfo.st1 == '' &&
                                              formData.mailingInfo.st2 == ''
                                            }
                                            options={Country}
                                            size='small'
                                            className='textField'
                                            value={Country?.find(
                                              (option) =>
                                                option.label === formData.mailingInfo.country
                                            )}
                                            isOptionEqualToValue={(option, value) =>
                                              option?.value === value?.value
                                            }
                                            onChange={(_, newValue) => {
                                              const SelectedValue = newValue ? newValue : null
                                              setSelectedMailingCountry(SelectedValue || '')
                                              setFormData((prevState) => {
                                                return {
                                                  ...prevState,
                                                  mailingInfo: {
                                                    ...prevState.mailingInfo,
                                                    country: SelectedValue
                                                      ? SelectedValue.label
                                                      : '',
                                                  },
                                                }
                                              })
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                variant='outlined'
                                                label='- Select Country -'
                                              />
                                            )}
                                          />
                                        </Box>
                                        <Box className='fieldContainer'>
                                          <Autocomplete
                                            disabled={formData.mailingInfo.country == ''}
                                            options={mailingStates}
                                            size='small'
                                            value={mailingStates?.find(
                                              (option) =>
                                                option.label === formData.mailingInfo.state
                                            )}
                                            isOptionEqualToValue={(option, value) =>
                                              option?.value === value?.value
                                            }
                                            onChange={(_, newValue) => {
                                              const SelectedValue = newValue ? newValue : null
                                              setSelectedMailingState(SelectedValue)
                                              setFormData((prevState) => {
                                                return {
                                                  ...prevState,
                                                  mailingInfo: {
                                                    ...prevState.mailingInfo,
                                                    state: SelectedValue ? SelectedValue.label : '',
                                                  },
                                                }
                                              })
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                variant='outlined'
                                                label='- Select State -'
                                              />
                                            )}
                                          />
                                        </Box>
                                      </Box>
                                      <Box display='flex' flexDirection='row' className='fieldRow'>
                                        <Box className='fieldContainer'>
                                          <Autocomplete
                                            disabled={formData.mailingInfo.state == ''}
                                            options={mailingCity}
                                            value={formData.mailingInfo.city}
                                            size='small'
                                            isOptionEqualToValue={(option, value) =>
                                              option?.value === value?.value
                                            }
                                            onChange={(_, newValue) => {
                                              const SelectedValue = newValue ? newValue : ''
                                              setFormData((prevState) => {
                                                return {
                                                  ...prevState,
                                                  mailingInfo: {
                                                    ...prevState.mailingInfo,
                                                    city: SelectedValue ? SelectedValue.value : '',
                                                  },
                                                }
                                              })
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                variant='outlined'
                                                label='- Select City -'
                                              />
                                            )}
                                          />
                                        </Box>
                                        <Box className='fieldContainer'>
                                          <TextField
                                            type='text'
                                            name='zipCode'
                                            size='small'
                                            className='textField'
                                            value={formData.mailingInfo.zipCode}
                                            onChange={(e) => {
                                              const {value} = e.target
                                              let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 5)
                                              setFormData((prevState) => {
                                                return {
                                                  ...prevState,
                                                  mailingInfo: {
                                                    ...prevState.mailingInfo,
                                                    zipCode: formattedValue,
                                                  },
                                                }
                                              })
                                            }}
                                            variant='outlined'
                                            label='Zip Code'
                                            autoComplete='off'
                                            error={Boolean(
                                              formData.mailingInfo.zipCode &&
                                                !validator.ZIP_CODE_VALIDATION.pattern.test(
                                                  formData.mailingInfo.zipCode
                                                )
                                            )}
                                            helperText={
                                              formData.mailingInfo.zipCode &&
                                              !validator.ZIP_CODE_VALIDATION.pattern.test(
                                                formData.mailingInfo.zipCode
                                              )
                                                ? validator.ZIP_CODE_VALIDATION.message
                                                : ''
                                            }
                                          />
                                        </Box>
                                      </Box>
                                      <Box display='flex' flexDirection='row' className='fieldRow'>
                                        <Box className='fieldContainer'>
                                          <TextField
                                            type='text'
                                            name='mailingPrimaryPhone'
                                            size='small'
                                            className='textField'
                                            value={formData.mailingInfo.primaryPhone}
                                            onChange={(e) => {
                                              const {value} = e.target
                                              let formattedValue = value
                                                .replace(/[^0-9]/g, '')
                                                .slice(0, 10)
                                              setFormData((prevState) => {
                                                return {
                                                  ...prevState,
                                                  mailingInfo: {
                                                    ...prevState.mailingInfo,
                                                    primaryPhone: formattedValue,
                                                  },
                                                }
                                              })
                                            }}
                                            variant='outlined'
                                            label='Primary Phone'
                                            autoComplete='off'
                                            error={Boolean(
                                              formData.mailingInfo.primaryPhone &&
                                                !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                                  formData.mailingInfo.primaryPhone
                                                )
                                            )}
                                            helperText={
                                              formData.mailingInfo.primaryPhone &&
                                              !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                                formData.mailingInfo.primaryPhone
                                              )
                                                ? validator.PHONE_NUMBER_VALIDATION.message
                                                : ''
                                            }
                                          />
                                        </Box>
                                        <Box className='fieldContainer'>
                                          <TextField
                                            type='text'
                                            name='mailingSecondaryPhone'
                                            size='small'
                                            className='textField'
                                            value={formData.mailingInfo.secondaryPhone}
                                            onChange={(e) => {
                                              const {value} = e.target
                                              let formattedValue = value
                                                .replace(/[^0-9]/g, '')
                                                .slice(0, 10)
                                              setFormData((prevState) => {
                                                return {
                                                  ...prevState,
                                                  mailingInfo: {
                                                    ...prevState.mailingInfo,
                                                    secondaryPhone: formattedValue,
                                                  },
                                                }
                                              })
                                            }}
                                            variant='outlined'
                                            label='Secondary Phone'
                                            autoComplete='off'
                                            error={Boolean(
                                              formData.mailingInfo.secondaryPhone &&
                                                !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                                  formData.mailingInfo.secondaryPhone
                                                )
                                            )}
                                            helperText={
                                              formData.mailingInfo.secondaryPhone &&
                                              !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                                formData.mailingInfo.secondaryPhone
                                              )
                                                ? validator.PHONE_NUMBER_VALIDATION.message
                                                : ''
                                            }
                                          />
                                        </Box>
                                      </Box>
                                      <Box display='flex' flexDirection='row' className='fieldRow'>
                                        <Box className='fieldContainer'>
                                          <TextField
                                            type='text'
                                            name='mailingAdditionalPhone'
                                            size='small'
                                            className='textField'
                                            value={formData.mailingInfo.additionalPhone}
                                            onChange={(e) => {
                                              const {value} = e.target
                                              let formattedValue = value
                                                .replace(/[^0-9]/g, '')
                                                .slice(0, 10)
                                              setFormData((prevState) => {
                                                return {
                                                  ...prevState,
                                                  mailingInfo: {
                                                    ...prevState.mailingInfo,
                                                    additionalPhone: formattedValue,
                                                  },
                                                }
                                              })
                                            }}
                                            variant='outlined'
                                            label='Additional Phone'
                                            autoComplete='off'
                                            error={Boolean(
                                              formData.mailingInfo.additionalPhone &&
                                                !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                                  formData.mailingInfo.additionalPhone
                                                )
                                            )}
                                            helperText={
                                              formData.mailingInfo.additionalPhone &&
                                              !validator.PHONE_NUMBER_VALIDATION.pattern.test(
                                                formData.mailingInfo.additionalPhone
                                              )
                                                ? validator.PHONE_NUMBER_VALIDATION.message
                                                : ''
                                            }
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  ) : null}
                                </Box>
                              ) : null}
                              <Box style={{marginTop: '24px'}}>
                                <Box
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                    borderBottomLeftRadius: '4px',
                                    borderBottomRightRadius: '4px',
                                    padding: '10px',
                                  }}
                                >
                                  <Button
                                    type='submit'
                                    sx={{padding: '4px', margin: '4px'}}
                                    variant='contained'
                                    style={{
                                      marginLeft: '6px',
                                      backgroundColor: '#28a745',
                                      color: '#fff',
                                      // '&:hover': {
                                      //     backgroundColor: '#218838',
                                      // },
                                    }}
                                  >
                                    Save
                                  </Button>
                                </Box>
                              </Box>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EditIndividualForm
