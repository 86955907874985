import { Redirect, Route, Switch } from 'react-router-dom'
import ClientLogList from '../../../../../pages/admin/care/clientLog/new/List'
import ListOfClientLogs from '../../../../../pages/admin/care/clientLog/list/List'
import SearchClientLogFields from '../../../../../pages/admin/care/clientLog/search/SearchClientLogFields'
import React from 'react'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'
import ListOfClientLogsEdit from '../../../../../pages/admin/care/clientLog/list/Edit'
import AddClientLogForm from '../../../../../pages/admin/care/clientLog/new/New'

const ClientLogsIndexRoutes: React.FC = () => {
    const {auth, client} = useSelector((state: {auth: any, client: any}) => state)
    const userPermissions = auth.user.roleId;
    const canSearch = hasPermission(userPermissions, 'ADMIN.CARE.CLIENT_LOGS.SEARCH');
    const canCreate = hasPermission(userPermissions, 'ADMIN.CARE.CLIENT_LOGS.CREATE');
    const canRead = hasPermission(userPermissions, 'ADMIN.CARE.CLIENT_LOGS.VIEW');

    const getDefaultPath = () => {
        if (canRead) return `/admin/care/incident-report/list/${client.clientID}`
        if (canRead) return `/admin/care/incident-report/list/${client.clientID}`
        if (canCreate) return '/admin/care/incident-report/new'
        if (canSearch) return '/admin/care/incident-report/search'
        return '/error/404'
      }
    return (
        <Switch>
        <Route exact path='/admin/care/incident-report'>
          <Redirect to={getDefaultPath()} />
        </Route>
            <RouteWithPermission path='/admin/care/incident-report/new' component={AddClientLogForm} hasPerm={canCreate} />
            <RouteWithPermission path='/admin/care/incident-report/list/:clientID/:id' component={ListOfClientLogsEdit} hasPerm={canRead} />
            <RouteWithPermission path='/admin/care/incident-report/list/:clientID' component={ListOfClientLogs} hasPerm={canRead} />
            <RouteWithPermission path='/admin/care/incident-report/search' component={SearchClientLogFields} hasPerm={canSearch} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default ClientLogsIndexRoutes
