import React, {FC, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Container, CssBaseline, Box, Typography, Grid, useTheme, useMediaQuery} from '@mui/material'
import DynamicIcon from './DynamicIcon'
// import {iconsData} from './Dashboard_contstant'
import {hasPermission} from '../../../_helper/hasPermission'
import {RootState} from '../../../setup'
import { permissions } from "../../../_constants/permissions";
import NoteNew from './assets/noteNew.png'
import NotesList from './assets/NotesList.png'
import ClientBehavior from './assets/behaviour.png'
import BehaviorExport from './assets/BehaviorExport.png'
import ClientList from './assets/search-client.png'
import CareGiver from './assets/assistant.png'
import CareGiverNew from './assets/hand.png'
import LogsNew from './assets/add-properties.png'
import Enrollment from './assets/sign-up.png'
import MyAvaiablity from './assets/schedules.png'
import Schedules from './assets/calender.png'
import TimeOff from './assets/coffee-time.png'
import CompanyTimeSheet from './assets/companyTimeSheet.png'
import TimeSheets from './assets/timesheet.png'
import AttendanceNew from './assets/fingerprint.png'
import AttendanceSearch from './assets/attendanceSearch.png'
import AuthorizationNew from './assets/locksmith.png'
import AuthorizationList from './assets/authorization.png'
import MedicationEntry from './assets/medication.png'
import MedicationList from './assets/medicationList.png'
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const selectAuth = (state: RootState) => state.auth
const select = (state: RootState) => state

const DashboardWrapper: FC = () => {
  const [filteredData, setFilteredData] = useState([])
  const auth = useSelector(selectAuth)
  const isCaregiver = auth?.user?.isCaregiver
  const userPermissions = auth.user.roleId
  
  //   const classes = useStyles()
  const iconsData = [
    { png: ClientList, title: 'Clients List', link: '/admin/selected-client', permission: permissions.admin_care_view },
    { icon: PersonAddIcon, title: 'New Client', link: '/admin/care/individual-intake/new', permission: permissions.admin_care_create },
    { png: CareGiver, title: 'List Caregiver', link: '/caregivers', permission: permissions.care_giver_view },
    { png: CareGiverNew, title: 'New Caregiver', link: '/caregivers/new', permission: permissions.care_giver_create },
    ...(isCaregiver
      ? [
        { png: MyAvaiablity, title: 'Caregiver Availability', link: '/caregivers/schedules/mySchedules', permission: permissions.care_giver_schedules_myAvailability_view },
        { png: TimeSheets, title: 'Caregiver Timesheet', link: '/caregivers/timesheets' , permission: permissions.care_giver_time_sheets_timeSheets_view},
        { png: CompanyTimeSheet, title: 'All Timesheets', link: '/caregivers/company-timesheets', permission: permissions.care_giver_time_sheets_companySheet_view },
        ]
      : []),
    { png: Schedules, title: 'Caregiver Schedules', link: '/caregivers/schedules/allSchedules', permission: permissions.care_giver_schedules_view },
    { png: TimeOff, title: 'Caregiver Time Off', link: '/caregivers/schedules/timeOffs', permission: permissions.care_giver_schedules_view }, // Will have create permmission for timeOff
    { png: AttendanceNew, title: 'New Attendance', link: '/billing/attendance/new', permission: permissions.billing_attendance_new_view },
    { png: AttendanceSearch, title: 'Search Attendance', link: '/billing/attendance/search' , permission: permissions.billing_attendance_search_view},
    { png: AuthorizationNew, title: 'Service Authorization New', link: '/billing/professionalclaim/service-authorization/new', permission: permissions.billing_professionalClaim_serviceAuthorization_create },
    { png: AuthorizationList, title: 'Service Authorization List', link: '/billing/professionalclaim/service-authorization/list' , permission: permissions.billing_professionalClaim_serviceAuthorization_view},
    { png: MedicationEntry, title: 'Medication Entry (MARS)', link: '/mars/individual-medication/new', permission: permissions.mars_individual_medication_continued_medication_create },
    { png: MedicationList, title: 'Medication List (MARS)', link: '/mars/individual-medication/list', permission: permissions.mars_individual_medication_continued_medication_view },
    { png: NotesList, title: 'Notes List', link: '/notes/caregiver-notes/notes', permission: permissions.care_giver_notes_view },
    { png: NoteNew, title: 'Notes New', link: '/notes/caregiver-notes/notes/new', permission: permissions.care_giver_notes_note_create },
    { png: ClientBehavior, title: 'Client Behavior', link: '/behaviour/client', permission : permissions.behavior_target_view },
    { png: BehaviorExport, title: 'Export Behavior', link: '/behaviour/export', permission: permissions.behavior_export_view},
];
  useEffect(() => {
    const filteredIconsData = iconsData.filter((iconData) => {
      if (iconData.permission) {
        return hasPermission(userPermissions, iconData.permission)
      }
      return true
    })
    setFilteredData(filteredIconsData)
  }, [userPermissions])

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('xs'))
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMd = useMediaQuery(theme.breakpoints.down('md'))

  const getFontSize = () => {
    if (isXs) return '1.5rem'
    if (isSm) return '2rem'
    if (isMd) return '2.5rem'
    return '3rem'
  }
  const is720p = useMediaQuery(
    '(width: 1280px), (width: 1366px)'
  );
  const is1080p = useMediaQuery(
    '(width: 1920px)'
  );

  return (
    <Container style={{height: '100%',}} component='main' maxWidth='lg'>
      <CssBaseline />
      <Box>
        <Grid style={{height: '120px'}}>
          <Typography
            variant='h2'
            fontWeight={600}
            mt='2rem'
            mb='2rem'
            component='div'
            align='center'
            sx={{fontSize: getFontSize()}}
          >
            Welcome To Your Dashboard
          </Typography>
        </Grid>
        <Grid
          style={{
            height: '100%',
            overflowY: 'hidden',
            overflowX: 'hidden',
            scrollbarWidth: isSm? 'none': 'auto',
            msOverflowStyle: isSm? 'none': 'auto',
          }}
          container
          spacing={8}
        >
          {filteredData.map((data, index) => (
            <Grid  item key={index} xs={6} sm={6} md={4} lg={3} xl={3}>
              <DynamicIcon {...data} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

export {DashboardWrapper}
