import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import BackButton from '../../../../../reusable-components/Buttons/BackButton'
import { addClientToProgram,deleteClientFromProgram,getClientForEnrollment } from '../store/actions'
import { useParams } from 'react-router-dom'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { permissions } from '../../../../../../_constants/permissions'
import { AppDispatch } from '../../../../../../setup/redux/Store'
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes'
import { LIST_ENROLLMENT_COLUMNS, LIST_ENROLLMENT_COLUMNS_DATA_MAPPING, LIST_ENROLLMENT_SORTING_COLUMNS } from '../../../care/care_table_constants'
const selectAuth = (state: RootState) => state.auth;
const ProgramIndividuals = () => {
    const [programData, setProgramData] = useState({
        _id: '',
        programName: '',
        programType: '',
    })
    const [enrollableFilteredData, setEnrollableFilteredData] = useState([])
    const [enrolledFilteredData, setEnrolledFilteredData] = useState([])
    const [enrollableSearchTerm, setEnrollableSearchTerm] = useState('')
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [enrolledSearchTerm, setEnrolledSearchTerm] = useState('')
    const [queryDataForEnrollableClient, setQueryDataForEnrollableClient] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 0,
        sortBy: 'name',
        sortDir: 'desc',
        searchTerm: ''
    })
    const [queryDataForEnrolledClient, setQueryDataForEnrolledClient] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 0,
        sortBy: 'name',
        sortDir: 'desc',
        searchTerm: ''
    })
    const [deleteClient, setDeleteClient] = useState({
        _id: ''
    })
    const [addedClient, setAddedClient] = useState({
        _id: ''
    })
    const auth = useSelector(selectAuth);
    const dispatch = useDispatch<AppDispatch>()
    const parameters: { programID: string } = useParams()
    const userPermissions = auth.user.roleId;
    useEffect(() => {
          const fetchEnrolledClients = async () => {
            setIsFetchingData(true)
            try{
                const programData = await dispatch(getClientForEnrollment({
                    id: parameters.programID,
                    page: queryDataForEnrolledClient.pageNumber,
                    pageSize: queryDataForEnrolledClient.pageSize,
                    sortBy: queryDataForEnrolledClient.sortBy,
                    sortDir: queryDataForEnrolledClient.sortDir,
                    searchEnrolledTerm: queryDataForEnrolledClient.searchTerm
                }));
                
                const { enrolled } = programData?.payload?.data;
                setProgramData(enrolled.enrolledClientsDetails)
                setEnrolledFilteredData(enrolled.data)
                setQueryDataForEnrolledClient(prevState => ({
                    ...prevState,
                    currentPage: enrolled.currentPage,
                    totalPages: enrolled.totalPages,
                    totalCounts: enrolled.totalCounts,
                }));
            } catch (err) {
                console.log('Error while fetching enrolled clients', err)
            } finally{
                setIsFetchingData(false)
            }
          }
          const fetchEnrollableClients = async () => {
            setIsFetchingData(true)
            try{
                const programData = await  dispatch(getClientForEnrollment({
                    id: parameters.programID,
                    page: queryDataForEnrollableClient.pageNumber,
                    pageSize: queryDataForEnrollableClient.pageSize,
                    sortBy: queryDataForEnrollableClient.sortBy,
                    sortDir: queryDataForEnrollableClient.sortDir,
                    searchEnrollableTerm: queryDataForEnrollableClient.searchTerm,
                }))
                const { enrollable } = programData?.payload?.data
                setEnrollableFilteredData(enrollable.data)
                setQueryDataForEnrollableClient(prevState => ({
                    ...prevState,
                    currentPage: enrollable.currentPage,
                    totalPages: enrollable.totalPages,
                    totalCounts: enrollable.totalCounts,
                }));
            } catch (err) {
                console.log('Error while fetching enrollable clients', err)
            } finally{
                setIsFetchingData(false)
            }
          }   
        
        if(parameters.programID) {
            fetchEnrolledClients()
            fetchEnrollableClients()
        }
    }, [
        addedClient,
        deleteClient,
        queryDataForEnrolledClient.pageNumber,
        queryDataForEnrolledClient.pageSize,
        queryDataForEnrolledClient.sortDir,
        queryDataForEnrolledClient.sortBy,
        queryDataForEnrolledClient.searchTerm,
        queryDataForEnrollableClient.pageNumber,
        queryDataForEnrollableClient.pageSize,
        queryDataForEnrollableClient.sortDir,
        queryDataForEnrollableClient.sortBy,
        queryDataForEnrollableClient.searchTerm,
      ])
    const handleAddClients = (row) => {
        dispatch(addClientToProgram({ clientId: row._id, programId: programData._id }))
            .then(() => {
                setQueryDataForEnrollableClient((prevState)=> {
                    return {
                      ...prevState,
                      searchTerm : '',
                      pageNumber: 1
                    }
                  })
            })
            .catch((error) => {
                console.error('Error adding client:', error);
            });
    
        setAddedClient({ _id: '' });
    };
    const handleDeleteClient = (row) => {
        dispatch(deleteClientFromProgram({ clientId: row._id, programId: programData._id }))
            .then(() => {
                setQueryDataForEnrolledClient((prevState)=> {
                    return {
                      ...prevState,
                      searchTerm : '',
                      pageNumber: 1
                    }
                  })
            })
            .catch((error) => {
                console.error('Error deleting client:', error);
            });
    
        setDeleteClient({ _id: '' });
    };
    const handleAddBox = (row: { _id: string; }) => {
        if (row._id) {
            setAddedClient(row);
            handleAddClients(row);
        }
    }
    const handleDelete = (row: { _id: string; }) => {
        if (row._id) {
            setDeleteClient(row)
            handleDeleteClient(row)
        }
    }
    return (
        <div>
            <div className="card d-flex justify-content-center">
                <div className="card-body h-md-100 w-100">
                    <div className='d-flex w-100'>
                        <div className="w-100">
                            <div className="card-title" style={{ display: 'flex', justifyContent: 'space-between', }}>
                                <p className='fs-2 fw-bolder'>Program Details</p>
                                <BackButton />
                            </div>
                        </div>
                    </div>
                    <div>
                    <div className="d-flex py-1">
                            <label className="w-50 fw-bolder">Program Name :</label>
                            {
                                programData &&
                                    <label className="w-50 form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">{programData?.programName}</label>
                            }
                        </div>
                        <div className="d-flex py-1 mb-2">
                            <label className="w-50 fw-bolder">Program Type :</label>
                            {
                                programData &&
                                    <label className="w-50 form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">{programData?.programType}</label>
                            }
                        </div>
                        <TableTitle title='Enrollable Clients'></TableTitle>
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={enrollableSearchTerm}
                            setSearchTerm={setEnrollableSearchTerm}
                            setQueryData={setQueryDataForEnrollableClient}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                        <DynamicTable
                            columns={LIST_ENROLLMENT_COLUMNS}
                            data={enrollableFilteredData}
                            canAddBox={hasPermission(userPermissions, permissions.admin_general_enrollement_by_program_create)}
                            canDelete={false}
                            columnDataMapping={LIST_ENROLLMENT_COLUMNS_DATA_MAPPING}
                            sortingColumns={LIST_ENROLLMENT_SORTING_COLUMNS}
                            title='Are you sure you want to enroll this client?'
                            clickableRow={false}
                            onAddBox={handleAddBox}
                            setQueryData={setQueryDataForEnrollableClient}
                            queryData={queryDataForEnrollableClient}
                            tableLoader={isFetchingData}
                        />
                        <TableTitle title='Enrolled Clients'></TableTitle>
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={enrolledSearchTerm}
                            setSearchTerm={setEnrolledSearchTerm}
                            setQueryData={setQueryDataForEnrolledClient}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                        <DynamicTable
                            columns={LIST_ENROLLMENT_COLUMNS}
                            data={enrolledFilteredData}
                            canAddBox={false}
                            canDelete={hasPermission(userPermissions, permissions.admin_general_enrollement_by_program_delete)}
                            columnDataMapping={LIST_ENROLLMENT_COLUMNS_DATA_MAPPING}
                            sortingColumns={LIST_ENROLLMENT_SORTING_COLUMNS}
                            title='Are you sure you want to delete this client enrollment?'
                            clickableRow={false}
                            onDelete={handleDelete}
                            setQueryData={setQueryDataForEnrolledClient}
                            queryData={queryDataForEnrolledClient}
                            tableLoader={isFetchingData}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProgramIndividuals