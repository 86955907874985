import {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import HealthProfileMenu from './HealthProfileMenu'
import axios from 'axios'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import {HEALTH_DATA_INPUTS} from '../CARE_CONSTANTS'
import AdminCareHeader from '../AdminCareHeader'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import {toast} from 'react-toastify'
import {MoonLoader} from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import { setPatient } from '../individual-intake/store'
const selectAuth = (state) => state.client;

const HealthBody = () => {
  const [client, setClient] = useState({})
  const [editHealthProfile, setEditHealthProfile] = useState({
    heightFeet: '',
    heightInch: '',
    weightRangeFrom: '',
    weightRangeTo: '',
    temperatureFrom: '',
    temperatureTo: '',
    pulseFrom: '',
    pulseTo: '',
    oxygenSaturationFrom: '',
    oxygenSaturationTo: '',
    respirationFrom: '',
    respirationTo: '',
    bloodSystolicFrom: '',
    bloodSystolicTo: '',
    bloodDiastolicFrom: '',
    bloodDiastolicTo: '',
  })
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const parameters = useParams()
  const {patient} = useSelector(selectAuth);
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        if (patient?.healthInfo) {
          setEditHealthProfile(patient?.healthInfo)
          setIsLoading(false)
        } 
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleSubmitForm = async (formData) => {
    try {
      const response = await axios.put(
        `/clients/update/healthInfo/${parameters.clientID}`,
        formData
      )
      if (response.status === 200 && response.data.success === true) {
        toast.success('Health Data Updated Successfully')
        dispatch(setPatient(response?.data?.data));
        localStorage.setItem('patientData', JSON.stringify(response?.data?.data));
      }
    } catch (error) {
      console.error(error)
    }
  }

  const buttons = [
    {label: 'Cancel', variant: 'contained', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit'},
  ]

  return (
    <div>
      <AdminCareHeader />
      <HealthProfileMenu />
      <div className='card my-10'>
        <div className='card-body'>
          {isLoading ? (
            <div className='overlay d-flex justify-content-center'>
              <MoonLoader color='#9db2fc' size={80} loading={isLoading} />
            </div>
          ) : (
            <div className='mt-5'>
              <TableTitle title='Edit Health Data'></TableTitle>
              <div className='mt-8'>
                <DynamicForm
                  fields={HEALTH_DATA_INPUTS}
                  data={editHealthProfile}
                  onSubmit={handleSubmitForm}
                  buttons={buttons}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HealthBody
