import React, { useState } from 'react';
import { TextField, InputAdornment, Box, Tooltip, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { QueryData } from './DynamicTableTypes';

type SetQueryData = React.Dispatch<React.SetStateAction<QueryData>>;
type SetSearchTerm = React.Dispatch<React.SetStateAction<string>>;

const TableSearchBar: React.FC<{
  label?: string;
  variant?: "filled" | 'outlined' | 'standard';
  value?: string;
  size?: "small" | "medium";
  setQueryData?: SetQueryData
  setSearchTerm?: SetSearchTerm;
  margin?: "normal" | "none" | "dense";
  inputAdornmentPosition?: "end" | "start";
}> = ({
  label,
  variant,
  size,
  value,
  setQueryData,
  setSearchTerm,
  margin,
  inputAdornmentPosition,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleFocus = () => {
    setTooltipOpen(true);
  };
  const handleBlur = () => {
    setTooltipOpen(false);
  };

  const handleSearchChange = (event: { target: { value: string; }; }) => {
    const searchTerm = event.target.value.toLowerCase();
  
    setSearchTerm?.(searchTerm);
  
    if (searchTerm === '' && setQueryData) {
      setQueryData((prevState) => ({
        ...prevState,
        searchTerm: '',
        pageNumber: 1,
      }));
    }
  };
  
  const handleKeyDown = async (event: { key: string; preventDefault: () => void; }) => {
    if (event.key === 'Enter' && value && setQueryData) {
      event.preventDefault();
      try {
        setQueryData((prevState) => ({
          ...prevState,
          searchTerm: value,
          pageNumber: 1,
        }));
      } catch (err) {
        console.log('Error fetching search result', err);
      }
    }
  };
  
  return (
    <Box width="100%" padding={1}>
      <Tooltip
        title={
          <Typography fontSize={10}  fontWeight={'bold'} fontFamily='helvetica'>
            Type to search and press Enter
        </Typography>
        }
        open={tooltipOpen}
        placement="right-start"
        arrow
      >
        <TextField
          label={label}
          variant={variant}
          size={size}
          value={value}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          InputProps={{
            startAdornment: (
              <InputAdornment position={inputAdornmentPosition}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          margin={margin}
        />
      </Tooltip>
    </Box>
  );
};
export default TableSearchBar;