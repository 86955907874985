import { createSlice } from '@reduxjs/toolkit';

const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clientID: null,
    patient: null,
  },
  reducers: {
    // Client reducers
    setClientID: (state, action) => {
      state.clientID = action.payload;
    },

    // Patient reducers
    setPatient: (state, action) => {
      state.patient = action.payload;
    },
    updatePatient: (state, action) => {
      state.patient = { ...state.patient, ...action.payload };
    },
    clearPatient: (state) => {
      state.patient = null;
    },
  },
});

export const { setClientID, setPatient, updatePatient, clearPatient } =
  clientSlice.actions;
export default clientSlice.reducer;
