import {Label} from '@mui/icons-material'
import {style, width} from '@mui/system'
import dayjs from 'dayjs'

export const NEW_RECORD_INPUTS = [
  {sectionHeading: 'Vital Signs', rowNumber: 5},
  {
    label: 'BP',
    name: 'bp',
    type: 'text',
    required: true,
    rowNumber: 6,
  },
  {
    label: 'Pulse',
    name: 'pulse',
    type: 'text',
    required: true,
    rowNumber: 6,
  },
  {
    label: 'RR',
    name: 'rr',
    type: 'text',
    required: true,
    rowNumber: 6,
  },
  {
    label: 'Temp',
    name: 'temp',
    type: 'text',
    required: true,
    rowNumber: 7,
  },
  {
    label: 'O2SAT',
    name: 'o2sat',
    type: 'text',
    required: true,
    rowNumber: 7,
  },
  {
    label: 'Pain',
    name: 'pain',
    type: 'text',
    required: true,
    rowNumber: 7,
  },

  {sectionHeading: 'Assessment', rowNumber: 9},
  {
    label: 'Neuro',
    name: 'neuro',
    type: 'textarea',
    // required: true,
    minRows: 3,
    rowNumber: 9,
  },
  {
    label: 'Skin',
    name: 'skin',
    type: 'textarea',
    // required: true,
    minRows: 3,
    rowNumber: 9,
  },
  {
    label: 'HEENT',
    name: 'heent',
    type: 'textarea',
    // required: true,
    minRows: 3,
    rowNumber: 10,
  },

  {
    label: 'Respiratory',
    name: 'respiratory',
    type: 'textarea',
    // required: true,
    minRows: 3,
    rowNumber: 11,
  },
  {
    label: 'Cardiac',
    name: 'cardiac',
    type: 'textarea',
    // required: true,
    minRows: 3,
    rowNumber: 11,
  },

  {sectionHeading: 'GI', rowNumber: 14},

  {
    label: 'Last BM',
    name: 'lastBm',
    type: 'number',
    // required: true,
    rowNumber: 15,
  },
  {
    label: 'Weight(lbs)',
    name: 'value',
    type: 'number',
    rowNumber: 15,
  },
  {
    label: 'Type',
    name: 'type',
    type: 'select',
    options: [
      {label: 'Gain', value: 'gain'},
      {label: 'Loss', value: 'loss'},
    ],
    // required: true,
    rowNumber: 15,
  },
  {
    label: 'GU (Genitourinary)',
    name: 'gu',
    type: 'text',
    rowNumber: 17,
  },

  {sectionHeading: 'Visit Notes', rowNumber: 18},

  {
    label: 'Patient Concerns',
    name: 'patientConcerns',
    type: 'textarea',
    // required: true,
    minRows: 4,
    rowNumber: 19,
  },
  {
    label: 'Notes',
    name: 'notes',
    type: 'textarea',
    // required: true,
    minRows: 4,
    rowNumber: 19,
  },
  {
    label: 'DME',
    name: 'dme',
    type: 'textarea',
    // required: true,
    minRows: 2,
    rowNumber: 20,
  },

  {
    sectionHeading: 'Next Visit',
    rowNumber: 25,
  },
  {
    // label: 'Next Appt',
    name: 'nextAppt',
    type: 'date',
    minDate: dayjs().toDate(),
    readOnly: true,
    // required: true,
    rowNumber: 26,
  },
]

export const NEW_RECORD_FORM_INPUTS = {
  bp: '',
  pulse: '',
  rr: '',
  temp: '',
  o2sat: '',
  pain: '',
  therapy: [],
  neuro: '',
  skin: '',
  heent: '',
  respiratory: '',
  cardiac: '',
  falls: '',
  mobility: '',
  lastBm: '',
  value: '',
  select: '',
  gu: '',
  patientConcerns: '',
  notes: '',
  dme: '',
  medRec: '',
  pocUpdated: '',
  familyUpdated: '',
  nextAppt: '',
}
