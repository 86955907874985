import {useEffect, useState} from 'react'
import '../../../../../../_helper/tableHeaderSticky/tableHeaderSticky.css'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import {deleteIncidentReport, getIncidentReport, getSearchIncidentReport} from '../store/action'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import {
  LIST_OF_CLIENT_LOG_COLUMNS_DATA_MAPPING,
  LIST_OF_CLIENT_LOG_COLUMNS,
  LIST_OF_CLIENT_LOG_SORTING_COLUMNS,
} from '../../care_table_constants'
import {hasPermission} from '../../../../../../_helper/hasPermission'
import {permissions} from '../../../../../../_constants/permissions'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import AddButton from '../../../../../reusable-components/Buttons/AddButton'
import TableButton from '../../../../../reusable-components/tables/TableButton'
import {MoonLoader} from 'react-spinners'
const selectAuth = (state) => state.auth
const ListOfClientLogs = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const clientID = useSelector((state) => state.client?.clientID)
  const [clientName, setClientName] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [searchTerm, setSearchTerm] = useState('')
  const [deleteID, setDeletedID] = useState('')
  const [queryData, setQueryData] = useState({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 0,
    sortBy: 'clientName',
    sortDir: 'asc',
    searchTerm: '',
  })
  const [isLoading, setIsLoading] = useState(true)
  const auth = useSelector(selectAuth)
  const {patient} = useSelector((state) => state.client);
  const userPermissions = auth.user.roleId
  useEffect(() => {
    const fetchData = async (page, pageSize, sortBy, sortDir) => {
      setIsLoading(true)
      setIsFetchingData(true)
      try {
        const ClientLogsValues = await dispatch(
          getIncidentReport({
            page: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortDir: sortDir,
            searchTerm: queryData.searchTerm,
            clientID: clientID
          })
        )
        console.log(ClientLogsValues)
        const {data, currentPage, totalCounts} = ClientLogsValues.payload.data
        setClientName(data[0]?.clientName)
        setFilteredData(data)
        setQueryData((prevState) => {
          return {
            ...prevState,
            currentPage,
            totalCounts,
          }
        })
      } catch (error) {
        console.error(error)
      } finally {
        setClientName(`${patient?.firstName} ${patient?.lastName}`)
        setIsLoading(false)
        setIsFetchingData(false)
      }
    }
    fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
  }, [
    deleteID,
    queryData.pageNumber,
    queryData.pageSize,
    queryData.sortBy,
    queryData.sortDir,
    queryData.searchTerm,
  ])
  const onDelete = async (row) => {
    try {
      const response = await dispatch(deleteIncidentReport(row._id))
      if (response.payload?.success) {
        toast.success(response.payload.message || 'Success', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setDeletedID(row._id)
        setFilteredData((prevData) => prevData.filter((item) => item._id !== row._id))
        setQueryData((prevState) => {
          return {
            ...prevState,
            searchTerm: '',
            pageNumber: 1,
          }
        })
      } else {
        toast.error(
          response.payload?.response?.data?.message || response.payload?.message || 'Error',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      }
    } catch (error) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  const onUpdate = (log) => {
    history.push(`/admin/care/incident-report/list/${clientID}/${log._id}`)
  }
  const onClickAddButton = () => {
    history.push('/admin/care/incident-report/new')
  }
  const onClickSearchButton = () => {
    history.push('/admin/care/incident-report/search')
  }
  return (
    <div className='card'>
      <div className='card-body'>
        {isLoading ? (
          <div className='overlay d-flex justify-content-center'>
            <MoonLoader color='#9db2fc' size={80} loading={isLoading} />
          </div>
        ) : (
          <>
            <TableTitle title={`Incident Reports Of ${clientName}`}></TableTitle>
            <TableWrapper
              searchField={
                <TableSearchBar
                  label='Search'
                  variant='outlined'
                  size='small'
                  value={searchTerm}
                  setQueryData={setQueryData}
                  setSearchTerm={setSearchTerm}
                  margin='normal'
                  inputAdornmentPosition='start'
                ></TableSearchBar>
              }
              tableButtons={
                <>
                  {!!hasPermission(userPermissions, permissions.admin_care_clientLog_create) && (
                    <AddButton onClick={onClickAddButton} />
                  )}
                  {/* {!!hasPermission(userPermissions, permissions.admin_care_clientLog_search) &&
                                    <TableButton
                                        style={{ marginRight: '4px' }}
                                        variant={'contained'}
                                        color={'primary'}
                                        title='Advanced Search'
                                        onClick={onClickSearchButton}
                                    ></TableButton>
                                } */}
                </>
              }
              dynamicTable={
                <DynamicTable
                  columns={LIST_OF_CLIENT_LOG_COLUMNS}
                  data={filteredData}
                  canUpdate={hasPermission(
                    userPermissions,
                    permissions.admin_care_clientLog_update
                  )}
                  canDelete={hasPermission(
                    userPermissions,
                    permissions.admin_care_clientLog_delete
                  )}
                  columnDataMapping={LIST_OF_CLIENT_LOG_COLUMNS_DATA_MAPPING}
                  onDelete={onDelete}
                  sortingColumns={LIST_OF_CLIENT_LOG_SORTING_COLUMNS}
                  onUpdate={onUpdate}
                  title='Are you sure you want to delete this Incident report?'
                  setQueryData={setQueryData}
                  queryData={queryData}
                  tableLoader={isFetchingData}
                />
              }
            />
          </>
        )}
      </div>
    </div>
  )
}
export default ListOfClientLogs