import React, { useEffect, useState } from "react";
import DynamicTable from "../../reusable-components/tables/DynamicTable";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify'
import TableTitle from "../../reusable-components/tables/TableTitle";
import TableSearchBar from "../../reusable-components/tables/TableSearchBar";
import { hasPermission } from "../../../_helper/hasPermission";
import { permissions } from "../../../_constants/permissions";
import { useSelector } from "react-redux";
import { ROLES_LISTING_COLUMNS_DATA_MAPPING, ROLES_LISTING_COLUMNS, ROLES_LISTING_SORTING_COLUMNS } from "./roles_table_constants";
import TableWrapper from "../../reusable-components/tables/TableWrapper";
import AddButton from "../../reusable-components/Buttons/AddButton";
const RolesListing = () => {
    const history = useHistory();
    const [rolesListing, setRoleListing] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [queryData, setQueryData] = useState({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'name',
        sortDir: 'asc'
    })
    const auth = useSelector((state) => state.auth)
    const userPermissions = auth.user.roleId;
    useEffect(() => {
        const fetchData = async (page, pageSize, sortBy, sortDir) => {
            setIsFetchingData(true)
            try{
             const response = await axios.get(`/roleManagement/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`)
                const { data, currentPage, totalCounts } = response.data;
                    setRoleListing(data);
                    setFilteredData(data)
                    setQueryData((prevState)=> {
                        return {
                            ...prevState,
                            currentPage,
                            totalCounts,
                        }
                    })
            } catch(err){
                console.log(err)
            } finally{
                setIsFetchingData(false)
            }
        }
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir])
    const handleSearchChange = async (event) => {
        event.persist()
        const searchTerm = event.target.value.toLowerCase()
        setSearchTerm(searchTerm)
    
        try{
            const response = await axios.get(`/roleManagement/search?searchTerm=${searchTerm}`)
            setFilteredData(response.data)
        } catch(err) {
            console.log('Error fetching search result', err)
        }
    }
    // Function to handle deletion of a row by its ID
    const onDelete = async (row) => {
        setIsFetchingData(true)
        try {
            const response = await axios.delete(`roleManagement/delete/${row._id}`);
            if (response.status === 200) {
                setFilteredData((prevData) => prevData.filter((item) => item._id !== row._id));
                toast.success("Role deleted successfully!"); // Display success toast
            }
        } catch (error) {
            console.error("Error deleting data:", error);
            toast.error("Error deleting role"); // Display error toast
        } finally{
            setIsFetchingData(false)
        }
    };
    const onUpdate = ({ _id }) => {
        history.push(`/roles/${_id}`)
    };
    const onClickAddButton = () => {
        history.push('/roles/new')
    }
    return (
        <div className="card">
            <div className="card-body">
                <TableTitle title="Roles Management"></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchTerm}
                            handleSearchChange={handleSearchChange}
                            margin="normal"
                            inputAdornmentPosition="start"
                        ></TableSearchBar>
                    }
                    tableButtons={
                        !!hasPermission(userPermissions, permissions.userManagement_roles_create) &&
                        <AddButton
                            onClick={onClickAddButton}
                        />
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={ROLES_LISTING_COLUMNS}
                            data={filteredData}
                            canUpdate={hasPermission(userPermissions, permissions.userManagement_roles_update)}
                            canDelete={hasPermission(userPermissions, permissions.userManagement_roles_delete)}
                            columnDataMapping={ROLES_LISTING_COLUMNS_DATA_MAPPING}
                            sortingColumns={ROLES_LISTING_SORTING_COLUMNS}
                            onDelete={onDelete}
                            onUpdate={onUpdate}
                            title='Are you sure you want to delete this role?'
                            setQueryData={setQueryData}
                            queryData={queryData}
                            tableLoader={isFetchingData}
                        />
                    }
                />
            </div>
        </div>
    );
};
export default RolesListing;

