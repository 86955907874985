import { useEffect, useState } from 'react'
import { RootState } from '../../../../../../setup';
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { deleteSite, getSites } from '../store/action';
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable';
import { useHistory } from 'react-router-dom';
import { LIST_OF_SITE_COLUMNS, LIST_OF_SITE_COLUMN_DATA_MAPPING, LIST_OF_SITE_SORTING_COLUMNS } from '../../GENERAL_TABLE_CONSTANTS';
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar';
import TableTitle from '../../../../../reusable-components/tables/TableTitle';
import { hasPermission } from '../../../../../../_helper/hasPermission';
import { permissions } from '../../../../../../_constants/permissions';
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper';
import AddButton from '../../../../../reusable-components/Buttons/AddButton';
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes';
const selectAuth = (state: RootState) => state.auth;
const List = () => {
    const [filteredData, setFilteredData] = useState<any>([])
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [searchTerm, setSearchTerm] = useState('')
    const [deletedID, setDeletedID] = useState('')
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm : ''
    })
    const [site, setSite] = useState<any[]>([])
    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    useEffect(() => {
        const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            setIsFetchingData(true)
            try {
                const result: any = await dispatch(getSites({page, pageSize, sortBy, sortDir, searchTerm : queryData.searchTerm  }))
                const { data, currentPage, totalCounts } = result.payload.data;
                setSite(data)
                setFilteredData(data)
                setQueryData((prevState)=> {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    }
                })
            }
            catch (err) {
                console.log(err)
            }finally{
                setIsFetchingData(false)
            }
        };
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    }, [deletedID, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])
    const DeleteSite = async (row: { _id: string; }) => {
        setIsFetchingData(true)
        try {
            const response: any = dispatch(deleteSite(row._id))
            if (response.payload?.success) {
                toast.success(response.payload.message || 'Success', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                setDeletedID(row._id)
                setQueryData((prevState)=> {
                    return {
                      ...prevState,
                      searchTerm : '',
                      pageNumber: 1
                    }
                  })
            } else {
                toast.error(response.payload?.response?.data?.message || response.payload?.message || 'Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        } catch (error) {
            toast.error('Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        } finally{
            setIsFetchingData(false)
        }
    }
    const onUpdate = ({ _id }: { _id: string }) => {
        history.push(`/admin/general/site/list/${_id}`)
    }
    const onClickAddButton = () => {
        history.push('/admin/general/site/new')
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <div>
                    <TableTitle title='Site List'></TableTitle>
                    <TableWrapper
                        searchField={
                            <TableSearchBar
                                label='Search'
                                variant='outlined'
                                size='small'
                                value={searchTerm}
                                setQueryData={setQueryData}
                                setSearchTerm={setSearchTerm}
                                margin='normal'
                                inputAdornmentPosition='start'
                            ></TableSearchBar>
                        }
                        tableButtons={
                            !!hasPermission(userPermissions, permissions.admin_billing_site_create) && 
                            <AddButton
                                onClick={onClickAddButton}
                        />
                        }
                        dynamicTable={
                            <DynamicTable
                            columns={LIST_OF_SITE_COLUMNS}
                            data={filteredData}
                            canUpdate={hasPermission(userPermissions, permissions.admin_billing_site_update)}
                            canDelete={hasPermission(userPermissions, permissions.admin_billing_site_delete)}
                            columnDataMapping={LIST_OF_SITE_COLUMN_DATA_MAPPING}
                            sortingColumns={LIST_OF_SITE_SORTING_COLUMNS}
                            clickableRow={false}
                            onUpdate={onUpdate}
                            onDelete={DeleteSite}
                            title='Are you sure you want to delete the Site'
                            setQueryData={setQueryData}
                            queryData={queryData}
                            tableLoader={isFetchingData}
                        />
                        }
                    />
                    {/* <div className='container' style={{ margin: '0px', padding: '0px' }}>
                        <div className='row' style={{ display: 'flex', width: 'auto', justifyContent: 'space-between' }}>
                            <div className='col-xs-4 col-sm-4 col-md-6' style={{ width: 'auto' }} >
                                <TableSearchBar
                                    label='Search'
                                    variant='outlined'
                                    size='small'
                                    value={searchTerm}
                                    handleSearchChange={handleSearchChange}
                                    margin='normal'
                                    inputAdornmentPosition='start'
                                ></TableSearchBar>
                            </div>
                            <div className='col-xs-8 col-sm-8 col-md-6 text-sm-center' style={{ width: 'auto' }} >
                                <div className='float-md-right'>
                                    {!!hasPermission(userPermissions, permissions.admin_billing_site_create) && <TableButton
                                        variant="contained"
                                        color="primary"
                                        sx={{ float: 'right', marginTop: '1.7rem' }}
                                        onClick={() => history.push(`/admin/general/site/new`)}
                                        title="Add New"
                                    />}
                                </div>
                            </div>
                        </div>
                        <DynamicTable
                            columns={LIST_OF_SITE_COLUMNS}
                            data={filteredData}
                            canUpdate={hasPermission(userPermissions, permissions.admin_billing_site_update)}
                            canDelete={hasPermission(userPermissions, permissions.admin_billing_site_delete)}
                            columnDataMapping={LIST_OF_SITE_COLUMN_DATA_MAPPING}
                            sortingColumns={LIST_OF_SITE_SORTING_COLUMNS}
                            clickableRow={false}
                            onUpdate={onUpdate}
                            onDelete={DeleteSite}
                            title='Are you sure you want to delete the Site'
                        />
                        </div> */}
                </div>
            </div>
        </div>
    )
}
export default List