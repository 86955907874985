import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import InsuranceClients from '../../../../../pages/admin/care/insurance/list/InsuranceClients'
import MainInsuranceForm from '../../../../../pages/admin/care/insurance/Main Insurance Form/New'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'

const InsuranceIndexRoutes: React.FC = () => {
  const {auth, client} = useSelector((state: {auth: any, client: any}) => state)
    const userPermissions = auth.user.roleId;
    const canUpdate = hasPermission(userPermissions, 'ADMIN.CARE.INSURANCE.UPDATE');
    const canCreate = hasPermission(userPermissions, 'ADMIN.CARE.INSURANCE.CREATE');
    const canRead = hasPermission(userPermissions, 'ADMIN.CARE.INSURANCE.VIEW');

    const getDefaultPath = () => {
        if (canUpdate) return `/admin/care/insurance/${client.clientID}`
        return '/error/404'
      }

    return (
        <Switch>
        <Route exact path='/admin/care/insurance'>
          <Redirect to={getDefaultPath()} />
        </Route>
            <RouteWithPermission hasPerm={canUpdate} path='/admin/care/insurance/:id' component={MainInsuranceForm} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default InsuranceIndexRoutes
