import {useDispatch, useSelector} from 'react-redux'
import {Fragment, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {QueryData} from '../../../reusable-components/tables/DynamicTableTypes'
import {deleteNursesNotes, getNursesNotes} from './store/action'
import {toast} from 'react-toastify'
import TableTitle from '../../../reusable-components/tables/TableTitle'
import TableWrapper from '../../../reusable-components/tables/TableWrapper'
import TableSearchBar from '../../../reusable-components/tables/TableSearchBar'
import {hasPermission} from '../../../../_helper/hasPermission'
import {permissions} from '../../../../_constants/permissions'
import DynamicTable from '../../../reusable-components/tables/DynamicTable'
import AddButton from '../../../reusable-components/Buttons/AddButton'
import {
  RECORDS_COLUMNS_DATA_MAPPING,
  RECORDS_LISTING_COLUMNS,
  RECORDS_SORTING_COLUMNS,
} from './GENERAL_TABLE_CONSTANTS'
import axios from 'axios'
import dayjs from 'dayjs'
const selectAuth = (state: RootState) => state.auth
export const ListNotes = () => {
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [fullData, setFullData] = useState<any[]>([])
  const [deletedID, setDeletedID] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [loadingStates, setLoadingStates] = useState({})
  const [isFetchingData, setIsFetchingData] = useState(true)
  const dispatch = useDispatch()
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId
  const history = useHistory()
  const [queryData, setQueryData] = useState<QueryData>({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
    sortBy: 'date',
    sortDir: 'asc',
    searchTerm: '',
  })
  useEffect(() => {
    const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
      setIsFetchingData(true)
      try {
        const result: any = await dispatch(
          getNursesNotes({
            page: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortDir:sortDir,
            searchTerm: queryData.searchTerm,
          })
        )
        const {data, currentPage, totalCounts} = result?.payload
        setFullData(data)
        const mappedData = data.map((item) => {
          const adjustedStartDate = new Date(item.startDate)
          adjustedStartDate.setDate(adjustedStartDate.getDate())
          const adjustedEndDate = new Date(item.endDate)
          adjustedEndDate.setDate(adjustedEndDate.getDate())
          const formattedStartDate = `${(adjustedStartDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${adjustedStartDate
            .getDate()
            .toString()
            .padStart(2, '0')}-${adjustedStartDate.getFullYear().toString().slice(-2)}`
          const formattedEndDate = `${(adjustedEndDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${adjustedEndDate
            .getDate()
            .toString()
            .padStart(2, '0')}-${adjustedEndDate.getFullYear().toString().slice(-2)}`
          const dateRange = `${formattedStartDate} to ${formattedEndDate}`
          return {
            _id: item._id,
            dateRange,
            frequency: item.frequency,
            visitType: item.visitType,
            clientName: item.clientFullName || 'N/A',
            date: item.date,
          }
        })
        setFilteredData(mappedData)
        setQueryData((prevState) => {
          return {
            ...prevState,
            currentPage,
            totalCounts,
          }
        })
      } catch (err) {
        console.error('Error fetching data:', err)
      } finally {
        setIsFetchingData(false)
      }
    }
    fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
  }, [
    deletedID,
    queryData.pageNumber,
    queryData.pageSize,
    queryData.sortBy,
    queryData.sortDir,
    queryData.searchTerm,
  ])
  const DeleteNote = async (row: {_id: string}) => {
    try {
      const response: any = await dispatch(deleteNursesNotes(row._id))
      if (response.payload?.success) {
        toast.success('Deleted')
        setDeletedID(row._id)
        setQueryData((prevState) => ({
          ...prevState,
          searchTerm: '',
          pageNumber: 1,
        }))
      } else {
        toast.error('Error')
      }
    } catch (error) {
      console.error('Error deleting note:', error)
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  const setLoading = (id, action, isLoading) => {
    setLoadingStates((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [action]: isLoading,
      },
    }))
  }
  const onDownload = async (row) => {
    try {
      setLoading(row._id, 'download', true)
      const filteredRowData = fullData?.find((item) => item._id === row._id)
      if (!filteredRowData) {
        console.error('No data found for this row')
        setLoading(row._id, 'download', false)
        return
      }
      const formattedNextAppt = filteredRowData?.assessment?.nextAppt
        ? dayjs(filteredRowData.assessment.nextAppt).format('MM-DD-YYYY')
        : null
      const formData = {
        ...filteredRowData,
        // painAssessment:  filteredRowData?.painAssessment,
        painAssessment: Array.isArray(filteredRowData?.painAssessment)
          ? filteredRowData.painAssessment.map((item) => ({
              location: item?.location || 'No details provided',
              duration: item?.duration || 'No details provided',
              score: item?.score || 'No details provided',
              description: item?.description || 'No details provided',
              frequency: item?.frequency || 'No details provided',
            }))
          : [],
        activity: Array.isArray(filteredRowData?.activity)
          ? filteredRowData.activity.map((item) => ({
              details: item?.details || 'No details provided',
            }))
          : [],
        poc: Array.isArray(filteredRowData?.poc)
          ? filteredRowData.poc.map((item) => ({
              pocDesc: item?.pocDesc || 'No details provided',
            }))
          : [],
        vitalSigns: {
          bp: filteredRowData?.vitalSigns?.bp,
          pulse: filteredRowData?.vitalSigns?.pulse,
          rr: filteredRowData?.vitalSigns?.rr,
          temp: filteredRowData?.vitalSigns?.temp,
          pain: filteredRowData?.vitalSigns?.pain,
          o2sat: filteredRowData?.vitalSigns?.o2sat,
        },
        assessment: {
          neuro: filteredRowData?.assessment?.neuro,
          skin: filteredRowData?.assessment?.skin,
          heent: filteredRowData?.assessment?.heent,
          respiratory: filteredRowData?.assessment?.respiratory,
          cardiac: filteredRowData?.assessment?.cardiac,
          gi: {
            lastBm: filteredRowData?.assessment?.gi?.lastBm,
            weight: {
              value: filteredRowData?.assessment?.gi?.weight?.value,
              type: filteredRowData?.assessment?.gi?.weight?.type,
            },
          },
          gu: filteredRowData?.assessment?.gu,
          patientConcerns: filteredRowData?.assessment?.patientConcerns,
          notes: filteredRowData?.assessment?.notes,
          dme: filteredRowData?.assessment?.dme,
          medRec: filteredRowData?.assessment?.medRec,
          nextAppt: formattedNextAppt,
        },
      }
      const response = await axios.post('/nursesNotes/generateNursesNotesPDF', formData, {
        responseType: 'blob',
      })
      const blob = new Blob([response.data], {type: 'application/pdf'})
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Notes_Report_For_${filteredRowData.clientFullName}.pdf`)
      document.body.appendChild(link)
      link.click()
      setLoading(row._id, 'download', false)
    } catch (err) {
      console.error('Error while downloading note', err)
      setLoading(row._id, 'download', false)
    }
  }
  const onUpdate = ({_id}: {_id: string}) => {
    history.push({
      pathname: `/notes/nurses-notes/edit/${_id}`,
    })
  }
  const onClickAddButton = () => {
    history.push('/notes/nurses-notes/new')
  }
  const onView = (row: any) => {
    history.push(`/notes/nurses-notes/${row['_id']}`)
  }
  return (
    <div className='card'>
      <div className='card-body'>
        <div>
          <TableTitle title='Nurses/Therapy Notes' />
          <TableWrapper
            searchField={
              <TableSearchBar
                label='Search'
                variant='outlined'
                size='small'
                value={searchTerm}
                setQueryData={setQueryData}
                setSearchTerm={setSearchTerm}
                margin='normal'
                inputAdornmentPosition='start'
              />
            }
            tableButtons={
              !!hasPermission(userPermissions, permissions.notes_nurses_note_create) && (
                <AddButton onClick={onClickAddButton} />
              )
            }
            dynamicTable={
              <DynamicTable
                htmlColumns={[]}
                columns={RECORDS_LISTING_COLUMNS}
                data={filteredData}
                canDownload={hasPermission(userPermissions, permissions.notes_nurses_note_download)}
                canUpdate={hasPermission(userPermissions, permissions.notes_nurses_note_update)}
                canDelete={hasPermission(userPermissions, permissions.notes_nurses_note_delete)}
                columnDataMapping={RECORDS_COLUMNS_DATA_MAPPING}
                sortingColumns={RECORDS_SORTING_COLUMNS}
                clickableRow={false}
                onUpdate={onUpdate}
                onDelete={DeleteNote}
                onDownload={onDownload}
                loadingStates={loadingStates}
                tableLoader={isFetchingData}
                title='Are you sure you want to delete the note'
                setQueryData={setQueryData}
                queryData={queryData}
              />
            }
          />
        </div>
      </div>
    </div>
  )
}
