import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Box} from '@mui/system'
import axios from 'axios'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {Autocomplete, TextField, Chip} from '@mui/material'
import {toast} from 'react-toastify'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const useStyles = makeStyles({
  card: {
    maxWidth: '100%',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  content: {
    padding: '0rem',
  },
})

const selectAuth = (state) => state.auth

export default function AddNewClient() {
  const [selectedClient, setSelectedClient] = useState(null)
  const [clientData, setClientData] = useState([])
  const auth = useSelector(selectAuth)
  const classes = useStyles()
  const [clients, setClients] = useState([])
  const [replacements, setReplacements] = useState([])
  const [targets, setTargets] = useState([])
  const [targetBehaviour, setTargetBehaviour] = useState([null])
  const [replacementBehaviour, setReplacementBehaviour] = useState([[]])
  const [queryData, setQueryData] = useState({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
  })
  const history = useHistory()
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
  const checkedIcon = <CheckBoxIcon fontSize='small' />

  useEffect(() => {
    const fetchData = async (page, pageSize) => {
      try {
        const [replacementResponse, targetResponse] = await Promise.all([
          axios.get(`/options?type=REPLACEMENT_BEHAVIOR`),
          axios.get(`/options?type=TARGET_BEHAVIOR`),
        ])
        const {data: replacementData} = replacementResponse.data
        const {data: targetData} = targetResponse.data
        const response = await axios.get('/clients/getClientNames')
        const {data} = response?.data
        const updatedclients = [
          ...data.map((client) => ({label: client.fullName, value: client._id})),
        ]
        setClients(updatedclients)
        setReplacements(replacementData.map((item) => ({value: item._id, label: item.name})))
        setTargets(targetData.map((item) => ({value: item._id, label: item.name})))
      } catch (error) {
        console.error(error)
      }
    }
    fetchData(queryData.pageNumber, queryData.pageSize)
  }, [queryData.pageNumber, queryData.pageSize])

  const handleSubmit = async () => {
    try {
      const filteredTargets = targetBehaviour
        .map((target) => {
          const foundIndex = targets.findIndex((t) => t.label === target)
          if (foundIndex !== -1) {
            return targets[foundIndex].value
          }
          return undefined
        })
        .filter((f) => f !== undefined)

      const obj = {
        clientId: clients.filter((client) => client.label === selectedClient)[0].value,
        behaviorsMapping: filteredTargets.map((targets, index) => ({
          targetId: targets,
          replacementIds: replacementBehaviour[index]
            .map((replacement) => {
              const foundIndex = replacements.findIndex((t) => t.label === replacement)
              if (foundIndex !== -1) {
                return replacements[foundIndex].value
              }
              return undefined
            })
            .filter((f) => f !== undefined),
        })),
      }
      const response = await axios.post('/behaviors', obj)
      if (response.data.status === false) {
        toast.error(response.data.error)
      } else {
        toast.success(response.data.message)
        history.push('/behaviour/client')
      }
    } catch (error) {
      console.error(error)
    }
  }
  const handleClose = () => {
    history.push('/behaviour/client')
  }
  return (
    <Card className={classes.card}>
      <CardContent
        sx={{
          ...classes.content,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography
          variant='h3'
          sx={{paddingTop: '1rem', paddingBottom: '1rem', paddingLeft: '1rem'}}
          fontSize={20}
          fontWeight={'bold'}
        >
          Add Client's Behavior
        </Typography>
        <FormControl style={{display: 'flex', width: '100%', paddingLeft: '1rem',  paddingRight: '1rem'}}>
          <Autocomplete
            id='client-autocomplete'
            options={clients}
            getOptionLabel={(option) => option.label}
            value={clients.find((client) => client.label === selectedClient) || null}
            onChange={(event, newValue) => {
              setSelectedClient(newValue ? newValue.label : null)
            }}
            renderInput={(params) => (
              <TextField {...params} label='Client' variant='outlined' size='small' />
            )}
            size='small'
          />
        </FormControl>
        {targetBehaviour.map((target, index) => (
          <Box key={target + index} sx={{paddingLeft: '1rem', paddingRight: '1rem' }}>
            <Typography variant='h6' fontSize={16} fontWeight={'bold'} sx={{marginBottom: 1}}>
              Behavior {index + 1}
            </Typography>
            <Box
              key={target + index}
              sx={{display: 'flex', justifyContent: 'space-between', gap: 2}}
            >
              <FormControl style={{display: 'flex', width: '100%'}}>
                <Autocomplete
                  id='target-autocomplete'
                  options={targets.filter(targetOption => !targetBehaviour.includes(targetOption.label))}
                  getOptionLabel={(option) => option.label}
                  value={targets.find((target) => target.label === targetBehaviour[index]) || null}
                  onChange={(event, newValue) => {
                    let dummytarget = [...targetBehaviour]
                    dummytarget[index] = newValue ? newValue.label : ''
                    setTargetBehaviour([...dummytarget])
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Target Behaviour'
                      variant='outlined'
                      size='small'
                    />
                  )}
                  size='small'
                />
              </FormControl>
              <FormControl style={{display: 'flex', width: '100%'}}>
                <Autocomplete
                  multiple
                  id='replacement-autocomplete'
                  options={replacements}
                  disableCloseOnSelect
                  forcePopupIcon={true}
                  getOptionLabel={(option) => option.label}
                  value={replacements.filter((replacement) =>
                    replacementBehaviour[index]?.includes(replacement.label)
                  )}
                  onChange={(event, newValue) => {
                    const dummyReplacementBehavior = [...replacementBehaviour]
                    dummyReplacementBehavior[index] = newValue.map((option) => option.label)
                    setReplacementBehaviour([...dummyReplacementBehavior])
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Replacement Behaviours'
                      variant='outlined'
                      size='small'
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option.label} {...getTagProps({index})} />
                    ))
                  }
                  size='small'
                />
              </FormControl>
              <Button
                variant='contained'
                size='small'
                sx={{
                  display:'flex',
                  alignSelf:'center',
                  backgroundColor: '#D9214E',
                  borderColor: '#D9214E',
                  '&:hover': {
                    backgroundColor: '#C21C47',
                  },
                }}
                disabled={targetBehaviour.length === 1}
                onClick={() => {
                  let dummytarget = [...targetBehaviour]
                  let dummyReplacementBehavior = [...replacementBehaviour]
                  dummytarget.splice(index, 1)
                  dummyReplacementBehavior.splice(index, 1)
                  setTargetBehaviour([...dummytarget])
                  setReplacementBehaviour([...dummyReplacementBehavior])
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        ))}
        <Box sx={{display: 'flex', gap: 1, marginTop: '5px', paddingLeft: '1rem',  paddingRight: '1rem'}}>
          <Button
            variant='contained'
            disabled={targetBehaviour.length >= targets.length}
            onClick={() => {
              let dummytarget = [...targetBehaviour]
              const dummyReplacementBehavior = [...replacementBehaviour]
              dummyReplacementBehavior.push([])
              dummytarget.push('')
              setReplacementBehaviour([...dummyReplacementBehavior])
              setTargetBehaviour([...dummytarget])
            }}
          >
            Add New
          </Button>
          <Button variant='contained' onClick={handleSubmit}>
            Save
          </Button>
          <Button variant='contained' onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}
