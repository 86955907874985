import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import IndividualListing from '../../../../../pages/admin/care/enrollment/byIndividual/IndividualListing'
import IndividualProgramList from '../../../../../pages/admin/care/enrollment/individualListing/IndividualProgramList'
import {useSelector} from 'react-redux'
import {hasPermission} from '../../../../../../_helper/hasPermission'
import {RouteWithPermission} from '../../../../../../_helper/routesWithPermission'
import {Error404} from '../../../../../modules/errors/components/Error404'
import {permissions} from '../../../../../../_constants/permissions'

const EnrollmentIndexRoutes: React.FC = () => {
  const {auth, client} = useSelector((state: {auth: any, client: any}) => state)
  const userPermissions = auth.user.roleId

  const canUpdate_Individual = hasPermission(
    userPermissions,
    permissions.admin_care_enrollment_byIndividual_update
  )
  const enrollment = hasPermission(userPermissions, permissions.admin_care_enrollment_view)
  const getDefaultPath = () => {
    if (canUpdate_Individual) return `/admin/care/enrollment/by-client/${client.clientID}`
    return '/error/404'
  }
  return (
    <Switch>
      <Route exact path='/admin/care/enrollment'>
        <Redirect to={getDefaultPath()} />
      </Route>
      <RouteWithPermission
        path='/admin/care/enrollment/by-client/:clientID'
        component={IndividualProgramList}
        hasPerm={enrollment && canUpdate_Individual}
      />
      <RouteWithPermission
        path='/admin/care/enrollment/by-client'
        component={IndividualListing}
        hasPerm={enrollment}
      />
      <Route path='/error/404' component={Error404} />
      {/* <Route path='/admin/care/enrollment/programindividual/' component={ProgramIndividuals} /> */}
      {/*  <Route path='/admin/care/enrollment/individualsprogram/*' component={IndividualProgramList} /> */}
    </Switch>
  )
}

export default EnrollmentIndexRoutes
