import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {reduxBatch} from '@manaflair/redux-batch'
import {rootReducer} from './RootReducer'
import { setClientID, setPatient } from '../../app/pages/admin/care/individual-intake/store'

const sagaMiddleware = createSagaMiddleware()
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  sagaMiddleware,
]

const store: any = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
})

const patientData = JSON.parse(localStorage.getItem('patientData'));
const clientID = JSON.parse(localStorage.getItem('clientID'));
if (patientData && clientID) {
  store.dispatch(setPatient(patientData));
  store.dispatch(setClientID(clientID));
}
export type AppDispatch = typeof store.dispatch

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
// export const persistor = persistStore(store)

// sagaMiddleware.run(rootSaga)

export default store
