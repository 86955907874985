import { useState, useEffect } from 'react'
import axios from 'axios'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import { ALLERGY_INPUTS, NEW_ALLERGY } from '../CARE_CONSTANTS'
import { useHistory, useParams } from 'react-router-dom'
import AdminCareHeader from '../AdminCareHeader'
import HealthProfileMenu from '../HealthProfile/HealthProfileMenu'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setPatient } from '../individual-intake/store'

const AddAllergyInfo = () => {
    const [client, setClient] = useState()
    const parameters = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchData = async () => {
            if (parameters.clientID) {
                const [ClientsData] = await Promise.all([
                    axios.get('/clients/getOne/' + parameters.clientID),
                ])
                setClient(ClientsData.data)
            } else {
                history.push('/admin/care/health/list')
            }
        }
        fetchData()
    }, [])

    const handleSubmitForm = async (formData) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        // Create the formatted date string
        const currentDateFormatted = `${year}-${month}-${day}`;
        try {
            const tempObj = {
                allergy: formData.allergy,
                allergyState: formData.allergyState,
                description: formData.description,
                identificationDate: formData.identificationDate,
                reaction: formData.reaction,
                severity: formData.severity
            }
            if(formData.allergy !== '' ) {
                 if (formData.identificationDate <= currentDateFormatted) {
                    const response = await axios.put(`/clients/update/allergyInfo/${parameters.clientID}`, tempObj)
                    if(response?.status === 400 && response?.data?.success === false) {
                        toast.error(response?.data?.message)
                    } else if (response.status === 200 && response.data && response.data.success === true) {
                    toast.success('Allergy Added Successfully')
                    dispatch(setPatient(response.data.data));
                    localStorage.setItem('patientData', JSON.stringify(response?.data?.data));
                    history.push(`/admin/care/health/allergy/list/${parameters.clientID}`)
                } else {
                    toast.error('Error Adding Allergy')
                }
                } else {
                    toast.error('Identification Date should not be later than today')
                }
            }

        } catch (err) {
            console.log(err)
            toast.error('Error Adding Allergy')
        }

    };

    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];
    return (
        <>
            <AdminCareHeader />
            <HealthProfileMenu />
            <div className='card my-10'>
                <div className='card-body'>
                    <h2 className='mt-6 mb-5'>New Allergy</h2>
                    <div className="mt-3">
                        <DynamicForm
                            fields={ALLERGY_INPUTS}
                            data={NEW_ALLERGY}
                            onSubmit={handleSubmitForm}
                            buttons={buttons}
                        />
                    </div>
                </div>
            </div>
        </>

    )
}

export default AddAllergyInfo