import { FC, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../helpers";
import { Logout } from "../../../../app/modules/auth";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";

const HeaderUserMenu: FC = () => {
  const selectAuth = (state: { auth: any }) => state.auth;
  const auth = useSelector(selectAuth);
  const firstName = auth?.user?.firstName;
  const lastName = auth?.user?.lastName;
  const email = auth?.user?.email;
  const profileImgKey = auth.user.profileImg; 
  const defaultAvatar = toAbsoluteUrl("/media/avatars/default-avatar.jpg");

  const [profileUrl, setProfileUrl] = useState<string>(defaultAvatar); 
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const history = useHistory();

  const fetchPresignedUrl = async (key: string) => {
    try {
      const response = await axios.get(`/batchfile/fetch-file-url`, {
        params: { fileKey:key },
      });
      if (response.status === 200) {
        setProfileUrl(response.data.presignedUrl); 
      }
    } catch (error) {
      console.error("Error fetching presigned URL:", error);
    }
  };

  useEffect(() => {
    if (profileImgKey) {
      if (!profileImgKey.startsWith('http')) {
        fetchPresignedUrl(profileImgKey);
      } else {
        setProfileUrl(profileImgKey);
      }
    }
  }, [profileImgKey]);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleImageChange = (e: any) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setSelectedImage(img);
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("email", auth.user.email);
    formData.append("profileImg", selectedImage.data);
  };
  return (
    <div>
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Profile Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="file" onChange={handleImageChange} />
            {selectedImage && (
              <img
                src={selectedImage.preview}
                alt="Profile Preview"
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  borderRadius: "8px",
                }}
              />
            )}
            {selectedImage && (
              <Button
                type="submit"
                onClick={closeModal}
                variant="success"
                style={{ marginTop: "20px" }}
              >
                Submit
              </Button>
            )}
          </form>
        </Modal.Body>
      </Modal>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          backgroundColor: "#ffffff",
          padding: "1.5rem",
          fontSize: "1rem",
          width: "320px",
          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)",
        }}
        data-kt-menu="true"
      >
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div
              className="symbol me-4"
              style={{
                width: "65px",
                height: "65px",
                borderRadius: "50%",
                overflow: "hidden",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
                border: "2px solid #e0e0e0",
              }}
            >
              <img
                alt="Profile"
                src={profileUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>

            <div
              className="d-flex flex-column"
              style={{ marginLeft: "0.5rem" }}
            >
              <span
                className="fw-bold"
                style={{
                  color: "#333",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                }}
              >
                {firstName} {lastName}
              </span>
              <span
                className="text-muted"
                style={{ fontSize: "0.9rem", color: "#666" }}
              >
                {email}
              </span>
            </div>
          </div>
        </div>

        <div
          className="separator my-3"
          style={{ borderTop: "1px solid #e0e0e0" }}
        ></div>

        <div className="menu-item px-5 my-2">
          <Link
            to="/crafted/account/settings"
            className="menu-link px-5"
            style={{ color: "#007bff" }}
          >
            Account Settings
          </Link>
        </div>

        <div
          className="separator my-2"
          style={{ borderTop: "1px solid #e0e0e0" }}
        ></div>

        <div className="menu-item px-5">
          <a
            className="menu-link px-5"
            onClick={() => history.push("/mfa")}
            style={{ color: "#007bff" }}
          >
            MFA
          </a>
        </div>

        <div
          className="separator my-2"
          style={{ borderTop: "1px solid #e0e0e0" }}
        ></div>

        <div className="menu-item px-5">
          <a
            onClick={() => Logout()}
            className="menu-link px-5"
            style={{ color: "#007bff" }}
          >
            Sign Out
          </a>
        </div>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
