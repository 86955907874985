import axios from "axios";
import { useEffect, useState, Fragment } from "react";
import { useLocation } from "react-router-dom";
import DynamicTable from "../tables/DynamicTable";
import moment from "moment";
import TableWrapper from "../../reusable-components/tables/TableWrapper";
import TableSearchBar from "../../reusable-components/tables/TableSearchBar";
import TableTitle from "../tables/TableTitle";
import { toast } from "react-toastify";

const DESCRIPTION_TABLE_COLUMNS = ["Client Name", "Description", "Date", "Timestamp"];
const DESCRIPTION_TABLE_SORTING_COLUMNS = {
  "Client Name": "clientFullName",
  "Description": "description",
  "Date": "date",
  "Timestamp": "timestamp",
};
const DESCRIPTION_TABLE_COLUMN_DATA_MAPPING = {
  "Client Name": "clientFullName",
  "Description": "description",
  "Date": "date",
  "Timestamp": "timestamp",
};

const DescriptionTable = () => {
  const location = useLocation();
  const [editHistory, setEditHistory] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [queryData, setQueryData] = useState({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
    sortBy: "timestamp",
    sortDir: "asc",
  });

  const clientId = new URLSearchParams(location.search).get("clientId");
  const section = new URLSearchParams(location.search).get("section");

  useEffect(() => {
    const loadEditHistory = async () => {
      try {
        setLoading(true);

        if (!clientId) {
          throw new Error("Client ID is missing in the URL.");
        }

        if (!section) {
          throw new Error("Section is missing in the URL.");
        }

        const response = await axios.get("/edit-histories", {
          params: {
            clientId,
            section, 
            page: queryData.pageNumber,
            pageSize: queryData.pageSize,
            sortBy: queryData.sortBy,
            sortDir: queryData.sortDir,
            searchTerm,
          },
        });

        if (response.data?.success) {
          const { data, currentPage, totalCounts } = response.data;

          const formattedData = data.map((item, index) => ({
            id: index + 1,
            clientFullName: item.clientFullName || "N/A",
            description: item.description || "No description provided",
            date: moment(item.timestamp).format("YYYY-MM-DD"), // Add date column
            timestamp: item.timestamp
              ? moment(item.timestamp).format("YYYY-MM-DD HH:mm:ss")
              : "N/A",
            options: (
              <Fragment>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => alert(`View details for ${item.clientFullName}`)}
                >
                  View
                </button>
              </Fragment>
            ),
          }));

          setEditHistory(formattedData);
          setFilteredData(formattedData);
          setQueryData((prevState) => ({
            ...prevState,
            currentPage,
            totalCounts,
          }));
        } else {
          throw new Error(response.data.message || "Failed to load data.");
        }
      } catch (err) {
        console.error("Error fetching edit history:", err.message || err);
        setError(err.message || "Something went wrong.");
      } finally {
        setLoading(false);
      }
    };

    loadEditHistory();
  }, [
    clientId,
    section,
    queryData.pageNumber,
    queryData.pageSize,
    queryData.sortBy,
    queryData.sortDir,
    searchTerm,
  ]);

  const handleSearchChange = async (event) => {
    event.persist();
    setSearchTerm(event.target.value);
    setQueryData((prevState) => ({
      ...prevState,
      pageNumber: 1,
    }));
  };

  return (
    <div className="card">
      <div className="card-body">
        <TableTitle title="Edit Section" />
        <TableWrapper
          searchField={
            <TableSearchBar
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              setQueryData={setQueryData}
              setSearchTerm={setSearchTerm}
              handleSearchChange={handleSearchChange}
              margin="normal"
              inputAdornmentPosition="start"
            />
          }
          dynamicTable={
            <DynamicTable
              htmlColumns={["Options"]}
              columns={DESCRIPTION_TABLE_COLUMNS}
              data={filteredData}
              columnDataMapping={DESCRIPTION_TABLE_COLUMN_DATA_MAPPING}
              sortingColumns={DESCRIPTION_TABLE_SORTING_COLUMNS}
              queryData={queryData}
              setQueryData={setQueryData}
              title="Edit History"
              loading={loading}
              canAdd={false}
              canDelete={false}
              canUpdate={false}
              canView={false}
            />
          }
        />
      </div>
    </div>
  );
};

export default DescriptionTable;
