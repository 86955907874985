import React from 'react'
import { Switch } from 'react-router-dom'
import { RouteWithPermission } from '../../../../_helper/routesWithPermission'
import Notes from '../../../pages/Notes/Caregiver_Notes/Notes/Notes'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../_helper/hasPermission'
import { permissions } from '../../../../_constants/permissions'
import NewNote from '../../../pages/Notes/Caregiver_Notes/Notes/Add/NewNote'
import EditNote from '../../../pages/Notes/Caregiver_Notes/Notes/Edit/EditNote'

const NotesRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canView = hasPermission(userPermissions, permissions.care_giver_notes_view)
    const canCreate = hasPermission(userPermissions, permissions.care_giver_notes_note_create)
    const canUpdate = hasPermission(userPermissions, permissions.care_giver_notes_note_update)
  return (
    <Switch>
    <RouteWithPermission path='/notes/caregiver-notes/notes/new' component={NewNote} hasPerm={(canView && canCreate)} />
    <RouteWithPermission path='/notes/caregiver-notes/notes/edit/:id' component={EditNote} hasPerm={(canView && canUpdate)} />
        <RouteWithPermission path='/notes/caregiver-notes/notes' component={Notes} hasPerm={canView} />
    </Switch>
  )
}

export default NotesRoutes