/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable */ import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useHistory, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
// import { useUser } from '../../../../_helper/userHooks/userHook'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import io from 'socket.io-client'
import {toast} from 'react-toastify'
import COGNITO from '../../../../aws-exports'
import {storeProviderCodeInLocalStorage, storeTokenInLocalStorage} from '../../../../_helper/userHooks/common'

const initialValues = {
  providerCode: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
export function ProviderCode() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [result, setResult] = useState('')

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    ;(async () => {
      let code: any = location.search.split('=')

      if (code?.length >= 2) {
        code = [code[1]]
      }

      const myHeaders = new Headers()
      myHeaders.set('Content-Type', 'application/x-www-form-urlencoded')

      // Removed the invalid header
      // myHeaders.set('', ''); // This line is causing the error

      const urlencoded = new URLSearchParams()
      urlencoded.set('grant_type', 'authorization_code')
      urlencoded.set('client_id', process.env.REACT_APP_COGNITO_CLIENT_ID)
      urlencoded.set('client_secret', process.env.REACT_APP_COGNITO_CLIENT_SECRET)
      urlencoded.set('code', code)
      urlencoded.set('redirect_uri', process.env.NODE_ENV === 'development' ? 
        process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_LOCAL : 
        process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN)

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow',
      }

      fetch('https://trumemo.auth.us-east-1.amazoncognito.com/oauth2/token', requestOptions as any)
        .then((response) => response.json())
        .then((result) => {
          setResult(result.access_token)
        })
        .catch((error) => console.error(error))
    })()
  }, [])

  useEffect(() => {
    const handleStorageChange = () => {
      if (localStorage.getItem('authToken') && localStorage.getItem('providerCode')) {
        history.push('/dashboard');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        const response = await axios.post(`/users/login`, {
          accessToken: result,
          providerCode: values.providerCode,
        })
        await storeTokenInLocalStorage(response?.data?.token);
        await storeProviderCodeInLocalStorage(values?.providerCode);

       // Check localStorage directly to verify values are set
      const storedAuthToken = localStorage.getItem('authToken');
      const storedProviderCode = localStorage.getItem('providerCode');

      if (storedAuthToken && storedProviderCode) {
        history.push('/dashboard');
        window.location.reload()
      }
      } catch (error) {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      } finally {
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Enter your provider Coder</h1>
      </div>

      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Provider Code</label>
          </div>
        </div>
        <input
          placeholder='Provider Code'
          type='providerCode'
          autoComplete='off'
          {...formik.getFieldProps('providerCode')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.providerCode && formik.errors.providerCode,
            },
            {
              'is-valid': formik.touched.providerCode && !formik.errors.providerCode,
            }
          )}
        />
        {formik.touched.providerCode && formik.errors.providerCode && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger'>
                {formik.errors.providerCode}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      {error != '' ? (
        <div style={{color: 'red', display: 'flex', justifyContent: 'center'}}>{error}</div>
      ) : (
        ''
      )}
      {/* end::Action */}
    </form>
  )
}
