import {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import FormWrapper from '../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import {NEW_RECORD_INPUTS, NEW_RECORD_FORM_INPUTS} from '../GENERAL_CONSTANTS'
import {toast} from 'react-toastify'
import {addNursesNotes} from '../store/action'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Autocomplete,
  Box,
  Typography
} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import axios from 'axios'
import {border, width} from '@mui/system'

const selectAuth = (state) => state.auth

export const NewNotes = () => {
  const [site, setSite] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const auth = useSelector(selectAuth)
  const [uiElements, setUIElements] = useState({})
  const [frequency, setFrequency] = useState('')
  const [visitType, setVisitType] = useState('')
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs())
  const [clients, setClients] = useState([])
  const [selectedClientId, setSelectedClientId] = useState('')
  const [displayDateRange, setDisplayDateRange] = useState('')
  const [therapy, setTherapy] = useState('')
  const [isExperiencingPain, setIsExperiencingPain] = useState('no')
  const [painAssessment, setPainAssessment] = useState([
    {
      location: '',
      score: '',
      duration: '',
      frequency: '',
      description: '',
      isExperiencingPain: false,
    },
  ])
  const [hasExperiencedFall, setHasExperiencedFall] = useState('no')
  const [activity, setActivity] = useState([
    {
      hasExperiencedFall: false,
      details: '',
    },
  ])

  const [pocUpdated, setPocUpdated] = useState('no')
  const [poc, setPoc] = useState([
    {
      pocUpdated: false,
      pocDesc: '',
    },
  ])

  const [familyUpdated, setFamilyUpdated] = useState('no')
  const [familyInformed, setFamilyInformed] = useState([
    {
      familyUpdated: false,
      famDesc: '',
    },
  ])
  useEffect(() => {
    const fetchClients = async () => {
      const providerCode = localStorage.getItem('providerCode')
      try {
        const response = await axios.get(`/clients/getClientNames`)
        const clientData = response.data.data.map((client) => ({
          id: client._id,
          // firstName: client.firstName,
          // lastName: client.lastName,
          fullName: client.fullName,
        }))
        setClients(clientData)
      } catch (error) {
        console.error('Error fetching clients:', error)
      }
    }

    fetchClients()
  }, [])
  const maxDate = dayjs().subtract(1, 'day')
  const minDate = dayjs().subtract(5, 'year')

  const handleStartDateChange = (newStartDate) => {
    if (!newStartDate) return

    if (newStartDate.isAfter(maxDate)) {
      newStartDate = maxDate
    } else if (newStartDate.isBefore(minDate)) {
      newStartDate = minDate
    }

    let calculatedStartDate
    switch (frequency) {
      case 'DAILY':
        calculatedStartDate = newStartDate
        break
      case 'WEEKLY':
        calculatedStartDate = newStartDate.startOf('week')
        break
      case 'MONTHLY':
        calculatedStartDate = newStartDate.startOf('month')
        break
      case 'QUARTERLY':
        const quarterMonth = Math.floor(newStartDate.month() / 3) * 3;
        calculatedStartDate = newStartDate.month(quarterMonth).startOf('month');
        break
      case 'YEARLY':
        calculatedStartDate = newStartDate.startOf('year')
        break
      default:
        calculatedStartDate = null
    }

    if (calculatedStartDate && frequency !== 'WEEKLY') {
      calculatedStartDate = calculatedStartDate.add(1, 'day')
    }

    setStartDate(calculatedStartDate)

    let calculatedEndDate
    switch (frequency) {
      case 'DAILY':
        calculatedEndDate = calculatedStartDate
        break
      case 'WEEKLY':
        calculatedEndDate = calculatedStartDate.add(5, 'days').endOf('day')
        setDisplayDateRange(
          `${calculatedStartDate.format('MM-DD-YY')} - ${calculatedEndDate.format('MM-DD-YY')}`
        )
        break
      case 'MONTHLY':
        calculatedEndDate = calculatedStartDate.endOf('month')
        setDisplayDateRange(
          `01-${calculatedStartDate.format('MMMM')} - ${calculatedEndDate.format('DD-MMMM-YYYY')}`
        )
        break
      case 'QUARTERLY':
        calculatedEndDate = calculatedStartDate.add(2, 'months').endOf('month')
        setDisplayDateRange(
          `${calculatedStartDate.format('MMMM')} - ${calculatedEndDate.format('MMMM-YYYY')}`
        )
        break
      case 'YEARLY':
        calculatedEndDate = calculatedStartDate.endOf('year')
        setDisplayDateRange(
          `${calculatedStartDate.format('MMMM')} - ${calculatedEndDate.format('MMMM')}`
        )
        break
      default:
        calculatedEndDate = null
    }

    setEndDate(calculatedEndDate)
  }

  const formatQuarter = (month) => {
    if (month >= 0 && month <= 2) return 'Q1';
    if (month >= 3 && month <= 5) return 'Q2';
    if (month >= 6 && month <= 8) return 'Q3';
    return 'Q4';
  };

  useEffect(() => {
    setDisplayDateRange('')
  }, [frequency])

  const handleSubmitForm = async (formData) => {
    const selectedClient = clients.find((client) => client.id === selectedClientId)
    const clientFullName = selectedClient ? selectedClient.fullName : ''

    const filteredPainAssessment = painAssessment.filter((painDetail) =>
      Object.values(painDetail).some((value) => value !== '')
    )

    const updatedPainAssessment = filteredPainAssessment.map((painDetail) => ({
      ...painDetail,
      isExperiencingPain: isExperiencingPain === 'yes',
    }))

    const updatedActivity = activity.map((fallDetail) => ({
      ...fallDetail,
      hasExperiencedFall: hasExperiencedFall === 'yes',
    }))

    const updatedPoc = poc.map((pocDetail) => ({
      ...pocDetail,
      pocUpdated: pocUpdated === 'yes',
    }))

    const updatedFamily = familyInformed.map((famDetail) => ({
      ...famDetail,
      familyUpdated: familyUpdated === 'yes',
    }))

    const formattedStartDate = dayjs(formData.startDate).add(1,'day')
    const formattedNextAppt = dayjs(formData.nextAppt)
    if (!formattedStartDate.isValid() || !formattedNextAppt.isValid()) {
      toast.error('Invalid Start Date or Next Appointment')
      return
    }
    const fiveYearsAgo = dayjs().subtract(5, 'years').startOf('day')
    const currentDate = dayjs();
    if (formattedStartDate.isBefore(fiveYearsAgo, 'day')) {
      toast.error('Dates cannot be more than 5 years in the past.')
      return
    }
    if (formattedNextAppt.isBefore(currentDate, 'day')) {
      toast.error('Appointment cannot be in the past.')
      return
    }

    const payload = {
      ...formData,
      frequency,
      therapy,
      visitType,
      startDate: startDate,
      endDate: endDate,
      clientId: selectedClientId,
      clientName: clientFullName,
      painAssessment: updatedPainAssessment,
      isExperiencingPain: isExperiencingPain === 'yes',
      hasExperiencedFall: hasExperiencedFall === 'yes',
      activity: updatedActivity,
      pocUpdated: pocUpdated === 'yes',
      poc: updatedPoc,
      familyInformed: updatedFamily,
      familyUpdated: familyUpdated === 'yes',
      vitalSigns: {
        bp: formData.bp,
        pulse: formData.pulse,
        rr: formData.rr,
        temp: formData.temp,
        pain: formData.pain,
        o2sat: formData.o2sat,
      },

      assessment: {
        neuro: formData.neuro,
        skin: formData.skin,
        heent: formData.heent,
        respiratory: formData.respiratory,
        cardiac: formData.cardiac,
        gi: {
          lastBm: formData.lastBm,
          weight: {
            value: formData.value,
            type: formData.type,
          },
        },
        gu: formData.cardiac,
        patientConcerns: formData.patientConcerns,
        notes: formData.notes,
        dme: formData.dme,
        medRec: formData.medRec,
        nextAppt: formattedNextAppt,
      },
    }

    const resultAction = await dispatch(addNursesNotes(payload))

    if (resultAction.payload.message.includes('therapy')) {
      toast.error('Please select a therapy')

      return
    }

    if (resultAction.payload.message.includes('successfully')) {
      toast.success(resultAction.payload.message)
      history.push('/notes/nurses-notes/list')
    } else {
      const errorMessage =
        resultAction.payload?.message || resultAction.error?.message || 'Failed to save the note.'
      console.error('Failed to save the note:', resultAction.error)
      toast.error(errorMessage)
    }
  }

  const handleAddPainDetail = () => {
    setPainAssessment([
      ...painAssessment,
      {location: '', score: '', duration: '', frequency: '', description: ''},
    ])
  }

  const handlePainDetailChange = (index, field, value) => {
    const newPainDetails = [...painAssessment]
    newPainDetails[index][field] = value
    setPainAssessment(newPainDetails)
  }

  const handleRemovePainDetail = (index) => {
    const newPainDetails = [...painAssessment]
    newPainDetails.splice(index, 1)
    setPainAssessment(newPainDetails)
  }
  const handleFallDetailChange = (index, field, value) => {
    const newFallDetails = [...activity]
    newFallDetails[index][field] = value
    setActivity(newFallDetails)
  }

  const handlePocDetailChange = (index, field, value) => {
    const newPocDetails = [...poc]
    newPocDetails[index][field] = value
    setPoc(newPocDetails)
  }

  const handlefamilyDetailChange = (index, field, value) => {
    const newFamDetails = [...familyInformed]
    newFamDetails[index][field] = value
    setFamilyInformed(newFamDetails)
  }
  const buttons = [
    {label: 'Cancel', variant: 'contained', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit'},
  ]

  return (

    <div className='card'>
    <div className='card-body'>
    <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1.5rem',
          marginLeft: '0.5rem',
          marginRight: '0.5rem',
        }}
      >
        <Typography fontSize={20} fontWeight={'bold'} fontFamily='helvetica'>
          Add New Note
        </Typography>
        </Box>
    <FormWrapper
      content={
        <>
          {frequency === 'WEEKLY' && (
            <div style={{marginLeft: '15px', color: 'red'}}>
              Note: The start date will automatically adjust to Sunday as the beginning of the week.
            </div>
          )}
          <FormControl
            sx={{mb: 2, ml: 1.5, mr: 1, width: '50%'}}
            color='primary'
            fullWidth
            variant='filled'
            required
          >
            <InputLabel id='note-type-select-label'>Select Note Type</InputLabel>
            <Select
              labelId='note-type-select-label'
              value={frequency}
              sx={{background: 'white'}}
              onChange={(e) => {
                setFrequency(e.target.value)
                setEndDate(null)
                setStartDate(null)
              }}
              label='Frequency'
              name='frequency'
            >
              <MenuItem value='DAILY'>Daily</MenuItem>
              <MenuItem value='WEEKLY'>Weekly</MenuItem>
              <MenuItem value='MONTHLY'>Monthly</MenuItem>
              <MenuItem value='QUARTERLY'>Quarterly</MenuItem>
              <MenuItem value='YEARLY'>Yearly</MenuItem>
            </Select>
          </FormControl>

          {frequency && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {frequency === 'MONTHLY' ? (
                <>
                  <DatePicker
                    views={['month', 'year']}
                    sx={{background: 'white'}}
                    label='Select Month'
                    maxDate={dayjs()}
                    minDate={dayjs().subtract(5, 'year')}
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                  />
                  {displayDateRange && (
                    <TextField
                      label='Selected Range'
                      sx={{width: '15%', mt: '0', ml: 1, width:'250px'}}
                      value={displayDateRange}
                      fullWidth
                      margin='normal'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </>
              ) : frequency === 'YEARLY' ? (
                <>
                  <DatePicker
                    views={['year']}
                    sx={{background: 'white'}}
                    label='Select Year'
                    maxDate={dayjs()}
                    minDate={dayjs().subtract(5, 'year')}
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                  />
                  {displayDateRange && (
                    <TextField
                      label='Selected Range'
                      sx={{width: '15%', mt: '0', ml: 1, width:'200px'}}
                      value={displayDateRange}
                      fullWidth
                      margin='normal'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </>
              ) : frequency === 'QUARTERLY' ? (
                <>
                  <DatePicker
                    views={['month', 'year']}
                    sx={{background: 'white'}}
                    label='Select Month'
                    maxDate={dayjs()}
                    minDate={dayjs().subtract(5, 'year')}
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        margin="normal"
                        value={formatQuarter(startDate.month())}
                      />
                    )}
                  />
                  {displayDateRange && (
                    <TextField
                      label='Selected Range'
                      sx={{width: '15%', mt: '0', ml: 1, width:'200px'}}
                      value={displayDateRange}
                      fullWidth
                      margin='normal'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </>
              ) : frequency === 'WEEKLY' ? (
                <>
                  <DatePicker
                    // views={['month', 'year']}
                    sx={{background: 'white'}}
                    label='Select Date'
                    maxDate={dayjs()}
                    minDate={dayjs().subtract(5, 'year')}
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                  />
                  {displayDateRange && (
                    <TextField
                      label='Selected Range'
                      sx={{width: '15%', mt: '0', ml: 1}}
                      value={displayDateRange}
                      fullWidth
                      margin='normal'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <DatePicker
                    label='Start Date'
                    sx={{background: 'white'}}
                    value={startDate}
                    minDate={dayjs().subtract(5, 'year')}
                    maxDate={dayjs()}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                  />

                  <>
                    {displayDateRange && frequency !== 'DAILY' (
                      <TextField
                        label='Selected Range'
                        sx={{width: '15%', mt: '0', ml: 1}}
                        value={displayDateRange}
                        fullWidth
                        margin='normal'
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  </>
                </>
              )}

              {frequency !== 'DAILY' &&
                frequency !== 'WEEKLY' &&
                frequency !== 'QUARTERLY' &&
                frequency !== 'MONTHLY' &&
                frequency !== 'YEARLY' && (
                  <TextField
                    sx={{mb: 2, ml: 1, background: 'white'}}
                    label='End Date'
                    value={endDate ? endDate.format('MM-DD-YYYY') : ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
            </LocalizationProvider>
          )}

          <FormControl
            sx={{mb: 2, ml: 1.5, width: '50%'}}
            color='primary'
            fullWidth
            variant='filled'
            required
          >
            {/* <InputLabel id='client-select-label'>Select Client</InputLabel> */}
            <Autocomplete
              sx={{mb: 2, width: '100%'}}
              options={clients}
              getOptionLabel={(option) => option.fullName}
              value={clients.find((client) => client.id === selectedClientId) || null}
              onChange={(event, newValue) => {
                setSelectedClientId(newValue ? newValue.id : '')
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Select Client'
                  variant='filled'
                  color='primary'
                  required
                />
              )}
            />
          </FormControl>

          <FormControl
            sx={{mb: 2, ml: 1.5, width: '32%'}}
            color='primary'
            fullWidth
            variant='filled'
            required
          >
            <InputLabel>Visit Type</InputLabel>
            <Select
              // labelId='note-type-select-label'
              value={visitType}
              sx={{background: 'white'}}
              onChange={(e) => {
                setVisitType(e.target.value)
              }}
              label='VisitType'
              name='visitType'
            >
              <MenuItem value='SCHEDULED'>SCHEDULED</MenuItem>
              <MenuItem value='PRN'>PRN</MenuItem>
            </Select>
          </FormControl>
          <p
            style={{
              fontSize: '16px',
              marginLeft: '15px',
              color: '#1976d2',
              fontWeight: 700,
              fontFamily: 'Roboto',
            }}
          >
            Did the patient experience a fall?
          </p>
          <FormControl sx={{width: '100%', mr: '10px', ml: 1}} required>
            <RadioGroup
              row
              value={hasExperiencedFall}
              onChange={(e) => {
                const value = e.target.value
                setHasExperiencedFall(value)
                const updatedActivity = activity.map((fallDetail) => ({
                  ...fallDetail,
                  hasExperiencedFall: value === 'yes',
                }))
                setActivity(updatedActivity)
              }}
              sx={{mb: 2, ml: 1.5}}
            >
              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
              <FormControlLabel value='no' control={<Radio />} label='No' />
            </RadioGroup>

            {hasExperiencedFall === 'yes' && (
              <div>
                {activity.map((fallDetail, index) => (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div key={index} style={{width: '90%'}}>
                      <TextField
                        sx={{width: '98%', mr: '10px', ml: 1}}
                        label='Fall details'
                        name='details'
                        value={fallDetail.details}
                        onChange={(e) => handleFallDetailChange(index, 'details', e.target.value)}
                        fullWidth
                        margin='normal'
                        multiline
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FormControl>

          <p
            style={{
              fontSize: '16px',
              marginLeft: '15px',
              color: '#1976d2',
              fontWeight: 700,
              fontFamily: 'Roboto',
            }}
          >
            Is the patient experiencing Pain?
          </p>
          <FormControl sx={{width: '100%', mr: '10px', ml: 1}} required>
            <RadioGroup
              required
              row
              value={isExperiencingPain}
              onChange={(e) => {
                const value = e.target.value
                setIsExperiencingPain(value)

                const updatedPainAssessment = painAssessment.map((painDetail) => ({
                  ...painDetail,
                  isExperiencingPain: value === 'yes',
                }))
                setPainAssessment(updatedPainAssessment)
              }}
              sx={{mb: 2, ml: 1.5}}
            >
              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
              <FormControlLabel value='no' control={<Radio />} label='No' />
            </RadioGroup>

            {isExperiencingPain === 'yes' && (
              <>
                <div>
                  {painAssessment.map((painAssessment, index) => (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <div key={index} style={{marginBottom: '10px', width: '90%'}}>
                        <TextField
                          sx={{width: '24%', mr: '10px', ml: 1}}
                          label='Location'
                          name='location'
                          value={painAssessment.location}
                          onChange={(e) =>
                            handlePainDetailChange(index, 'location', e.target.value)
                          }
                          fullWidth
                          margin='normal'
                        />
                        <TextField
                          type='number'
                          sx={{width: '24%', mr: '10px', ml: 0.5}}
                          label='Pain Score'
                          name='score'
                          value={painAssessment.score}
                          onChange={(e) => handlePainDetailChange(index, 'score', e.target.value)}
                          fullWidth
                          margin='normal'
                        />
                        <TextField
                          sx={{width: '24%', mr: '10px', ml: 0.5}}
                          label='Duration'
                          name='duration'
                          value={painAssessment.duration}
                          onChange={(e) =>
                            handlePainDetailChange(index, 'duration', e.target.value)
                          }
                          fullWidth
                          margin='normal'
                        />
                        <TextField
                          sx={{width: '22%', mr: '10px', ml: 1}}
                          label='Frequency'
                          name='frequency'
                          value={painAssessment.frequency}
                          onChange={(e) =>
                            handlePainDetailChange(index, 'frequency', e.target.value)
                          }
                          fullWidth
                          margin='normal'
                        />
                        <TextField
                          sx={{width: index > 0 ? '90%' : '98%', mr: '10px', ml: 1}}
                          label='Description'
                          name='description'
                          value={painAssessment.description}
                          onChange={(e) =>
                            handlePainDetailChange(index, 'description', e.target.value)
                          }
                          fullWidth
                          minRows={8}
                          margin='normal'
                          multiline
                        />

                        {index > 0 && (
                          <Button
                            sx={{mt: 4, ml: 1}}
                            onClick={() => handleRemovePainDetail(index)}
                            variant='contained'
                            color='primary'
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <Button
                  sx={{mb: 4, ml: 8.5, width: '8%'}}
                  onClick={handleAddPainDetail}
                  variant='contained'
                >
                  Add More
                </Button>
              </>
            )}
          </FormControl>

          <p
            style={{
              fontSize: '16px',
              marginLeft: '15px',
              color: '#1976d2',
              fontWeight: 700,
              fontFamily: 'Roboto',
            }}
          >
            POC updated?
          </p>
          <FormControl sx={{width: '100%', mr: '10px', ml: 1}} required>
            <RadioGroup
              row
              value={pocUpdated}
              onChange={(e) => {
                const value = e.target.value
                setPocUpdated(value)
                const updatedPoc = poc.map((pocDetail) => ({
                  ...pocDetail,
                  pocUpdated: value === 'yes',
                }))
                setPoc(updatedPoc)
              }}
              sx={{mb: 2, ml: 1.5}}
            >
              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
              <FormControlLabel value='no' control={<Radio />} label='No' />
            </RadioGroup>

            {pocUpdated === 'yes' && (
              <div>
                {poc.map((pocDetail, index) => (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div key={index} style={{width: '90%'}}>
                      <TextField
                        sx={{width: '98%', mr: '10px', ml: 1}}
                        label='POC details'
                        name='pocDesc'
                        value={pocDetail.pocDesc}
                        onChange={(e) => handlePocDetailChange(index, 'pocDesc', e.target.value)}
                        fullWidth
                        margin='normal'
                        multiline
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FormControl>

          <p
            style={{
              fontSize: '16px',
              marginLeft: '15px',
              color: '#1976d2',
              fontWeight: 700,
              fontFamily: 'Roboto',
            }}
          >
            Family updated?
          </p>
          <FormControl sx={{width: '100%', mr: '10px', ml: 1}} required>
            <RadioGroup
              row
              value={familyUpdated}
              onChange={(e) => {
                const value = e.target.value
                setFamilyUpdated(value)
                const updatedFamily = familyInformed.map((famDetail) => ({
                  ...famDetail,
                  familyUpdated: value === 'yes',
                }))
                setFamilyInformed(updatedFamily)
              }}
              sx={{mb: 2, ml: 1.5}}
            >
              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
              <FormControlLabel value='no' control={<Radio />} label='No' />
            </RadioGroup>

            {familyUpdated === 'yes' && (
              <div>
                {familyInformed.map((famDetail, index) => (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div key={index} style={{width: '90%'}}>
                      <TextField
                        sx={{width: '98%', mr: '10px', ml: 1}}
                        label='Family details'
                        name='famDesc'
                        value={famDetail.famDesc}
                        onChange={(e) => handlefamilyDetailChange(index, 'famDesc', e.target.value)}
                        fullWidth
                        margin='normal'
                        multiline
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FormControl>
          <p
            style={{
              fontSize: '16px',
              marginLeft: '15px',
              color: '#1976d2',
              fontWeight: 700,
              fontFamily: 'Roboto',
            }}
          >
            Therapy
          </p>
          <FormControl
            sx={{mt: 0, mb: 2, ml: 1.5, mr: 1, width: '32%', border: '1px solid lightgrey'}}
            color='primary'
            fullWidth
            variant='filled'
            required
          >
            <InputLabel id='note-type-select-label'>Select Therapy</InputLabel>
            <Select
              labelId='note-type-select-label'
              value={therapy}
              sx={{background: 'white'}}
              onChange={(e) => {
                setTherapy(e.target.value)
              }}
              label='Therapy'
              name='therapy'
            >
              <MenuItem value='pt'>PT</MenuItem>
              <MenuItem value='ot'>OT</MenuItem>
              <MenuItem value='st'>ST</MenuItem>
              <MenuItem value='hha'>HHA</MenuItem>
              <MenuItem value='msw'>MSW</MenuItem>
              <MenuItem value='rn'>RN</MenuItem>
              <MenuItem value='cm'>CM</MenuItem>
            </Select>
          </FormControl>

          <DynamicForm
            fields={NEW_RECORD_INPUTS}
            data={NEW_RECORD_FORM_INPUTS}
            onSubmit={handleSubmitForm}
            buttons={buttons}
          />
        </>
      }
    />
    </div>
    </div>

  )
}
