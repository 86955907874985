import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import axios from 'axios'
import {useHistory, useParams} from 'react-router-dom'
import DynamicTable from '../../../../reusable-components/tables/DynamicTable'
import AdminCareHeader from '../AdminCareHeader'
import HealthProfileMenu from '../HealthProfile/HealthProfileMenu'
import {
  DIAGNOSTIC_LIST_COLUMNS_DATA_MAPPING,
  DIAGNOSTIC_LIST_COLUMNS,
  DIAGNOSTIC_LIST__SORTING_COLUMNS,
} from '../care_table_constants'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../reusable-components/tables/TableSearchBar'
import TableButton from '../../../../reusable-components/tables/TableButton'
import {useDispatch, useSelector} from 'react-redux'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {permissions} from '../../../../../_constants/permissions'
import {toast} from 'react-toastify'
import TableWrapper from '../../../../reusable-components/tables/TableWrapper'
import AddButton from '../../../../reusable-components/Buttons/AddButton'
import {MoonLoader} from 'react-spinners'
import { setPatient } from '../individual-intake/store'
const selectAuth = (state) => state
const DiagnosticsList = () => {
  const [filteredData, setFilteredData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [queryData, setQueryData] = useState({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
    sortBy: '_id',
    sortDir: 'desc',
    searchTerm: '',
  })
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const [isFetchingData, setIsFetchingData] = useState(true);
  const {client, auth} = useSelector(selectAuth);
  const userPermissions = auth.user.roleId
  const parameters = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      setIsFetchingData(true)
      try {
        const { patient } = client
        if (patient?.diagnosticsInfo?.length > 0) {
          setFilteredData(patient?.diagnosticsInfo)
        }
      } catch (error) {
      } finally {
        setIsLoading(false)
        setIsFetchingData(false)
      }
    }
    fetchData()
  }, [
    history, parameters
  ])
  const onUpdate = (row) => {
    history.push(
      `/admin/care/health/diagnosis/list/${parameters.clientID}/${
        row.uuid
      }`
    )
  }
  const onDelete = async (row) => {
    setIsFetchingData(true)
    try {
      await axios.delete(`/clients/deleteInfo?clientId=${parameters.clientID}&id=${row?.uuid}&type=diagnosticsInfo`, ).then((res) => {
        const {data} = res?.data
          dispatch(setPatient(data));
          setFilteredData(data?.diagnosticsInfo)
          localStorage.setItem('patientData', JSON.stringify(data));
          toast.success('Diagnostics Info successfuly deleted')
        })
    } catch (err) {
      console.log(err)
      toast.error('Error Deleting Diagnosis')
    } finally{
      setIsFetchingData(false)
    }
  }
  const onClickAddButton = () => {
    history.push({
      pathname: `/admin/care/health/diagnosis/new/${parameters.clientID}`,
    })
  }
  return (
    <div>
      <AdminCareHeader />
      <HealthProfileMenu />
      <div className='card my-10'>
        <div className='card-body'>
          {isLoading ? (
            <div className='overlay d-flex justify-content-center'>
              <MoonLoader color='#9db2fc' size={80} loading={isLoading} />
            </div>
          ) : (
            <div className='mt-5'>
              <TableTitle title='Diagnosis List'></TableTitle>
              <TableWrapper
                searchField={
                  <TableSearchBar
                    label='Search'
                    variant='outlined'
                    size='small'
                    value={searchTerm}
                    setQueryData={setQueryData}
                    setSearchTerm={setSearchTerm}
                    margin='normal'
                    inputAdornmentPosition='start'
                  />
                }
                tableButtons={
                  !!hasPermission(userPermissions, permissions.admin_care_diagnosis_create) && (
                    <AddButton onClick={onClickAddButton} />
                  )
                }
                dynamicTable={
                  <DynamicTable
                    columns={DIAGNOSTIC_LIST_COLUMNS}
                    data={filteredData}
                    canUpdate={hasPermission(
                      userPermissions,
                      permissions.admin_care_diagnosis_update
                    )}
                    canDelete={hasPermission(
                      userPermissions,
                      permissions.admin_care_diagnosis_delete
                    )}
                    columnDataMapping={DIAGNOSTIC_LIST_COLUMNS_DATA_MAPPING}
                    sortingColumns={DIAGNOSTIC_LIST__SORTING_COLUMNS}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    title='Are you sure you want to delete this contact?'
                    setQueryData={setQueryData}
                    queryData={queryData}
                    tableLoader={isFetchingData}
                  ></DynamicTable>
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default DiagnosticsList