import { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router-dom'
import FormWrapper from '../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../reusable-components/forms/DynamicForm'
import { USER_EDIT_CONSTANTS } from './USER_CONSTANT'
import { makeStyles } from '@mui/styles'
import { MoonLoader } from 'react-spinners'



type Params = { id: string; };

const EditUser = () => {
    const [userFields, setUserFields] = useState([])
    const [isFormLoading, setIsFormLoading] = useState(true)
    const [editFormData, setEditFormData] = useState({
        _id: '',
        firstName: '',
        lastName: '',
        email: '',
        roleId: [],
        updatedAt: ''
    })
    const history = useHistory()

    const parameters = useParams<Params>();

    useEffect(() => {
        const fetchData = async () => {
            setIsFormLoading(true)
            try {
            if (parameters.id) {
                const [rolesData, userData] = await Promise.all([
                    axios.get('/roleManagement/get'),
                    axios.get('/user/getOne/' + parameters.id)
                ])
                const user = userData.data;
                const filteredRoles = rolesData.data.filter(role => user.roleId.includes(role._id));
                let roles = filteredRoles.map((role: { _id: string, name: string }) => ({
                    value: role._id,
                    label: role.name
                }));
                const tempObj = {
                    _id: user._id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    roleId: roles,
                    updatedAt: user.updatedAt
                }
                setEditFormData(tempObj)
                let rolesList = rolesData.data.map((role: { name: string, _id: string }) => ({ label: role.name, value: role._id }))
                const constantsWithUpdatedRoles: any = USER_EDIT_CONSTANTS.map((field) => {
                    if (field.name === 'roleId') {
                        return {
                            ...field,
                            options: rolesList,
                        };
                    }
                    // else {
                    //     if(field.name === 'password'){

                    //     }
                    // }
                    return field;
                });
                setUserFields(constantsWithUpdatedRoles)
            }
        } catch (error) {
            console.error('Error fetching data', error)
          } finally {
            setIsFormLoading(false)
          }
        }
        fetchData()
    }, [parameters.id])

    const handleSubmitForm = async (formData: any) => {
        const ids = formData.roleId.map(data => data.value)
        const tempObj = {
            _id: formData._id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            roleId: ids,
            updatedAt: formData.updatedAt
        }
        await axios
            .put('/user/editUser', tempObj)
            .then(() => {
                // setEditFormData(tempObj)
                history.goBack()
                toast.success('User updated successfully!')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const useStyles = makeStyles((theme) => ({
        card: {
            width: '70%',
            marginLeft: '10rem'
        }
    }))
    const classes = useStyles();


    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];

    return (
        <div className={classes.card}>
             {isFormLoading ? (
          <div
            className="overlay d-flex justify-content-center"
            style={{ marginTop: "100px" }}
          >
            <MoonLoader color="#9db2fc" size={60} loading={isFormLoading} />
          </div>
          
        ) : (
            <FormWrapper
                title='Edit User'
                content={
                    <DynamicForm
                        fields={userFields}
                        data={editFormData}
                        onSubmit={handleSubmitForm}
                        buttons={buttons}
                    />
                }
            />
        )}
        </div>
    )
}

export default EditUser
