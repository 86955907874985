import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";

const NavigateButton = ({ label, clientId, section }) => {
  const history = useHistory();

  const handleNavigate = () => {
    if (!clientId) {
      console.error("clientId is not provided!");
      return;
    }

    if (!section) {
      console.error("section is not provided!");
      return;
    }

    history.push(`/edit-histories?clientId=${clientId}&section=${encodeURIComponent(section)}`);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleNavigate}
      style={{ margin: "10px" }}
    >
      {label || "Go to Edit History"}
    </Button>
  );
};

export default NavigateButton;
