import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'

interface NursesNotes {
  _id: String
  Name: String
  visit_type: String
  travel_time: String
  patient_concerns: String
}
interface DeleteNursesNotesResponse {
  success: boolean
  message?: string
}
interface DeleteNursesNotesPayload {
  providerCode: string
  _id: string
}

export const getNursesNotes = createAsyncThunk(
  'nursesNotes/getAll',
  async ({
    page = 1,
    pageSize = 5,
    sortBy = 'date',
    sortDir = 'asc',
    searchTerm = '',
    providerCode = 'Afgg2B',
  }: {
    page?: number
    pageSize?: number
    sortBy?: string
    sortDir?: string
    searchTerm?: string
    providerCode?: string
  }) => {
    try {
      // Construct query parameters for pagination, sorting, and search
      const params = {
        page,
        pageSize,
        sortBy,
        sortDir,
        searchTerm,
        providerCode,
      }

      const response = await axios.get(`nursesNotes/getAll`, {params})

      return {
        data: response.data.data,
        currentPage: response.data.currentPage,
        totalPages: response.data.totalPages,
        totalCounts: response.data.totalCounts,
      }
    } catch (err) {
      console.error('Error fetching nurses notes:', err)
      throw err
    }
  }
)

export const getOneNursesNotes = createAsyncThunk(
  'nursesNotes/getOne',
  async (id: string | undefined) => {
    try {
      const response = await axios.get(`/nursesNotes/getOne/${id}`)
      return {
        data: response.data,
      }
    } catch (err) {
      console.log(err)
      return Promise.reject(err)
    }
  }
)

export const addNursesNotes = createAsyncThunk('nursesNotes/create', async (data: NursesNotes) => {
  try {
    const response = await axios.post('nursesNotes/create', data)
    return response.data
  } catch (err) {
    console.log(err)
    return err
  }
})

export const deleteNursesNotes = createAsyncThunk<DeleteNursesNotesResponse, string>(
  'nursesNotes/softDelete/',
  async (id: string) => {
    const response = await axios.delete(`/nursesNotes/softDelete`, {
      data: {_id: id},
    })
    return response.data as DeleteNursesNotesResponse
  }
)
export const updateNursesNotes = createAsyncThunk(
  'nursesNotes/update',
  async (data: NursesNotes) => {
    try {
      const response = await axios.put('nursesNotes/update', data)
      return response.data
    } catch (err) {
      console.log(err)
      return err
    }
  }
)
