import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {Autocomplete, Button, TextField, TextareaAutosize} from '@mui/material'
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import AdminCareHeader from '../AdminCareHeader'
import HealthProfileMenu from '../HealthProfile/HealthProfileMenu'
import {MoonLoader} from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import { setPatient } from '../individual-intake/store'
const selectAuth = (state) => state.client;

const EditDiagnosisForm = () => {
  const [codeType, setCodeType] = useState({label: '', value: ''})
  const [initialDiagnosisName, setInitialDiagnosisName] = useState({label: '', value: ''})
  const [formData, setFormData] = useState({
    diagnosisCodeType: '',
    diagnosisName: '',
    description: '',
    diagnosisDate: '',
    enteredBy: '',
    _id: '',
  })
  const [filteredOptions, setFilteredOptions] = useState([])
  const parameters: {clientID: string; id: string} = useParams()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const {patient} = useSelector(selectAuth);
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const id = parameters.clientID
        if (patient?.diagnosticsInfo && id == patient?._id) {
          const filteredDiagnosis = patient?.diagnosticsInfo.filter(
            (diagnosis) => diagnosis.uuid === parameters?.id // Replace 'id' with the correct key to match against parameters?.id
        );
        setCodeType({
          label: filteredDiagnosis[0].diagnosisCodeType,
          value: filteredDiagnosis[0].diagnosisCodeType,
        })
        setInitialDiagnosisName(filteredDiagnosis[0].diagnosisName)
          setFormData(filteredDiagnosis[0])
          setIsLoading(false)
        } 
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  const getData = async (value) => {
    try {
      let response
      if (codeType?.value === 'ICD-9') {
        response = await axios.post(`/icd9/findICD`, {
          value: value,
        })
      } else if (codeType?.value === 'ICD-10') {
        response = await axios.post(`/icd10/findICD`, {
          value: value,
        })
      }
      return response.data.data.map((item) => ({
        label: item.info,
        value: item.info,
      }))
    } catch (error) {
      console.error('Error fetching allergies:', error)
      return []
    }
  }

  const loadOptions = async (inputValue) => {
    if (inputValue.length >= 3) {
      const response = await getData(inputValue)
      setFilteredOptions(response)
    } else {
      setFilteredOptions([])
    }
  }

  const handleAutocompleteChange = (_, newValue) => {
    if (newValue) {
      handleInputChange('diagnosisName', newValue.value)
    }
  }
  const codeTypes = [
    {value: 'ICD-9', label: 'ICD-9'},
    {value: 'ICD-10', label: 'ICD-10'},
  ]

  const handleInputChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }
  const handleSubmit = async (values) => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getDate().toString().padStart(2, '0')

    // Create the formatted date string
    const currentDateFormatted = `${year}-${month}-${day}`
    try {
      let newData = {
        diagnosisCodeType: codeType.value,
        diagnosisName: formData.diagnosisName,
        description: formData.description,
        diagnosisDate: formData.diagnosisDate,
        enteredBy: formData.enteredBy,
        _id: formData._id,
      }
      if (newData.diagnosisCodeType && newData.diagnosisName && newData.description) {
        if (values.diagnosisDate <= currentDateFormatted) {
          const response = await axios.put(`/clients/update/diagnosticsInfo/${parameters.clientID}`, newData)
          if (response?.status === 400 && response?.data?.success === false) {
            toast.error(response?.data?.message)
          } else if (response.status === 200 && response.data.success) {
            toast.success('Diagnosis Updated Successfully')
            dispatch(setPatient(response?.data?.data))
            history.push(`/admin/care/health/diagnosis/list/${parameters.clientID}`)
          } else {
            toast.error('Error Updating Diagnosis')
          }
        } else {
          toast.error('Diagnosis Date should not be later than today')
        }
      }
    } catch (err) {
      console.log(err)
      toast.error('Error Updating Diagnosis')
    }
  }

  return (
    <div>
      <AdminCareHeader />
      <HealthProfileMenu />
      <div className='card my-10'>
        <div className='card-body'>
          {isLoading ? (
            <div className='overlay d-flex justify-content-center'>
              <MoonLoader color='#9db2fc' size={80} loading={isLoading} />
            </div>
          ) : (
            <>
              <h2 className='mt-6 mb-5'>Edit Diagnosis</h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit(formData)
                }}
              >
                <div className='row'>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                    <Autocomplete
                      options={codeTypes}
                      size='small'
                      id='diagnosisCodeType'
                      getOptionLabel={(option) => option.label}
                      value={codeTypes.find((option) => option.value === codeType.value) || null}
                      onChange={(_, newValue) => {
                        if (newValue) {
                          setCodeType(newValue)
                          handleInputChange('diagnosisName', '')
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label='Diagnosis Code Type' />
                      )}
                    />
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                    <Autocomplete
                      id='diagnosisName'
                      size='small'
                      options={filteredOptions}
                      value={initialDiagnosisName}
                      onInputChange={(_, newValue) => loadOptions(newValue)}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      onChange={(e, newValue) => {
                        handleAutocompleteChange(e, newValue)
                        setInitialDiagnosisName(newValue.value)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Search for ${codeType.value}`}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <TextareaAutosize
                      name='description'
                      minRows={4}
                      maxRows={10}
                      style={{
                        marginBottom: '1rem',
                        width: '100%',
                        borderRadius: '5px',
                        padding: '7px',
                      }}
                      placeholder='Description'
                      onChange={(e) => {
                        handleInputChange('description', e.target.value)
                      }}
                      value={formData.description}
                    />
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='Diagnosis Date'
                        value={dayjs(formData.diagnosisDate)}
                        format='MM-DD-YYYY'
                        onChange={(newValue) => {
                          if (newValue) {
                            const newDate = newValue.format('YYYY-MM-DD')
                            handleInputChange('diagnosisDate', newDate)
                          }
                        }}
                        slotProps={{
                          textField: {size: 'small', error: false, style: {width: '100%'}},
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className='mx-2 float-end'>
                  <Button
                    variant='contained'
                    color='primary'
                    sx={{height: '2.3rem', minWidth: '86px', float: 'right', marginRight: '5px'}}
                    type='submit'
                  >
                    Save
                  </Button>
                  <Button
                    variant='outlined'
                    type='button'
                    sx={{height: '2.3rem', minWidth: '86px', float: 'right', marginRight: '5px'}}
                    onClick={() => {
                      history.push(`/admin/care/diagnosis/list/${parameters.clientID}`)
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditDiagnosisForm
