import React, { useState, useEffect, CSSProperties } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../redux/AuthRedux";

const styles = {
  container: {
    maxWidth: "700px",
    margin: "0 auto",
    padding: "30px",
    fontFamily: "'Arial', sans-serif",
  },
  title: {
    fontSize: "28px",
    fontWeight: "600",
    textAlign: "center",
    color: "#333",
    marginBottom: "30px",
  },
  card: {
    padding: "30px",
    borderRadius: "12px",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  fileInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginTop: "15px",
  },
  inputField: {
    display: "inline-block",
    fontSize: "16px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
  },
  uploadButton: {
    padding: "10px 25px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    boxShadow: "0 4px 8px rgba(0, 123, 255, 0.4)",
  },
  loader: {
    fontSize: "16px",
    color: "#007bff",
    marginTop: "10px",
  },
  previewContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "20px",
  },
  previewImage: {
    width: "130px",
    height: "130px",
    borderRadius: "50%",
    objectFit: "cover",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  placeholder: {
    width: "130px",
    height: "130px",
    borderRadius: "50%",
    backgroundColor: "#f0f0f0",
    color: "#999",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
  },
  errorMessage: {
    color: "red",
    fontSize: "14px",
    marginTop: "10px",
  },
  successMessage: {
    textAlign: "center",
    marginTop: "20px",
    color: "#28a745",
    fontSize: "16px",
    fontWeight: "bold",
  },
  link: {
    color: "#007bff",
    textDecoration: "none",
    fontWeight: "bold",
    display: "block",
    marginTop: "5px",
  },
};

const EditProfile = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const selectAuth = (state) => state.auth;
  const auth = useSelector(selectAuth);
  const userEmail = auth?.user?.email;
  const profileImg = auth?.user?.profileImg; 
  console.log(profileImg)
  const [fileUrl, setFileUrl] = useState<string>(""); 
  const fetchPresignedUrl = async (key: string) => {
    try {
      const response = await axios.get(`/batchfile/fetch-file-url`, {
        params: { fileKey: key },
      });
      if (response.status === 200) {
        setFileUrl(response.data.presignedUrl);
      }
    } catch (error) {
      console.error("Error fetching presigned URL:", error);
      setErrorMessage("Failed to fetch profile image URL.");
    }
  };
  useEffect(() => {
    if (profileImg) {
      fetchPresignedUrl(profileImg);
    }
  }, [profileImg]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files![0]);
    setErrorMessage("");
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setErrorMessage("Please select a file to upload.");
      return;
    }
  
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("email", userEmail);
    formData.append("type", "profile");
  
    setLoading(true);
  
    try {
      const response = await axios.post("/batchfile/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.status === 200) {
        const newProfileKey = response.data.profileUrl;
        
        if (newProfileKey) {
          const presignedResponse = await axios.get(`/batchfile/fetch-file-url`, {
            params: { fileKey: newProfileKey },
          });
          if (presignedResponse.status === 200) {
            const presignedUrl = presignedResponse.data.presignedUrl;
            dispatch(actions.setProfileUrl(presignedUrl));
            setFileUrl(presignedUrl);
          } else {
            console.error("Failed to fetch presigned URL");
          }
        } else {
          console.error("No file key returned from the server.");
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorMessage("Failed to upload file.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div style={styles.container as CSSProperties}>
      <h2 style={styles.title as CSSProperties}>Edit Profile Picture</h2>
      <div style={styles.card as CSSProperties}>
        <div style={styles.previewContainer as CSSProperties}>
          {selectedFile ? (
            <img
              src={URL.createObjectURL(selectedFile)}
              alt="Selected"
              style={styles.previewImage as CSSProperties}
            />
          ) : fileUrl ? (
            <img
              src={fileUrl}
              alt="Profile"
              style={styles.previewImage as CSSProperties}
            />
          ) : (
            <div style={styles.placeholder as CSSProperties}>
              No Image Selected
            </div>
          )}
        </div>

        <div style={styles.fileInput as CSSProperties}>
          <input
            type="file"
            onChange={handleFileChange}
            style={styles.inputField as CSSProperties}
          />
          <button
            onClick={handleUpload}
            style={styles.uploadButton as CSSProperties}
          >
            {loading ? "Uploading..." : "Upload"}
          </button>
        </div>

        {loading && (
          <div style={styles.loader as CSSProperties}>Uploading...</div>
        )}

        {errorMessage && (
          <p style={styles.errorMessage as CSSProperties}>{errorMessage}</p>
        )}

        {fileUrl && !errorMessage && !loading && (
          <div style={styles.successMessage as CSSProperties}>
            <p>Profile picture uploaded successfully!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProfile;
