import React from 'react'
import {useSelector} from 'react-redux'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {permissions} from '../../../../../_constants/permissions'
import {Redirect, Route, Switch} from 'react-router-dom'
import { RouteWithPermission } from '../../../../../_helper/routesWithPermission'
import { CaregiversList } from '../../../../pages/caregiver/General/List/CaregiversList'
import NewCareGiver from '../../../../pages/caregiver/General/Add/NewCareGiver'
import EditCareGiver from '../../../../pages/caregiver/General/Edit/EditCareGiver'
import { CaregiverProfileJobDetails } from '../../../../pages/caregiver/General/Profiles/JobDetails/CaregiverProfileJobDetails'
import { CaregiverProfileDocuments } from '../../../../pages/caregiver/General/Profiles/Documents/CaregiverDocuments'
import { CaregiverProfilePersonalInformation } from '../../../../pages/caregiver/General/Profiles/PersonalInformation/CareGiverProfilePersonalInformation'
import AddCaregiverProfileDocuments from '../../../../pages/caregiver/General/Profiles/Documents/AddCaregiverProfileDocuments'

const CaregiverIndexRoute = () => {
  const auth = useSelector((state: {auth: any}) => state.auth)
  const userPermissions = auth.user.roleId
  const canViewProfileInfo = hasPermission(
    userPermissions,
    permissions.care_giver_profile_personalInformation_view
  )
  const canViewDocuments = hasPermission(
    userPermissions,
    permissions.care_giver_profile_documents_view
  )
  const canCreateDocuments = hasPermission(
    userPermissions,
    permissions.care_giver_profile_documents_view
  )
  const canViewJobDetails = hasPermission(
    userPermissions,
    permissions.care_giver_profile_jobDetails_view
  )
  const canUpdate = hasPermission(
    userPermissions,
    permissions.care_giver_general_view
  )
  const canCreate = hasPermission(
    userPermissions,
    permissions.care_giver_create
  )
  const canView = hasPermission(userPermissions, permissions.care_giver_view)

  // Default path based on permissions
  const getDefaultPath = () => {
    if (canView) return '/caregivers'
    if (canCreate) return '/caregivers/new'
    if (canUpdate) return '/caregivers/edit/:id'
    if (canViewJobDetails) return '/caregivers/profile/jobDetails/:id'
    if (canViewProfileInfo) return '/caregivers/profile/personalInformation/:id'
    if (canViewDocuments) return '/caregivers/profile/documents/:id'
    if (canCreateDocuments) return '/caregivers/profile/documents/new/:id'
    // Fallback to 404 if no permissions
    return '/error/404'
  }

  return (
    <Switch>
      {/* Default redirect if no specific path is chosen */}
      <Route exact path='/caregiver'>
        <Redirect to={getDefaultPath()} />
      </Route>
      <RouteWithPermission
        path='/caregivers/profile/documents/new/:id'
        component={AddCaregiverProfileDocuments}
        hasPerm={canView && canCreateDocuments}
      />
      <RouteWithPermission
        path='/caregivers/profile/documents/:id'
        component={CaregiverProfileDocuments}
        hasPerm={canView && canViewDocuments}
      />
      <RouteWithPermission
        path='/caregivers/profile/personalInformation/:id'
        component={CaregiverProfilePersonalInformation}
        hasPerm={canView && canViewProfileInfo}
      />
      <RouteWithPermission
        path='/caregivers/profile/jobDetails/:id'
        component={CaregiverProfileJobDetails}
        hasPerm={canView && canViewJobDetails}
      />
      <RouteWithPermission
        path='/caregivers/edit/:id'
        component={EditCareGiver}
        hasPerm={canView && canUpdate}
      />
      <RouteWithPermission
        path='/caregivers/new'
        component={NewCareGiver}
        hasPerm={canView && canCreate}
      />
      <RouteWithPermission
        path='/caregivers'
        component={CaregiversList}
        hasPerm={canView}
      />
    </Switch>
  )
}

export default CaregiverIndexRoute
