import React, {useEffect, useState} from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
  Button,
  TextField,
  Autocomplete,
  Typography,
} from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import RoleModal from '../../pages/roles/RoleModal'
import AddBoxIcon from '@mui/icons-material/AddBox'
import './style.css'
import {MoonLoader} from 'react-spinners'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import DownloadIcon from '@mui/icons-material/Download'
import { DynamicTableProps } from './DynamicTableTypes'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'
const selectAuth = (state: RootState) => state.timeZone.timeZone

const DynamicTable: React.FC<DynamicTableProps> = ({
  loadingStates,
  setQueryData,
  queryData,
  pageSize,
  currentPage,
  setPageSize,
  setPageNumber,
  tableLoader,
  columns,
  data,
  canUpdate = false,
  canDelete = false,
  columnDataMapping,
  onDelete,
  onUpdate,
  title,
  sortingColumns,
  clickableRow,
  rowClickFunction,
  canAdd = false,
  onAdd,
  canView = false,
  onView,
  canAddBox = false,
  onAddBox,
  updateModal,
  updateDataBsToggle,
  updateDataBsTarget,
  addModal,
  addDataBstoggle,
  addDataBstarget,
  viewModal,
  viewDataBstoggle,
  viewDataBstarget,
  addBoxDataBsToggle,
  addBoxDataBstarget,
  htmlInRows,
  htmlColumns,
  canApprove,
  canReject,
  onApprove,
  onReject,
  canUpdateStatus,
  handleUpdateStatus,
  canDownload = false,
  onDownload,
}) => {
  const rowsPerPageOptions = [5, 10, 25, 50]
  const [compData, setData] = useState(data)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [ordersOfColumns, setOrdersOfColumns] = useState({})
  const [loading, setLoading] = useState(true)
  const [goToPage, setGoToPage] = useState('')
  const totalPages = Math.ceil(queryData.totalCounts / rowsPerPage)
  const pageOptions = Array.from({length: totalPages}, (_, i) => (i + 1).toString())
  const timeZone = useSelector(selectAuth)

  useEffect(() => {
    if (sortingColumns && Object.keys(sortingColumns).length > 0) {
      const initialOrders = {}
      for (const column of Object.keys(sortingColumns)) {
        if (!ordersOfColumns[column]) {
          initialOrders[column] = 'asc'
        }
      }
      setOrdersOfColumns((prevOrders) => ({...prevOrders, ...initialOrders}))
      setTimeout(() => {
        setLoading(false)
      }, 100)
    } else {
      setTimeout(() => {
        setLoading(false)
      }, 100)
    }
  }, [])
  useEffect(() => {
    setData([...data])
  }, [data])
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    const adjustPage = newPage + 1
    const newSkip = (adjustPage - 1) * queryData.pageSize
    setQueryData((prevState) => {
      return {
        ...prevState,
        pageNumber: adjustPage,
        skip: newSkip,
      }
    })
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setQueryData((prevState) => {
      return {
        ...prevState,
        pageSize: parseInt(event.target.value, 10),
      }
    })
  }

  const handleDelete = (row) => {
    setShowDeleteModal(true)
    setSelectedRow(row)
  }

  const handleDiscardDelete = () => {
    setShowDeleteModal(false)
    setSelectedRow(null)
  }

  const handleConfirmDelete = () => {
    if (onDelete) {
      onDelete(selectedRow)
    }
    setShowDeleteModal(false)
    setSelectedRow(null)
  }
  const handleSort = (colName: string | object) => {
    const columnName = typeof colName === 'object' ? Object.keys(colName)[0] : colName
    const currentSortDir = ordersOfColumns[columnName]
    const newSortDir = currentSortDir === 'asc' ? 'desc' : 'asc'
    const dbColumnName = sortingColumns[columnName]
    setQueryData((prevState) => ({
      ...prevState,
      sortBy: dbColumnName,
      sortDir: newSortDir,
    }))
    setOrdersOfColumns({...ordersOfColumns, [columnName]: newSortDir})
  }

  const handleUpdate = (row) => {
    if (onUpdate) {
      onUpdate(row)
    }
  }

  const handleAdd = (row) => {
    if (onAdd) {
      onAdd(row)
    }
  }

  const handleView = (row) => {
    if (onView) {
      onView(row)
    }
  }
  const handleAddBox = (row) => {
    setShowAddModal(true)
    setSelectedRow(row)
  }

  const handleDiscardAdd = () => {
    setShowAddModal(false)
    setSelectedRow(null)
  }

  const handleConfirmAdd = () => {
    if (onAddBox) {
      onAddBox(selectedRow)
    }
    setShowAddModal(false)
    setSelectedRow(null)
  }
  const handlePageInputChange = (event, value) => {
    if (value) {
      setGoToPage(value)
      const pageNumber = parseInt(value, 10)
      if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= totalPages) {
        handleChangePage(null, pageNumber - 1)
      }
    } else {
      setPage(0)
      handleChangePage(null, 0)
    }
  }

  const isDateColumn = (column) => {
    const dateKeywords = ["Date", "date", "dates", "Dates"];
    return dateKeywords.some(keyword => column.includes(keyword));
  };
  const isTimeColumn = (column) => {
    return column.toLowerCase().includes('time');
  };
  
  return (
    <div className='table-responsive'>
      {loading ? (
        <div className='d-flex justify-content-center'>
          <MoonLoader color='#9db2fc' size={80} />
        </div>
      ) : (
        <>
          <Table className='table-container' stickyHeader>
            <TableHead>
              <TableRow>
                {(canDownload ||
                  canUpdate ||
                  canDelete ||
                  canAdd ||
                  canView ||
                  canAddBox ||
                  canApprove ||
                  canReject ||
                  canUpdateStatus) && <TableCell className='table-header-cell'>Actions</TableCell>}
                {Object.keys(sortingColumns).map((column, index) => (
                  <TableCell key={index} className='table-header-cell'>
                    {sortingColumns[column] ? (
                      <TableSortLabel
                        active={queryData.sortBy === sortingColumns[column]}
                        direction={queryData.sortDir}
                        onClick={() => handleSort(column)}
                      >
                        {column}
                      </TableSortLabel>
                    ) : (
                      <TableCell key={index} className='table-header-cell'>
                        {column}
                      </TableCell>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
            {tableLoader ? (
    <TableRow>
      <TableCell colSpan={columns.length + 1} style={{ textAlign: 'center' }}>
        <div style={{ padding: '20px' }}>
        <div className='overlay d-flex justify-content-center'>
              <MoonLoader color='#9db2fc' size={60} />
            </div>
          <Typography variant="body1" style={{ marginTop: '15px', color: 'grey' }}>
            Please wait
          </Typography>
        </div>
      </TableCell>
    </TableRow>
  ) :
              compData.length === 0 ? (
                <TableRow className='table-row-even'>
                  <TableCell className='table-cell-action' colSpan={columns.length + 1}>
                    <span
                      className='text-gray-800 mb-1'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      No Data Found
                    </span>
                  </TableCell>
                </TableRow>
              ) : (
                compData.map((row, rowIndex) => (
                  <TableRow
                    className={rowIndex % 2 === 0 ? 'table-row-even' : 'table-row-odd'}
                    key={rowIndex}
                  >
                    {(canUpdate ||
                      canDelete ||
                      canAdd ||
                      canView ||
                      canAddBox ||
                      canApprove ||
                      canReject ||
                      canUpdateStatus) && (
                      <TableCell className='table-cell-action'>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          {canUpdate && updateModal && (
                            <div
                              data-bs-toggle={updateDataBsToggle}
                              data-bs-target={updateDataBsTarget}
                            >
                              <EditOutlinedIcon
                                style={{cursor: 'pointer'}}
                                onClick={() => handleUpdate(row)}
                              />
                            </div>
                          )}
                          {canUpdate && !updateModal && (
                            <EditOutlinedIcon
                              style={{cursor: 'pointer'}}
                              onClick={() => handleUpdate(row)}
                            />
                          )}
                          {canDelete && (
                            <DeleteOutlineIcon
                              style={{cursor: 'pointer'}}
                              onClick={() => handleDelete(row)}
                            />
                          )}
                          {canAdd && addModal && (
                            <div data-bs-toggle={addDataBstoggle} data-bs-target={addDataBstarget}>
                              <AddOutlinedIcon
                                style={{cursor: 'pointer'}}
                                onClick={() => handleAdd(row)}
                              ></AddOutlinedIcon>
                            </div>
                          )}
                          {canAdd && !addModal && (
                            <AddOutlinedIcon
                              style={{cursor: 'pointer'}}
                              onClick={() => handleAdd(row)}
                            ></AddOutlinedIcon>
                          )}
                          {canView && viewModal && (
                            <div
                              data-bs-toggle={viewDataBstoggle}
                              data-bs-target={viewDataBstarget}
                            >
                              <VisibilityOutlinedIcon
                                style={{cursor: 'pointer'}}
                                onClick={() => handleView(row)}
                              ></VisibilityOutlinedIcon>
                            </div>
                          )}
                          {canUpdateStatus && (
                            <Button onClick={() => handleUpdateStatus(row)}>
                              {row.status === 'ACTIVE' ? 'DISABLE' : 'ENABLE'}
                            </Button>
                          )}
                          {canView && !viewModal && (
                            <VisibilityOutlinedIcon
                              style={{cursor: 'pointer'}}
                              onClick={() => handleView(row)}
                            ></VisibilityOutlinedIcon>
                          )}
                          {canAddBox && addModal && (
                            <div
                              data-bs-toggle={addBoxDataBsToggle}
                              data-bs-target={addBoxDataBstarget}
                            >
                              <AddBoxIcon
                                style={{cursor: 'pointer'}}
                                onClick={() => handleAddBox(row)}
                              ></AddBoxIcon>
                            </div>
                          )}
                          {canAddBox && !addModal && (
                            <AddBoxIcon
                              style={{cursor: 'pointer'}}
                              onClick={() => handleAddBox(row)}
                            ></AddBoxIcon>
                          )}
                          {canApprove && (
                            <div onClick={() => !loadingStates[row._id]?.approve && onApprove(row)}>
                              {loadingStates[row._id]?.approve ? (
                                <MoonLoader size={17} color='#9497a1' />
                              ) : (
                                <CheckCircleIcon style={{cursor: 'pointer'}} />
                              )}
                            </div>
                          )}
                          {canReject && (
                            <div onClick={() => !loadingStates[row._id]?.reject && onReject(row)}>
                              {loadingStates[row._id]?.reject ? (
                                <MoonLoader size={17} color='#9497a1' />
                              ) : (
                                <CancelIcon style={{cursor: 'pointer'}} />
                              )}
                            </div>
                          )}
                          {canDownload && (
                            <div
                              onClick={() => !loadingStates[row._id]?.download && onDownload(row)}
                            >
                              {loadingStates[row._id]?.download ? (
                                <MoonLoader size={17} color='#9497a1' />
                              ) : (
                                <DownloadIcon style={{cursor: 'pointer'}} />
                              )}
                            </div>
                          )}
                        </div>
                      </TableCell>
                    )}
                    {/* {columns.map((column, colIndex) =>
                      clickableRow ? (
                        <TableCell
                          key={colIndex}
                          onClick={() => rowClickFunction(row)}
                          className='table-cell'
                        >
                          <span
                            className='text-gray-800 text-hover-primary mb-1'
                            style={{cursor: 'pointer'}}
                          >
                            {row[columnDataMapping[column]]?.toString()}
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell key={colIndex} className='table-cell'>
                          {htmlColumns &&
                            htmlColumns.includes(column) &&
                            row[columnDataMapping[column]]}
                          {(!htmlColumns || !htmlColumns.includes(column)) &&
                            row[columnDataMapping[column]]?.toString()}
                        </TableCell>
                      )
                    )} */}

                  {columns.map((column, colIndex) => (
                    <TableCell 
                      key={colIndex} 
                      onClick={clickableRow ? () => rowClickFunction(row) : undefined} 
                      className="table-cell"
                    >
                      {isDateColumn(column) && typeof row[columnDataMapping[column]] === 'string' ? (
                        moment.tz(row[columnDataMapping[column]], 'UTC').tz(timeZone).format('YYYY-MM-DD')
                      ) : isTimeColumn(column) && typeof row[columnDataMapping[column]] === 'string' ? (
                        moment.tz(row[columnDataMapping[column]], 'UTC').tz(timeZone).format('hh:mm A')
                      ) : (
                        htmlColumns && htmlColumns.includes(column) 
                          ? row[columnDataMapping[column]] 
                          : row[columnDataMapping[column]]?.toString()
                      )}
                    </TableCell>
                  ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </>
      )}
      <div
        style={{
          width: '100%',

          ...(windowWidth >= 768 && { display: 'flex', justifyContent: 'flex-end' }),        }}
      >
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions || [5]}
          component='div'
          count={(queryData?.totalCounts && queryData?.totalCounts) || 0}
          rowsPerPage={rowsPerPage}
          page={(queryData?.currentPage && queryData?.currentPage - 1) || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className='MuiTablePagination-toolbar'
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            // width: '100%',
            '& .MuiTablePagination-toolbar': {
              paddingLeft: '0px',
            },
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            marginBottom: '5px',
          }}
        >
          <Typography>Go to page:</Typography>
          <Autocomplete
            freeSolo
            options={pageOptions}
            value={goToPage}
            onChange={(event, newValue) => handlePageInputChange(event, newValue)}
            onInputChange={handlePageInputChange}
            renderInput={(params) => (
              <TextField {...params} label='Page' variant='outlined' size='small' />
            )}
            style={{width: '60px', marginLeft: '8px'}}
          />
        </div>
      </div>

      <RoleModal
        title={title}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        handleDiscardDelete={handleDiscardDelete}
      />
      <RoleModal
        title={title}
        showDeleteModal={showAddModal}
        setShowDeleteModal={setShowAddModal}
        handleConfirmDelete={handleConfirmAdd}
        handleDiscardDelete={handleDiscardAdd}
      />
    </div>
  )
}

export default DynamicTable
