import { Action } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { UserModel } from '../models/UserModel';
import { getUserByToken } from './AuthCRUD';

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetProfileUrl: '[Set Profile URL] Action',
};

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  profileUrl: undefined,
};

export interface IAuthState {
  user?: UserModel;
  accessToken?: string;
  profileUrl?: string;
}

export const reducer = persistReducer(
  { storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken', 'profileUrl'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken;
        return { accessToken, user: undefined, profileUrl: undefined };
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken;
        return { accessToken, user: undefined, profileUrl: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserRequested: {
        return { ...state, user: undefined };
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user;
        const profileUrl = action.payload?.user?.profileUrl; 
        return { ...state, user, accessToken: action.payload?.accessToken, profileUrl };
      }

      case actionTypes.SetProfileUrl: {
        const profileUrl = action.payload; 
        return {
          ...state,
          user: { ...state.user, profileImg: profileUrl },
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (accessToken: string) => ({ type: actionTypes.Login, payload: { accessToken } }),
  register: (accessToken: string) => ({ type: actionTypes.Register, payload: { accessToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({ type: actionTypes.UserRequested }),
  fulfillUser: (user: UserModel) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user: UserModel, token: string) => ({
    type: actionTypes.SetUser,
    payload: { user, accessToken: token },
  }),
  setProfileUrl: (profileUrl: string) => ({
    type: actionTypes.SetProfileUrl,
    payload: profileUrl, 
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    yield put(actions.fulfillUser(user));
    if (user?.profileUrl) {
      yield put(actions.setProfileUrl(user.profileUrl)); 
    }
  });
}
