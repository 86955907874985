import React, { useState } from 'react';
import { Modal, Button, TextField, Box } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const DescriptionModal = ({ open, onClose, clientID, onSave,section }) => {
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    if (!description.trim()) {
      toast.error('Description is required');
      return;
    }

    setIsLoading(true); 
    try {
      const response = await axios.post('/edit-history', { clientID, description,section });
      if (response.data.success) {
        toast.success('Description saved successfully');
        setDescription('');
        onSave(description);
        onClose();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to save description';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="description-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          p: 4,
          borderRadius: 2,
        }}
      >
        <h2 id="description-modal-title">Reason for edit</h2>
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} variant="outlined" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary" disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DescriptionModal;
