import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { getFundingSource, deleteFundingSource } from '../store/actions';
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom';
import TableTitle from '../../../../../reusable-components/tables/TableTitle';
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar';
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable';
import { LIST_OF_FUNDING_SOURCE_COLUMNS, LIST_OF_FUNDING_SOURCE_COLUMN_DATA_MAPPING, LIST_OF_FUNDING_SOURCE_SORTING_COLUMNS } from '../../BILLING_TABLE_CONSTANTS';
import { hasPermission } from '../../../../../../_helper/hasPermission';
import { permissions } from '../../../../../../_constants/permissions';
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper';
import AddButton from '../../../../../reusable-components/Buttons/AddButton';
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes';
const selectAuth = (state: RootState) => state.auth;
export default function List() {
    const [filteredData, setFilteredData] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState('')
    const [deletedID, setDeletedID] = useState('')
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm: ""
    })
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            setIsFetchingData(true)
            try {
                const result: any = await dispatch(getFundingSource({ page, pageSize, sortBy, sortDir, searchTerm: queryData.searchTerm}));
                const { data, currentPage, totalCounts } = result.payload.data;
                setFilteredData(data);
                setQueryData((prevState)=> {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    }
                })
            } catch (error) {
                console.log(error);
            } finally{
                setIsFetchingData(false)
            }
        };
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    }, [deletedID, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])
    const deleteFundingSourceFun = async (row: { _id: string; }) => {
        setIsFetchingData(true)
        try {
            const response: any = await dispatch(deleteFundingSource(row._id))
            if (response.payload?.success) {
                toast.success(response.payload.message || 'Success', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
        setDeletedID(row._id)
        setQueryData((prevState)=> {
            return {
                ...prevState,
                pageNumber: 1,
                searchTerm : '',
            }
        })
            } else {
                toast.error(response.payload?.response?.data?.message || response.payload?.message || 'Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        } catch (error) {
            toast.error('Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
        finally{
            setIsFetchingData(false)
        }
    }
    const onUpdate = ({ _id }: { _id: string }) => {
        history.push({
            pathname: `/admin/billing/funding-source/list/${_id}`
        })
    }
    const onClickAddButton = () => {
        history.push('/admin/billing/funding-source/new')
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Funding Source List'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            setQueryData={setQueryData}
                            setSearchTerm={setSearchTerm}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                    }
                    tableButtons={
                        !!hasPermission(userPermissions, permissions.admin_billing_fundingSource_create) && 
                        <AddButton
                        onClick={onClickAddButton}
                />
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={LIST_OF_FUNDING_SOURCE_COLUMNS}
                            data={filteredData}
                            canUpdate={hasPermission(userPermissions, permissions.admin_billing_fundingSource_update)}
                            canDelete={hasPermission(userPermissions, permissions.admin_billing_fundingSource_delete)}
                            columnDataMapping={LIST_OF_FUNDING_SOURCE_COLUMN_DATA_MAPPING}
                            sortingColumns={LIST_OF_FUNDING_SOURCE_SORTING_COLUMNS}
                            clickableRow={false}
                            onUpdate={onUpdate}
                            onDelete={deleteFundingSourceFun}
                            title='Are you sure you want to delete the Funding Source'
                            setQueryData={setQueryData}
                            queryData={queryData}
                            tableLoader={isFetchingData}
                        />
                    }
                />
            </div>
        </div>
    )
}