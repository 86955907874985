import React, {useEffect, useState} from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector} from 'react-redux'
import {hasPermission} from '../../../../_helper/hasPermission'
import {permissions} from '../../../../_constants/permissions'
import ClientDailog from '../../../../app/pages/admin/care/individual-intake/ClientDailog'
import {useLocation} from 'react-router-dom'
const asideMenuItems = [
  {
    title: 'User Management',
    icon: '/media/icons/duotune/general/gen049.svg',
    permission: permissions.userManagement,
    subMenuItems: [
      {
        to: '/roles',
        title: 'Roles',
        hasBullet: true,
        permission: permissions.userManagement_roles,
      },
      {
        to: '/users',
        title: 'User',
        hasBullet: true,
        permission: permissions.user_management_user_view,
      },
      {
        to: '/import/data',
        title: 'Import Data',
        hasBullet: true,
        permission: permissions.userManagement_userManagement,
      },
      {
        to: '/notification-manager',
        title: 'Notification Manager',
        hasBullet: true,
        permission: permissions.admin_billing,
      },
    ],
  },
  {
    title: 'Admin',
    icon: '/media/icons/duotune/general/gen051.svg',
    permission: permissions.admin,
    subMenuItems: [
      {
        to: '/admin/general/shared-contact/list',
        title: 'General',
        hasBullet: true,
        permission: permissions.admin_general,
      },
      {
        to: '/admin/selected-client',
        title: 'Care',
        hasBullet: true,
        permission: permissions.admin_care,
      },
      // {
      //   to: '/admin/personalfinance',
      //   title: 'Personal Finance',
      //   hasBullet: true,
      //   permission: permissions.admin_personalFinance,
      // },
      {
        to: '/admin/billing/attendance-type/list',
        title: 'Billing',
        hasBullet: true,
        permission: permissions.admin_billing,
      },
      // {
      //   to: '/admin/datadrivenoutcome',
      //   title: 'Data Driven Outcome',
      //   hasBullet: true,
      //   permission: permissions.admin_dataDrivenOutcome,
      // },
      {
        to: '/admin/service-directory',
        title: 'Service Directory',
        hasBullet: true,
        permission: permissions.admin_serviceDirectory,
      },
    ],
  },
  {
    title: 'Caregiver',
    icon: '/media/icons/duotune/communication/com006.svg',
    permission: permissions.care_giver_view,
    subMenuItems: [
      {
        to: '/caregivers',
        title: 'General',
        hasBullet: true,
        permission: permissions.care_giver_view,
      },
      {
        to: '/caregivers/schedules/allSchedule',
        title: 'Schedules',
        hasBullet: true,
        permission: permissions.care_giver_schedules_view,
      },
      {
        to: '/caregivers/sheets/timesheet',
        title: 'Timesheets',
        hasBullet: true,
        permission: permissions.care_giver_time_sheets_view,
      },
     
    ],
  },

  {
    title: 'Notes',
    icon: '/media/icons/duotune/communication/com006.svg',
    permission: permissions.care_giver_notes_view,
    subMenuItems: [
      {
        to: '/notes/nurses-notes/list',
        title: 'Nurses/Therapy Notes',
        hasBullet: true,
        permission: permissions.notes_nurses_note_view,
      },
      {
        to: '/notes/caregiver-notes/notes',
        title: 'Caregiver Notes',
        hasBullet: true,
        permission: permissions.care_giver_notes_note_view,
      },
   
]},
  {
    title: 'Agency',
    icon: '/media/icons/duotune/general/gen025.svg',
    permission: permissions.agency,
    subMenuItems: [
      {
        to: '/agency-wide-document-storage/document-storage/new',
        title: 'Agency Wide Document Storage',
        hasBullet: true,
        permission: permissions.agency_agencyWideDocument,
      },
    ],
  },
  {
    title: 'Billing',
    icon: '/media/icons/duotune/ecommerce/ecm003.svg',
    permission: permissions.billing,
    subMenuItems: [
      {
        to: '/billing/attendance/new',
        title: 'Attendance',
        hasBullet: true,
        permission: permissions.billing_attendance,
      },
      {
        to: '/billing/professionalclaim/service-authorization/list',
        title: 'Professional Claim',
        hasBullet: true,
        permission: permissions.billing_professionalClaim,
      },
    ],
  },
  {
    title: 'Mars',
    icon: '/media/icons/duotune/ecommerce/ecm003.svg',
    permission: permissions.mars_individual_medication_view,
    subMenuItems: [
      {
        to: '/mars/individual-medication/list',
        title: 'Individual Medication',
        hasBullet: true,
        permission: permissions.mars_individual_medication_view,
      },
    ],
  },
  {
    title: 'Behavior',
    icon: '/media/icons/duotune/ecommerce/ecm003.svg',
    permission: permissions.behavior_view,
    subMenuItems: [
      {
        to: '/behaviour/target',
        title: 'Target Behavior',
        hasBullet: true,
        permission: permissions.behavior_target_view,
      },
      {
        to: '/behaviour/replacement',
        title: 'Replacement Behavior',
        hasBullet: true,
        permission: permissions.behavior_replacement_view,
      },
      {
        to: '/behaviour/client',
        title: 'Client Behavior',
        hasBullet: true,
        permission: permissions.behavior_client_behavior_view,
      },
      {
        to: '/behaviour/export',
        title: 'Export Insights',
        hasBullet: true,
        permission: permissions.behavior_export_view,
      },
    ],
  },
]

export const AsideMenuMain: React.FC<any> = (props) => {
  const auth = useSelector((state: {auth: any}) => state.auth)
  const userPermissions = auth.user.roleId
  const location = useLocation()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  useEffect(() => {
    // Check if the current route matches '/admin/care' and open dialog
    if (location.pathname.startsWith('/admin/selected-client')) {
      setIsDialogOpen(true)
    } else {
      setIsDialogOpen(false)
    }
  }, [location.pathname])
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='DASHBOARD'
        fontIcon='bi-app-indicator'
        permission={true}
      />

      {
        <div className='mt-4'>
          {asideMenuItems.map((item) => {
            if (item.subMenuItems) {
              return (
                <AsideMenuItemWithSub
                  key={item.title}
                  to={item.subMenuItems[0].to}
                  title={item.title}
                  icon={item.icon}
                  permission={hasPermission(userPermissions, item.permission)}
                >
                  {item.subMenuItems.map((subItem) => (
                    <AsideMenuItem
                      key={subItem.title}
                      to={subItem.to}
                      title={subItem.title}
                      hasBullet={subItem.hasBullet}
                      permission={hasPermission(userPermissions, subItem.permission)}
                    />
                  ))}
                </AsideMenuItemWithSub>
              )
            } else return <></>
          })}
          {isDialogOpen && (
            <ClientDailog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
          )}
        </div>
      }
    </>
  )
}
