import React from 'react'
import {useSelector} from 'react-redux'
import {hasPermission} from '../../../../../../_helper/hasPermission'
import {permissions} from '../../../../../../_constants/permissions'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RouteWithPermission} from '../../../../../../_helper/routesWithPermission'
import ListHealth from '../../../../../pages/admin/care/HealthProfile/List'
import EditHealth from '../../../../../pages/admin/care/HealthProfile/Edit'
import ListAllergy from '../../../../../pages/admin/care/AllergyProfile/List'
import EditAllergy from '../../../../../pages/admin/care/AllergyProfile/Edit'
import NewAllergy from '../../../../../pages/admin/care/AllergyProfile/New'
import ListDiagnosis from '../../../../../pages/admin/care/Diagnostics List/List'
import EditDiagnosis from '../../../../../pages/admin/care/Diagnostics List/Edit'
import NewDiagnosis from '../../../../../pages/admin/care/Diagnostics List/New'

const HealthInfoIndexRoute = () => {
  const {auth, client} = useSelector((state: {auth: any, client: any}) => state)
  const userPermissions = auth.user.roleId

  const canViewHealthProfile = hasPermission(userPermissions, permissions.admin_care_healthProfile_view)
  const canViewHealthData = hasPermission(userPermissions, permissions.admin_care_healthData_view)
  const canViewAllergy = hasPermission(userPermissions, permissions.admin_care_allergy_view)
  const canCreateAllergy = hasPermission(userPermissions, permissions.admin_care_allergy_create)
  const canUpdateAllergy = hasPermission(userPermissions, permissions.admin_care_allergy_update)
  const canViewDiagnosis = hasPermission(userPermissions, permissions.admin_care_diagnosis_view)
  const canUpdateDiagnosis = hasPermission(userPermissions, permissions.admin_care_diagnosis_update)
  const canCreateDiagnosis = hasPermission(userPermissions, permissions.admin_care_diagnosis_create)
  
  const getDefaultPath = () => {
    // if (canViewHealthProfile) return '/admin/care/health/list'
    if (canViewHealthData) return `/admin/care/health/health-data/${client.clientID}`
    if (canViewAllergy) return `/admin/care/health/allergy/list/${client.clientID}`
    if (canViewAllergy) return `/admin/care/health/allergy/list/${client.clientID}`
    if (canCreateAllergy) return `/admin/care/health/allergy/new/${client.clientID}`
    if (canViewDiagnosis) return `/admin/care/health/diagnosis/list/${client.clientID}`
    if (canViewDiagnosis) return `/admin/care/health/diagnosis/list/${client.clientID}`
    if (canCreateDiagnosis) return `/admin/care/health/diagnosis/new/${client.clientID}`
    return '/error/404'
  }

  
  return (
    <Switch>
      <Route exact path='/admin/care/health'>
        <Redirect to={getDefaultPath()} />
      </Route>
      <RouteWithPermission
        path='/admin/care/health/health-data/:clientID'
        component={EditHealth}
        hasPerm={canViewHealthData}
      />
      <RouteWithPermission
        path='/admin/care/health/allergy/list/:clientID/:id'
        component={EditAllergy}
        hasPerm={canViewAllergy && canUpdateAllergy}
      />
      <RouteWithPermission
        path='/admin/care/health/allergy/list/:clientID'
        component={ListAllergy}
        hasPerm={canViewAllergy}
      />
      <RouteWithPermission
        path='/admin/care/health/allergy/new/:clientID'
        component={NewAllergy}
        hasPerm={canViewAllergy && canCreateAllergy}
      />
      <RouteWithPermission
        path='/admin/care/health/diagnosis/list/:clientID/:id'
        component={EditDiagnosis}
        hasPerm={canViewDiagnosis && canUpdateDiagnosis}
      />
      <RouteWithPermission
        path='/admin/care/health/diagnosis/list/:clientID'
        component={ListDiagnosis}
        hasPerm={canViewDiagnosis}
      />
      <RouteWithPermission
        path='/admin/care/health/diagnosis/new/:clientID'
        component={NewDiagnosis}
        hasPerm={canViewDiagnosis && canCreateDiagnosis}
      />
    </Switch>
  )
}

export default HealthInfoIndexRoute
