import { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import TableTitle from '../../../../../reusable-components/tables/TableTitle';
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar';
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable';
import { LIST_FOR_SERV_AUTH_CLIENT_COLUMNS, LIST_FOR_SERV_AUTH_CLIENT_COLUMNS_MAPPING, LIST_FOR_SERV_AUTH_CLIENT_SORTING_COLUMNS } from '../../../BILLING_TABLE_CONSTANTS';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../../../../../_helper/hasPermission';
import { permissions } from '../../../../../../_constants/permissions';
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper';
import BackButton from '../../../../../reusable-components/Buttons/BackButton';
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes';
const selectAuth = (state: { auth: any }) => state.auth;
const Clients = () => {
    const [individuals, setIndividuals] = useState<any[]>([]);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [filteredData, setFilteredData] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState('')
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'desc',
        searchTerm: ''
    })
    const parameters: { programID: string } = useParams()
    const history = useHistory()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    useEffect(() => {
        const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            setIsFetchingData(true)
            try{
                if (parameters.programID) {
                const response = await axios.get(`clients/getProg/${parameters.programID}?page=${page}&pageSize${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`)
                const { data, currentPage, totalCounts } = response.data; 
                setIndividuals(data)
                setFilteredData(data)
                setQueryData((prevState) => {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    };
                });  
                }
            } catch(err){
                console.log(err)
            } finally{
                setIsFetchingData(false)
            }
        } 
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])
    const onAdd = ({ _id }: { _id: string }) => {
        history.push({
            pathname: `/billing/professionalclaim/service-authorization/new/${parameters.programID}/${_id}`
        })
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Select Client For Service Authorization'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            setQueryData={setQueryData}
                            setSearchTerm={setSearchTerm}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                    }
                    tableButtons={
                        <BackButton/>
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={LIST_FOR_SERV_AUTH_CLIENT_COLUMNS}
                            data={filteredData}
                            canDelete={false}
                            columnDataMapping={LIST_FOR_SERV_AUTH_CLIENT_COLUMNS_MAPPING}
                            sortingColumns={LIST_FOR_SERV_AUTH_CLIENT_SORTING_COLUMNS}
                            canAdd={hasPermission(userPermissions, permissions.billing_professionalClaim_serviceAuthorization_create)}
                            onAdd={onAdd}
                            setQueryData={setQueryData}
                            queryData={queryData}
                            tableLoader={isFetchingData}
                        />
                    }
                />
            </div>
        </div>
    )
}
export default Clients